const initState = {
    loading: false,
    bookingList: [],
    launchbookingList: [],
    busBooking:[],
    busBooking_s:[],
    launchBooking_s:[],
    bookingStatus:[],
    error:[],
    hotel_booking:[],
    discountData:[],
    hotel_loading:false,
  };
  
  const BusBookingReducer = (state = initState, action) => {
    switch (action.type) {

      case "LOADING":
        return {
          ...state,
          loading: true,
        };
  
      case "SUCCESS_LAUNCH_BOOKING":
        return {
          ...state,
          loading: false,
          launchbookingList: action.res,
        };
      case "SUCCESS_BUS_BOOKING":
        return {
          ...state,
          loading: false,
          bookingList: action.res,
        };
      case "LAUNCH_BOOKING":
        return {
          ...state,
          loading: false,
          launchBooking_s: action.res,
        };
      case "DISCOUNT":
        return {
          ...state,
          loading: false,
          discountData: action.res,
        };
      case "BUS_BOOKING":
        return {
          ...state,
          loading: false,
          busBooking_s: action.res,
        };

        case "HOTEL_BOOKING_LOADING":
          return {
            ...state,
            hotel_loading: true,
          };

        case "HOTEL_BOOKING":
          return {
            ...state,
            hotel_loading: false,
            hotel_booking: action.res,
          };


      case "BOOKING_STATUS":
        return {
          ...state,
          loading: false,
          bookingStatus: action.res,
        };

  
      case "ERROR":
        return {
          ...state,
          loading: false,
          error: action.res,
        };
  
      default:
        return state;
    }
  };
  
  export default BusBookingReducer;
  