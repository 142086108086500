import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
// import { getSearchData, searchRequest, getSearchDataForLaunch } from "../Store/Actions/SearchAction";
import { getSearchData, getSearchDataForLaunch } from "../Store/Actions/SearchAction";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from_city: "",
      to_city: "",
      journey_date: null,
      journey_time: "",
      return_journey_date: null,
      return_journey_time: "",
      fireRedirect: false,
      type:this.props.type,
    };
    this.onFromChange = this.onFromChange.bind(this);
    this.onToChange = this.onToChange.bind(this);
  }

  onFromChange = (event, values) => {
    this.setState({
      from_city: values,
    });
  };
  onToChange = (event, values) => {
    this.setState({
      to_city: values,
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ fireRedirect: false });
  }



  handleSubmit = (e) => {
    e.preventDefault();

    localStorage.removeItem('jStatus');
    localStorage.removeItem('journey');
    localStorage.removeItem('returnJourney');
    localStorage.removeItem('journeyDetails');
    localStorage.removeItem('busName');
    localStorage.removeItem('departurTime');
    localStorage.removeItem('operatorName');
    localStorage.removeItem('retuenBusName');
    localStorage.removeItem('retuenDeparturTime');
    localStorage.removeItem('retuenOperatorName');
    localStorage.removeItem('paymentUrl');
    localStorage.removeItem('bookingData');
    localStorage.removeItem('jType');
    localStorage.removeItem('ref_no');
    localStorage.removeItem('setTimer');
    // localStorage.removeItem('expired');

    if (this.state.journey_date >= this.state.return_journey_date) {
      toast.warning(<i className="fa fa-warning" aria-hidden="true">Invalid Journey Date</i>);
    } else if (this.state.from_city === "") {
      toast.warning(<i className="fa fa-warning" aria-hidden="true">The From city field is required</i>);
    } else if (this.state.to_city === "") {
      toast.warning(<i className="fa fa-warning" aria-hidden="true">The To city field is required</i>);
    } else if (this.state.journey_date === "") {
      toast.warning(<i className="fa fa-warning" aria-hidden="true">The date of journey field is required</i>);
    }else if(this.state.from_city === this.state.to_city){
      toast.error(<i className="fa fa-warning" aria-hidden="true">Please select your destination correctly !</i>);
    } else {
      this.setState({ fireRedirect: true });
    }
    // this.setState({ fireRedirect: true });
    // console.log(this.state);
    // this.props.searchRequest(this.state);
    // return <Redirect to='/bus-search' />
    // history.push('/bus-search');
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleDateChange = (date) =>{
    let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    this.setState({ journey_date: [date.getFullYear(), mnth, day].join("-") })
  } ;
  handleDateChange2 = (date) =>{
    let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    this.setState({ return_journey_date: [date.getFullYear(), mnth, day].join("-") })
  } ;

  componentDidMount = () => {
    this.props.getSearchData();
    this.props.getSearchDataForLaunch();
  };
  render() {
    const { fireRedirect } = this.state;
    // const searchArray = this.props.busSearchList;
    // console.log(this.props.searchdataList,'jl');

    var searchdataList =
      this.props.searchdataList.start_end_city !== undefined
        ? this.props.searchdataList.start_end_city
        : [];
    var launch_data = this.props.launchSearchdataList.launch_from_to_cities;    

    if(this.props.type === 'bus'){
      searchdataList = searchdataList;
      var to_url = '/bus-search';
      
    }else if(this.props.type === 'launch'){
      searchdataList = launch_data;
      var to_url = '/launch-search';
      
    }
    
    
    // console.log(this.props.launchSearchdataList.launch_from_to_cities,this.props.type);

    const defaultProps = {
      options: searchdataList,
      getOptionLabel: (option) => option,
    };

    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <Grid container className="pad-25 mobile-search">
            <Grid item xs={6}>
              <Autocomplete
                {...defaultProps}
                onChange={this.onFromChange}
                autoComplete
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="From"
                    name="from_city"
                    value={this.state.from_city}
                    onChange={this.handleChange}
                    placeholder="Enter City"
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                {...defaultProps}
                onChange={this.onToChange}
                autoComplete
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="To"
                    name="to_city"
                    value={this.state.to_city}
                    onChange={this.handleChange}
                    placeholder="Enter City"
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker 
                disablePast 
                format="MM/dd/yyyy"
                value={this.state.journey_date}
                onChange={this.handleDateChange} 
                autoOk
                invalidDateMessage=''
                label="Date Of Journey"
                variant="inline"
                
                />
            </MuiPickersUtilsProvider>
              {/* <TextField
                onChange={this.handleChange}
                name="journey_date"
                value={this.state.journey_date}
                margin="normal"
                id="date"
                label="Date Of Journey"
                
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <InputLabel id="demo-simple-select-helper-label">
                  Journey Time
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  name="time_of_journey"
                  value={
                    this.state.time_of_journey !== undefined
                      ? this.state.time_of_journey
                      : ""
                  }
                  onChange={this.handleChange}
                
                >
                  <MenuItem value="">
                    <em>{this.state.journey_date === "" ? 'Select journey date first':'None'}</em>
                  </MenuItem>
                  <MenuItem value='12:00 AM - 05:59 AM'   disabled={this.state.journey_date ==="" ? true:false}>12:00 AM - 05:59 AM</MenuItem>
                  <MenuItem value='06:00 AM - 11:59 AM'   disabled={this.state.journey_date === "" ? true:false}>06:00 AM - 11:59 AM</MenuItem>
                  <MenuItem value='12:00 PM - 05:59 PM'   disabled={this.state.journey_date === "" ? true:false}>12:00 PM - 05:59 PM</MenuItem>
                  <MenuItem value='06:00 PM - 11:59 PM'   disabled={this.state.journey_date === "" ? true:false}>06:00 PM - 11:59 PM</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker 
                disablePast 
                format="MM/dd/yyyy"
                value={this.state.return_journey_date}
                onChange={this.handleDateChange2} 
                autoOk
                invalidDateMessage=''
                label="Date Of Return(Optional)"
                variant="inline"
                
                />
            </MuiPickersUtilsProvider>
              {/* <TextField
                onChange={this.handleChange}
                name="return_journey_date"
                value={this.state.return_journey_date}
                margin="normal"
                id="date"
                label="Date Of Return(Optional)"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <InputLabel id="demo-simple-select-helper-label">
                  Return Journey Time
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  name="return_journey_time"
                  value={
                    this.state.return_journey_time !== undefined
                      ? this.state.return_journey_time
                      : ""
                  }
                  onChange={this.handleChange}
                >
                  <MenuItem value="">
                 <em>{this.state.return_journey_date === "" ? 'Select Return journey date first':'None'}</em>
                  </MenuItem>
                  <MenuItem value='12:00 AM - 05:59 AM'   disabled={this.state.return_journey_date === "" ? true:false}>12:00 AM - 05:59 AM</MenuItem>
                  <MenuItem value='06:00 AM - 11:59 AM'   disabled={this.state.return_journey_date === "" ? true:false}>06:00 AM - 11:59 AM</MenuItem>
                  <MenuItem value='12:00 PM - 05:59 PM'   disabled={this.state.return_journey_date === "" ? true:false}>12:00 PM - 05:59 PM</MenuItem>
                  <MenuItem value='06:00 PM - 11:59 PM'   disabled={this.state.return_journey_date === "" ? true:false}>06:00 PM - 11:59 PM</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Button
            className={this.props.btn_color}
            variant="contained"
            color="primary"
            type="submit"
          >
            Search 
          </Button>
          
        </form>
        
        {fireRedirect && (
          <Redirect
            to={{
              pathname: to_url,
              state: { searchArray: this.state },
            }}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchdataList: state.SearchReducer.searchdataList,
    launchSearchdataList: state.SearchReducer.launchSearchdataList,
    // busSearchList: state.SearchReducer.busSearchList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSearchData: () => dispatch(getSearchData()),
    getSearchDataForLaunch: () => dispatch(getSearchDataForLaunch()),
    // searchRequest: (creds) => dispatch(searchRequest(creds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
