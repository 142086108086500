import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
// import Typography from "@material-ui/core/Typography";
import SideNav from "./SideNav";
import { connect } from "react-redux";
import { UserInformation,myTrips } from "../Store/Actions/AuthAction";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import  HeaderBanner from "./HeaderBanner";
import { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import "./Dialog.css";
import Button from "@material-ui/core/Button";
import Drawer from '@material-ui/core/Drawer';


class Mytrips extends Component {
  constructor(props) {
    super(props);
    var dataa = this.props.userData;
    this.state = {
      id: dataa.id,
      full_name: dataa.full_name,
      phone: dataa.phone,
      email: dataa.email,
      city: dataa.city,
      gender: dataa.gender,
      photo_id_no: dataa.photo_id_no,
      fname: "",
      upcoming:true,
      completed:false,
      isOpen: false,
      setOpen: false,
      open: false,
      pdf:'',
      setOpenDrawer:false,
      drawerOpen:false,
    };
    this.handleClose = this.handleClose.bind(this);
  }
  handleDrawerOpen = () => {
    this.setState({
      setOpenDrawer:true,
      drawerOpen:true,
    })
  };
  handleDrawerClose  = () => {
    this.setState({
      setOpenDrawer:false,
      drawerOpen:false,
    })
  };
  handleClickOpen = (data) => {
    this.state.pdf = data;
    this.setState({ setOpen: true, open: true,pdf:data});
  };
  handleClose = () => {
    this.setState({ setOpen: false, open: false,pdf:''});
  };
  upcomingJourney= () =>{
    this.setState({upcoming:true,completed:false});
  }
  completedJourney= () =>{
    this.setState({upcoming:false,completed:true});
  }

  componentDidMount(props) {
    var user = localStorage.getItem('user_id');
    var id = this.state.id !== undefined ? this.state.id : user;
    this.props.UserInformation(this.props.history);
    this.props.myTrips(id);
  }
  render() {
    var userData = this.props.userData;
    var previous = this.props.mytrips !== undefined ? this.props.mytrips.previous : [];
    var upcoming = this.props.mytrips !== undefined ? this.props.mytrips.upcoming : [];
    // console.log( this.props.mytrips);
    var prevData =  previous ===  undefined ? '' : previous.map((val,index)=>{
        var icon = '';
        if(val.trip_type === 'Hotel Booking'){
          icon = <i key={index} className="fa fa-building" aria-hidden="true"></i>;
        }else if(val.trip_type === 'Bus Booking'){
          icon = <i key={index} className="fa fa-bus" aria-hidden="true"></i>;
        }else if(val.trip_type === 'Launch Booking'){
          icon = <i key={index} className="fa fa-ship" aria-hidden="true"></i>;
        }
        return(
          <Fragment>
            <div className="ticket-list">
                  <div className="inline">
                    <div className="ticket-details">
                      <h2>{val.operator}</h2>
                      <p><b>{val.trip_type === 'Bus Booking' ? val.bus_name : ''}</b></p>
                      <p><b>{val.trip_type === 'Launch Booking' ? val.launch_name : ''}</b></p>
                      <p>{val.date}</p>
                      <p>{val.time}</p>
                      <h2>{val.cost} TK.</h2>
                    </div>
                    <div className="ticket-icon">
                      {icon}
                    </div>
                  </div>
                  <button onClick={()=>this.handleClickOpen(val.pdf)} className="view-ticket float-right">
                    View Tickets
                  </button>
                </div>
          </Fragment>
        )
    });
    var upData =  upcoming === undefined ? '' : upcoming.map((val,index)=>{
      var icon = '';
      if(val.trip_type === 'Hotel Booking'){
        icon = <i key={index} className="fa fa-building" aria-hidden="true"></i>;
      }else if(val.trip_type === 'Bus Booking'){
        icon = <i key={index} className="fa fa-bus" aria-hidden="true"></i>;
      }else if(val.trip_type === 'Launch Booking'){
        icon = <i key={index} className="fa fa-ship" aria-hidden="true"></i>;
      }
      return(
        <Fragment>
          <div className="ticket-list">
                <div className="inline">
                  <div className="ticket-details">
                    <h2>{val.operator}</h2>
                    <p><b>{val.trip_type === 'Bus Booking' ? val.bus_name : ''}</b></p>
                    <p><b>{val.trip_type === 'Launch Booking' ? val.launch_name : ''}</b></p>
                    <p>{val.date}</p>
                    <p>{val.time}</p>
                    <h2>{val.cost} TK.</h2>
                  </div>
                  <div className="ticket-icon">
                    {icon}
                  </div>
                </div>
                <button onClick={()=>this.handleClickOpen(val.pdf)} className="view-ticket float-right">
                  View Tickets
                </button>
              </div>
        </Fragment>
      )
  });
    return (
      <div className=" pad-bottom">
        <HeaderBanner title="My Trips" />
        <Container maxWidth="md">
          {/* <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Home
            </Link>
            <Typography color="textPrimary">My Account</Typography>
          </Breadcrumbs> */}
          <Grid container>
            <Grid item xs={4} className="profile-sidebar">
              <SideNav userData={userData} />
            </Grid>
            <Grid item xs={8} className="profile-details">
              <div className="header-tab">
                <button className={this.state.upcoming === true ? 'upcoming tab-active-trip' : 'upcoming'} onClick={this.upcomingJourney}>Upcoming</button>
                <button className={this.state.completed === true ? 'completed tab-active-trip' : 'completed'} onClick={this.completedJourney}>Completed</button>
                {this.state.upcoming === true ?  upData: ''}
                {this.state.completed === true ? prevData : ''}
                
                <p>
              <Link className="for-mob" onClick={this.handleDrawerOpen}><i className="fa fa-bars float-right mar-right-15"> </i></Link> 
              </p>
              </div>
            </Grid>
          </Grid>
        </Container>

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.open}
          onClose={this.handleClose}
          fullScreen 
        >
          {this.state.open ? <Fragment>
            <DialogActions>
                  <Button onClick={this.handleClose} >
                    <i className="fa fa-close"></i>
                  </Button>
                </DialogActions>
            <DialogContent>
            <div className="image-slider">
              <div className="details-body">
                <Grid container spacing={3}>
                  <Grid item xs={12} className="pad-left-5">
                    <embed height="1224" width="350" src={this.state.pdf}></embed>
                  </Grid>
                  </Grid>
              </div>
            </div>
          </DialogContent>
          
          </Fragment>:''}
          {/* <DialogActions>
            <Button onClick={this.handleClose} className="footer-btn">
              Close
            </Button>
          </DialogActions> */}
        </Dialog>


        <Drawer
        variant="persistent"
        anchor="left"
        open={this.state.drawerOpen}
      >
         <Container maxWidth="md" className="pad-top-25">
          <Grid container>
            <Grid item xs={3} className="drawer-gird">
               <SideNav userData={userData} />
            </Grid>
            
          </Grid>
          <div className="drawer-btn">
              <button className="btn-view-seats-drawer   tracking" onClick={this.handleDrawerClose}>Close</button>
          </div>
        </Container>
      </Drawer>


      </div>
    );
  }
}

const mapStateToProps = (state, history) => {
  return {
    userData: state.AuthReducer.userData,
    mytrips: state.AuthReducer.mytrips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    UserInformation: (history) => dispatch(UserInformation(history)),
    myTrips: (id) => dispatch(myTrips(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mytrips);
