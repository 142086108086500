import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Button from "@material-ui/core/Button";
import HttpService from '../Store/Services/HttpService';

export default class Pop extends Component {
    constructor(props) {
        super(props);
        this.state = {
          open:false,
        };
      }
    handleClickOpen = () => {
        this.setState({open: true,});
    };
    handleClickClose = () => {
        this.setState({open: false,});
    };
    componentDidMount(props) {
        this.handleClickOpen();
    }
    render() {
        const http = new HttpService();
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    keepMounted
                    // onClose={handleClose}
                    // aria-labelledby="alert-dialog-slide-title"
                    // aria-describedby="alert-dialog-slide-description"
                    maxWidth="md"
                    >
                    <DialogActions>
                        <Button onClick={this.handleClickClose} color="primary">
                        <i className="fa fa-close"></i>
                        </Button>
                    </DialogActions>
                    <DialogContent>
                    <img
                    src={http.url+this.props.img}
                    className="img"
                    alt="img"
                  />
                    </DialogContent>
                    
                    </Dialog>
            </div>
        )
    }
}
