import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Slide from "./Slide";
import Slide3 from "./Slide3";
import Loader from "./Loader";
import Slide2 from "./Slide2";
import { connect } from "react-redux";
import { getOfferData } from "../Store/Actions/OfferAction";
import { getLaunchOfferData } from "../Store/Actions/OfferAction";
import { getHotelOfferData } from "../Store/Actions/OfferAction";

class Offer extends Component {
  componentDidMount = () => {
    this.props.getOfferData();
    this.props.getLaunchOfferData();
    this.props.getHotelOfferData();
  };
  render() {
    let data = this.props.dataList.busOffers;
    let launchData = this.props.launchData.launchOffers;
    let hotelData = this.props.hotelData.hotelOffers;
    // console.log(launchData);
    const loadData = data ? < Slide offer={data} /> : <Loader />;
    const launch = launchData ? < Slide2 offer={launchData} /> : <Loader />;
    const hotelSlider = hotelData ? < Slide3 offer={hotelData} /> : <Loader />;
    return (
      <div>
        <Container maxWidth="md">
          <h3 className="text-center web-offer-title">Offers</h3>
          <div className="bus-offer-container">
            <Grid container >
              <Grid item xs={3} className="container-title mar31">
                <img
                  src="/assets/images/bus_illustration.png"
                  className="bus-image"
                  alt="img"
                />
                <div className="overlay">
                  <h3 className="offer-head">
                    Bus <br /> Offers
                  </h3>
                </div>
              </Grid>
              <Grid item xs={9}>
              <h3 className="text-center mobile-offer-title">Bus Offers</h3>
                { loadData }
                
              </Grid>
            </Grid>
          </div>
          <div className="bus-offer-container">
            <Grid container >
              <Grid item xs={9}>
              <h3 className="text-center mobile-offer-title">Launch Offers</h3>
                {launch}
              </Grid>
              <Grid item xs={3} className="container-title mar31">
                <img
                  src="/assets/images/launch_illustration.png"
                  className="right-image"
                  alt="img"
                />
                <div className="overlay" style={{ bottom: "0px",marginLeft:'11px'}}>
                  <h3 className="offer-head">Launch <br/> Offers</h3>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="bus-offer-container">
            <Grid container >
              <Grid item xs={3} className="container-title mar31">
                <img
                  src="/assets/images/air_illustration.png"
                  className="bus-image"
                  alt="img"
                />
                <div className="overlay">
                  <h3 className="offer-head">
                    Airlines <br /> Offers 
                  </h3>
                </div>
              </Grid>
              <Grid item xs={9}>
              <h3 className="text-center mobile-offer-title">Air Offers</h3>
              { loadData }
              </Grid>
            </Grid>
          </div>
          <div className="bus-offer-container">
            <Grid container >
              <Grid item xs={9}>
              <h3 className="text-center mobile-offer-title">Hotel Offers</h3>
              {hotelSlider}
              </Grid>
              <Grid item xs={3} className="container-title mar31">
                <img
                  src="/assets/images/hotel_illustration.png"
                  className="right-image"
                  alt="img"
                />
                <div className="overlay" style={{ bottom: "0px",marginLeft:'11px'}}>
                  <h3 className="offer-head">Hotels <br/> Offers</h3>
                </div>
              </Grid>
            </Grid>
          </div>

        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataList: state.OfferReducer.dataList,
    launchData: state.OfferReducer.launchData,
    hotelData: state.OfferReducer.hotelData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOfferData: () => dispatch(getOfferData()),
    getLaunchOfferData: () => dispatch(getLaunchOfferData()),
    getHotelOfferData: () => dispatch(getHotelOfferData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
