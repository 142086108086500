import HttpService from './HttpService';



export const reqForReview = (hotel_id) =>{
    const http = new HttpService();
    let url = "get-hotel-reviews/"+hotel_id;
    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });

}

export const reqForLocation = (journey_id,type) =>{
    const http = new HttpService();
    let url = "get-location/"+type+'/'+journey_id;
    console.log('url',url);
    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });

}
export const reqForFoodMenu = (journey_id) =>{
    const http = new HttpService();
    let url = "launches/"+journey_id+'/food-menu';
    console.log('url',url);
    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });

}



export const getSearchList = () =>{
    const http = new HttpService();
    let url = "bus-from-to-cities";
    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });
}

export const getSearchListForHotel = () =>{
    const http = new HttpService();
    let url = "get_hotel_city";
    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });
}


export const getSearchListForLaunch = () =>{
    const http = new HttpService();
    let url = "launch-from-to-cities";
    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });
}


export const hotelSearchRequest = (creds) =>{
    const http = new HttpService();
    var url = "hotel-search";
    return http.requestData(creds,url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });
}
export const hotelSearchRequestForDetails = (creds) =>{
    var id = creds.id;
    const http = new HttpService();
    var url = "hotel-search-details/"+id;
    return http.requestData(creds,url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });
}



export const reqSearchList = (creds) =>{
    
    const http = new HttpService();
    let urlD = '';
    if(creds.type === 'bus'){
        urlD = "bus-journey-search";
    }else if(creds.type === 'launch'){
        urlD = "launch-journey-search";
        
    }
    let url = urlD;
    

    return http.requestData(creds,url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });
}

export const reqForSeatLayout = (journey_id) =>{
    const http = new HttpService();
    let url = "journey-details-seat-layout/"+journey_id;
    // console.log(url,journey_id);

    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });

}

export const reqForSeatLayoutForLaunch = (journey_id) =>{
    const http = new HttpService();
    let url = "journey-details-cabin-layout/"+journey_id;
    // console.log(url,journey_id);

    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });

}

