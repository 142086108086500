const initState = {
  loading: false,
  searchdataList: [],
  launchSearchdataList: [],
  hotelSearchdataList: [],
  busSearchList:[],
  busSeatLayout:'[]',
  hotelList:'[]',
  hotelDetails:'[]',
  hotelLoading:false,
  error:[],
  food_menu:[],
  location:[],
  hotelReview:[],
  busLoading:false,
  hoteldetailsLoading:false,
};

const SearchReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOAD_DATA":
      return {
        ...state,
        loading: false,
        busLoading: false,
      };
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "BUS_LOADING":
      return {
        ...state,
        busLoading: true,
      };
    case "HOTEL_LOADING":
      return {
        ...state,
        hotelLoading: true,
      };

    case "SUCCESS":
      return {
        ...state,
        loading: false,
        busLoading: false,
        searchdataList: action.res,
      };
    case "LOCATION":
      return {
        ...state,
        loading: false,
        location: action.res,
      };
    case "REVIEW":
      return {
        ...state,
        loading: false,
        hotelReview: action.res,
      };
    case "LAUNCH_SUCCESS":
      return {
        ...state,
        loading: false,
        launchSearchdataList: action.res,
      };
      case "FOOD_MENU":
      return {
        ...state,
        loading: false,
        food_menu: action.res,
      };
    case "HOTEL_SUCCESS":
      return {
        ...state,
        loading: false,
        hotelLoading: false,
        hotelSearchdataList: action.res,
      };
      case "BUS_SEARCH_SUCCESS":
      return {
        ...state,
        loading: false,
        busLoading: false,
        busSearchList: action.res,
      };

      case "HOTEL_SEARCH_LOADING":
      return {
        ...state,
        hotelLoading: true,
      };

      case "HOTEL_SEARCH_SUCCESS":
      return {
        ...state,
        hotelLoading: false,
        hotelList: action.res,
      };

      case "HOTEL_DETAILS_LOADING":
      return {
        ...state,
        hoteldetailsLoading: true,
      };

      case "HOTEL_DETAILS_SUCCESS":
      return {
        ...state,
        hoteldetailsLoading: false,
        hotelDetails: action.res,
      };


      case "BUS_SEAT_SUCCESS":
      return {
        ...state,
        loading: false,
        busLoading: false,
        busSeatLayout: action.res,
      };
    case "ERROR":
      return {
        ...state,
        loading: false,
        busLoading: false,
        error: action.res,
      };

    default:
      return state;
  }
};

export default SearchReducer;
