import firebase from 'firebase/app';
import 'firebase/auth';
 
const config  = {
    apiKey: "AIzaSyAq8MtOV_ZixBNeoIiUqH8tIKm26PHGub0",
    authDomain: "powerbits-f499c.firebaseapp.com",
    databaseURL: "https://powerbits-f499c-default-rtdb.firebaseio.com",
    projectId: "powerbits-f499c",
    storageBucket: "powerbits-f499c.appspot.com",
    messagingSenderId: "896816863785",
    appId: "1:896816863785:web:36b7d9ade30885335a9a38",
    measurementId: "G-28TKE6CEHB"
  };


firebase.initializeApp(config);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

const FacebookProvider = new firebase.auth.FacebookAuthProvider();
FacebookProvider.setCustomParameters({ prompt: 'select_account' });
export const signInWithFacebook = () => auth.signInWithPopup(FacebookProvider);

export default firebase;

