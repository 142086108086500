import { SignUpService,LoginUser,AuthUserInformation } from "../Services/AuthService";
import { UserLogoutD,UserEdit,ChangePassword,editUserImage,SocialLoginUser,userTrips,sendMailForgetPassword,reqForResetPassword } from "../Services/AuthService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import history from './history';

export const signUp = (credentials,history) => {
    return (dispatch) => {
      dispatch({ type: "RESTART_AUTH_RESPONSE" });
      dispatch({ type: "LOADING" });
      if (credentials.password.length < 6) {
        return dispatch({ type: "SHORT_PASSWORD" });
      }
  
      SignUpService(credentials,history).then(
        (res) => {
            
          if (res.api_token !== '' &&  res.api_token !== undefined ) {
            localStorage.setItem("user", "Bearer " + res.api_token);
            localStorage.setItem("user_id",res.id);
            dispatch({ type: "SIGNUP_SUCCESS" });
            toast.success(<i className="fa fa-check-circle" aria-hidden="true"> Sign Up Successfully !</i>);
            AuthUserInformation().then(
              (res) => {
                  let response = res.profile;
                  dispatch({ type: "LOAD_USER_DATA", response });
              },
              (error) => {
                dispatch({ type: "FETCH_DATA_ERROR", error });
              });
            setTimeout(() => {
              if(localStorage.getItem("bookingData")){
                var chk = JSON.parse(localStorage.getItem("bookingData"));
                // console.log(chk.search_parameter.type,chk);
                if(chk.search_parameter !== undefined && chk.search_parameter.type === 'launch'){
                  history.push("/launch-search");
                }else if(chk.type !== undefined && chk.type === 'hotel'){
                  history.push("/hotel-booking")
                }else{
                  history.push("/bus-search");
                }
                
              }else{
                history.push("/");
              }
            }, 2000);
          } else {
            // console.log(res.errors.email);
            if(res.errors.email){
                toast.warning(<i className="fa fa-exclamation-triangle"> {res.errors.email[0]}</i>);
            } 
            if(res.errors.fname){
                toast.warning(<i className="fa fa-exclamation-triangle"> {res.errors.fname[0]}</i>);
            } 
            if(res.errors.password){
                toast.warning(<i className="fa fa-exclamation-triangle"> {res.errors.password[0]}</i>);
            } 
            if(res.errors.password_confirmation){
                toast.warning(<i className="fa fa-exclamation-triangle"> {res.errors.password_confirmation[0]}</i>);
            } 
            if(res.errors.phone){
                toast.warning(<i className="fa fa-exclamation-triangle"> {res.errors.phone[0]}</i>);
            } 
            dispatch({ type: "CODE_ERROR", res });
          }
        },
        (error) => {
            // console.log('yui');  
          dispatch({ type: "CODE_ERROR", error });
        }
      );
    };
  };





  export const UserLogin = (credentials, history) => {
    return (dispatch) => {
      dispatch({ type: "RESTART_AUTH_RESPONSE" });
      dispatch({ type: "LOADING" });
  
      LoginUser(credentials, history).then(
        (res) => {
            console.log(res);
            if (res.api_token !== '' &&  res.api_token !== undefined ){
            localStorage.setItem("user", "Bearer " + res.api_token);
            localStorage.setItem("user_id",res.id);
            dispatch({ type: "LOGIN_SUCCESS" });
            toast.success(<i className="fa fa-check-circle" aria-hidden="true"> Login Successfully !</i>);
            AuthUserInformation().then(
              (res) => {
                  let response = res.profile;
                  dispatch({ type: "LOAD_USER_DATA", response });
              },
              (error) => {
                dispatch({ type: "FETCH_DATA_ERROR", error });
              });


            setTimeout(() => {
              if(localStorage.getItem("bookingData")){
                var chk = JSON.parse(localStorage.getItem("bookingData"));
                // console.log(chk.search_parameter,chk);
                if(chk.search_parameter !== undefined && chk.search_parameter.type === 'launch'){
                  history.push("/launch-search");
                }else if(chk.type !== undefined && chk.type === 'hotel'){
                  history.push("/hotel-booking")
                }else{
                  history.push("/bus-search");
                }
              }else{
                history.push("/");
              }
            }, 2000);
          } else {
            toast.error(<i className="fa fa-exclamation-triangle"> {res.message} </i>);
            dispatch({ type: "LOGIN_ERROR", res });
          }
        },
        (error) => {
          dispatch({ type: "CODE_ERROR", error });
          // console.log(error);
        }
      );
    };
  };


  export const UserInformation = () => {
    return (dispatch) => {
      dispatch({ type: "LOADING" });
      if(localStorage.getItem('user')){
      AuthUserInformation().then(
        (res) => {
            let response = res.profile;
            dispatch({ type: "LOAD_USER_DATA", response });
            if(res.message === "Unauthenticated."){
                localStorage.removeItem("user");
                localStorage.removeItem("user_id");
                window.location.reload();
            }
        },
        (error) => {
          dispatch({ type: "FETCH_DATA_ERROR", error });
        }
      );
    }
    };
  };


  export const myTrips = (id) => {
    return (dispatch) => {
      dispatch({ type: "TRIPS_LOADING" });
      if(localStorage.getItem('user')){
      userTrips(id).then(
        (res) => {
          dispatch({ type: "MY_TRIPS", res });
        },
        (error) => {
          dispatch({ type: "FETCH_DATA_ERROR", error });
        }
      );
    }
    };
  };


  export const Logout = () => {
    return (dispatch) => {
      dispatch({ type: "LOADING" });
  
      UserLogoutD().then(
        (res) => {
            dispatch({ type: "LOGOUT_SUCCESS" });
            toast.success(<i className="fa fa-exclamation-triangle"> Logout Successfully !</i>);
            localStorage.removeItem("user");
        },
        (error) => {
          dispatch({ type: "FETCH_DATA_ERROR", error });
        }
      );
    };
  };

  export const editUser = (credentials) => {
    // console.log(credentials);
    return (dispatch) => {
      dispatch({ type: "LOADING" });
      UserEdit(credentials).then(
        (res) => {
            toast.success(<i className="fa fa-check"> {res.message} </i>);
            let response = res.profile;
            dispatch({ type: "LOAD_USER_DATA", response });
         },
        (error) => {
          toast.success(<i className="fa fa-exclamation-triangle">Something went wrong !</i>);
          dispatch({ type: "CODE_ERROR", error });
        }
      );
    };
  };

  export const editProfileImage = (credentials) => {
    // console.log(credentials);
    return (dispatch) => {
      dispatch({ type: "LOADING" });
      editUserImage(credentials).then(
        (res) => {
            toast.success(<i className="fa fa-check"> {res.message} </i>);
            let response = res.profile;
            dispatch({ type: "LOAD_USER_DATA", response });
         },
        (error) => {
          toast.success(<i className="fa fa-exclamation-triangle">Something went wrong !</i>);
          dispatch({ type: "CODE_ERROR", error });
        }
      );
    };
  };


  export const reqForgetPassword = (credentials,history) => {
    return (dispatch) => {
      dispatch({ type: "LOADING" });
      sendMailForgetPassword(credentials,history).then(
        (res) => {
            console.log(res);
            dispatch({ type: "FORGET", res });
            if(res.code === 2000){
              toast.success(<i className="fa fa-check"> {res.data.message} </i>);
              history.push("/");
            }else{
              toast.error(<i className="fa fa-warning"> {res.data.message} </i>);
            }
         },
        (error) => {
          toast.success(<i className="fa fa-exclamation-triangle">Something went wrong !</i>);
          dispatch({ type: "CODE_ERROR", error });
        }
      );
    };
  };
  export const reqForResetPass = (credentials,history) => {
    return (dispatch) => {
      dispatch({ type: "LOADING" });
      reqForResetPassword(credentials,history).then(
        (res) => {
            console.log(res);
            dispatch({ type: "FORGET", res });
            if(res.code === 2000){
              toast.success(<i className="fa fa-check"> {res.data.message} </i>);
              history.push("/");
            }else{
              toast.error(<i className="fa fa-warning"> {res.data.message} </i>);
            }
         },
        (error) => {
          toast.success(<i className="fa fa-exclamation-triangle">Something went wrong !</i>);
          dispatch({ type: "CODE_ERROR", error });
        }
      );
    };
  };




  export const passwordChange = (credentials) => {
    return (dispatch) => {
      dispatch({ type: "LOADING" });
      ChangePassword(credentials).then(
        (res) => {
          // console.log(res);
            toast.success(<i className="fa fa-check"> {res.message} </i>);
            // dispatch({ type: "CHANGE_PASSWORD", res });
         },
        (error) => {
          toast.success(<i className="fa fa-exclamation-triangle">Something went wrong !</i>);
          dispatch({ type: "CODE_ERROR", error });
        }
      );
    };
  };




  export const SocialLogin = (credentials, history) => {
    return (dispatch) => {
      dispatch({ type: "RESTART_AUTH_RESPONSE" });
      dispatch({ type: "LOADING" });
  
      SocialLoginUser(credentials, history).then(
        (res) => {
            console.log(res);
            if (res.api_token !== '' &&  res.api_token !== undefined ){
            localStorage.setItem("user", "Bearer " + res.api_token);
            localStorage.setItem("user_id",res.id);
            dispatch({ type: "LOGIN_SUCCESS" });
            toast.success(<i className="fa fa-check-circle" aria-hidden="true"> Login Successfully !</i>);
            AuthUserInformation().then(
              (res) => {
                  let response = res.profile;
                  dispatch({ type: "LOAD_USER_DATA", response });
              },
              (error) => {
                dispatch({ type: "FETCH_DATA_ERROR", error });
              });


            setTimeout(() => {
              if(localStorage.getItem("bookingData")){
                var chk = JSON.parse(localStorage.getItem("bookingData"));
                // console.log(chk.search_parameter.type,chk);
                if(chk.search_parameter !== undefined && chk.search_parameter.type === 'launch'){
                  history.push("/launch-search");
                }else if(chk.type !== undefined && chk.type === 'hotel'){
                  history.push("/hotel-booking")
                }else{
                  history.push("/bus-search");
                }
              }else{
                history.push("/");
              }
            }, 2000);
          } else {
            toast.error(<i className="fa fa-exclamation-triangle"> {res.message} </i>);
            dispatch({ type: "LOGIN_ERROR", res });
          }
        },
        (error) => {
          dispatch({ type: "CODE_ERROR", error });
          // console.log(error);
        }
      );
    };
  };





