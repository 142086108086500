import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import { Fragment } from "react";
import HeaderBanner from "./HeaderBanner";
import Grid from "@material-ui/core/Grid";
export default class Contact extends Component {
  render() {
    return (
      <Fragment>
        <HeaderBanner title="Contact us" />
        <Container maxWidth="md">
          <Grid container spacing={3} className="pad-bottom mob-contact">
            <Grid item xs={8} className="mar-top-40">
              <iframe
                width="100%"
                height="300"
                frameborder="0"
                src="https://www.google.com/maps/embed/v1/place?q=23.8103,90.4125&amp;key=AIzaSyDZeKmppB1BbqEao2horwtegrIJN8CoRVw"
              ></iframe>
            </Grid>
            <Grid item xs={4} className="text-left">
              <h2>Head Office</h2>
              <p>
                <b>Email :</b> info@Vromon.com
              </p>
              <p>
                <b>Address : </b> 239/40 City Plaza Market (near Udayon School
                gate), Fazlul haque Avenue, 2nd Floor, Barishal
              </p>
              <p>
                <b>Telephone :</b> 01309-033586-88
              </p>
              <p>
                <b>Helpline :</b> 01309-033583 OR 01309-033584
              </p>
              <p>
                <b>Hours :</b> Thursday: 10:00AM–10:00PM Friday: 10:00AM–12:30PM
                and 3:00PM-9:00PM
              </p>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}
