import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { connect } from "react-redux";
import { hotelRequest } from "../Store/Actions/SearchAction";
// import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import Checkbox from "@material-ui/core/Checkbox";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Input from '@material-ui/core/Input';
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class HotelModifySearch extends Component {
  constructor(props) {
    super(props);
    var setArray = this.props.stateArray; 
    this.state = {
      city: setArray.city,
      adult: setArray.adult,
      children: setArray.children,
      check_in: setArray.check_in,
      check_out: setArray.check_out,
      number_of_room: setArray.number_of_room,
      setOpen:false,
      open:false,
    };
    this.handleClose = this.handleClose.bind(this);

  }

  setAdultPlus = () =>{
    if(this.state.adult >= 0){
      var total = this.state.adult+1;
      this.setState({ adult: total});
    }
  };
  setAdultMinus = () =>{
    if(this.state.adult > 0){
      var total = this.state.adult-1;
      this.setState({adult:total})
    }
    
  };

  setRoomPlus = () =>{
    if(this.state.number_of_room >= 0){
      var total = this.state.number_of_room+1;
      this.setState({ number_of_room: total});
    }
    
  };
  setRoomMinus = () =>{
    if(this.state.number_of_room > 0){
      var total = this.state.number_of_room-1;
      this.setState({number_of_room:total})
    }
    
  };

  setChildPlus = () =>{
    if(this.state.children >= 0){
      var total = this.state.children+1;
      this.setState({ children: total});
    }
    
  };
  setChildMinus = () =>{
    if(this.state.children > 0){
      var total = this.state.children-1;
      this.setState({children:total})
    }
    
  };


  handleClickOpen = () => {
    this.setState({setOpen:true,open:true})
  };

   handleClose = () => {
    this.setState({setOpen:false,open:false})
  };



  handleSubmit = (e) => {
    e.preventDefault();
  };

  onFromChange = (event, values) => {
    this.setState({
      from_city: values,
    });
  };

  nextChange = (e) => {
    e.preventDefault();
    let jDate =  this.state.check_in.split("-")
    let monthName =jDate[1];
    let day = jDate[2];
    let year = jDate[0];
    let sum = parseInt(day)+1;
    let fullDate = year+'-'+monthName+'-'+sum;
    this.setState({check_in:fullDate})
    var dataArray = {
      city : this.state.city,
      check_in : this.state.check_in,
      check_out : this.state.check_out,
      children : this.state.children,
      adult : this.state.adult,
      number_of_room : this.state.number_of_room,
    }
    if(dataArray.adult === 0){
      toast.warning(<i className="fa fa-exclamation-triangle"> The Adult must be getter than 0 </i>);
      return false;
    }
    this.props.hotelRequest(dataArray);
  };
  prevChange = (e) => {
    e.preventDefault();
    let jDate =  this.state.check_in.split("-")
    let monthName =jDate[1];
    let day = jDate[2];
    let year = jDate[0];
    let sum = parseInt(day)-1;
    let fullDate = year+'-'+monthName+'-'+sum;
    this.setState({check_in:fullDate})
    var dataArray = {
      city : this.state.city,
      check_in : this.state.check_in,
      check_out : this.state.check_out,
      children : this.state.children,
      adult : this.state.adult,
      number_of_room : this.state.number_of_room,
    }
    if(dataArray.adult === 0){
      toast.warning(<i className="fa fa-exclamation-triangle"> The Adult must be getter than 0 </i>);
      return false;
    }
    this.props.hotelRequest(dataArray);
  };


  RnextChange = (e) => {
    e.preventDefault();
    let jDate =  this.state.check_out.split("-")
    let monthName =jDate[1];
    let day = jDate[2];
    let year = jDate[0];
    let sum = parseInt(day)+1;
    let fullDate = year+'-'+monthName+'-'+sum;
    this.setState({check_out:fullDate})
    var dataArray = {
      city : this.state.city,
      check_in : this.state.check_in,
      check_out : this.state.check_out,
      children : this.state.children,
      adult : this.state.adult,
      number_of_room : this.state.number_of_room,
    }
    if(dataArray.adult === 0){
      toast.warning(<i className="fa fa-exclamation-triangle"> The Adult must be getter than 0 </i>);
      return false;
    }
    this.props.hotelRequest(dataArray);
  };
  RprevChange = (e) => {
    e.preventDefault();
    let jDate = this.state.check_out.split("-")
    let monthName =jDate[1];
    let day = jDate[2];
    let year = jDate[0];
    let sum = parseInt(day)-1;
    let fullDate = year+'-'+monthName+'-'+sum;
    this.setState({check_out:fullDate})
    var dataArray = {
      city : this.state.city,
      check_in : this.state.check_in,
      check_out : this.state.check_out,
      children : this.state.children,
      adult : this.state.adult,
      number_of_room : this.state.number_of_room,
    }

    if(dataArray.check_in >= dataArray.check_out){
      toast.warning(<i className="fa fa-exclamation-triangle"> Invalid  journey </i>);
      return false;
    }
     if(dataArray.adult === 0){
      toast.warning(<i className="fa fa-exclamation-triangle"> The Adult must be getter than 0 </i>);
      return false;
    }

    this.props.hotelRequest(dataArray);
  };

  componentDidMount(){
    // console.log(this.props,'dadfasdf');
    this.setState({city:''});
  }
  componentDidUpdate(){
    if(this.state.city === ''){
    var data = this.props.stateArray;
    this.setState({
      city:data.city,
      adult:data.adult,
      children:data.children,
      number_of_room:data.number_of_room,
      check_in : data.check_in,
      check_out : data.check_out,
    });
    }
  }

  render() {
    var mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      let jDate =  this.state.check_in?this.state.check_in.split("-"):'';
      let monthName = mL[jDate[1]-1];
      let day = jDate[2];
      let year = jDate[0];
    
  
      let rDate = this.state.check_out?this.state.check_out.split("-"):'';
      let rmonthName = mL[jDate[1]-1];
      let rday = rDate[2];
      let ryear = rDate[0];

    return (

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <h3 className="modify-title">{this.state.city}</h3>
        <Grid container  className="pad-10">
            
          <Grid item xs={6} className="border-right">
            <h4 className="search-title">Check In</h4>
            {/* <h3 className="from-to">{this.props.modArray.from_city} - {this.props.modArray.to_city}</h3> */}
            <Container className="web-bus-modify">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div className="pad-10">
                    <button className="btn-next" onClick={this.prevChange} ><i className="fa fa-angle-left"> Prev Day </i></button>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="src-middle-icon">
                    <i className="fa fa-building" aria-hidden="true"></i>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="pad-10">
                    <button className="btn-next"  onClick={this.nextChange} >Next Day <i className="fa fa-angle-right"> </i></button>
                  </div>
                </Grid>
              </Grid>
              <h3 className="modify-date">{day} {monthName} {year}</h3>
            </Container>
            <Container className="mobile-bus-modify">
              <Grid container spacing={3} className="mar12">
                <Grid item xs={4}>
                  <div>
                    <button className="btn-next" onClick={this.prevChange} ><i className="fa fa-angle-left"></i></button>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="src-middle-icon">
                    <i className="fa fa-building" aria-hidden="true"></i>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div >
                    <button className="btn-next"  onClick={this.nextChange} ><i className="fa fa-angle-right"> </i></button>
                  </div>
                </Grid>
              </Grid>
              <h3 className="modify-date">{day} {monthName} {year}</h3>
            </Container>
          </Grid>
          <Grid item xs={6} className="lineHeight" >
            <h4 className="search-title">Check Out</h4>
            <Container className="web-bus-modify">
              <Grid container spacing={3}> 
                <Grid item xs={4}>
                  <div className="pad-10">
                  <button className="btn-next" onClick={this.RprevChange} ><i className="fa fa-angle-left"> Prev Day </i></button>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="src-middle-icon">
                    <i className="fa fa-building" aria-hidden="true"></i>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="pad-10">
                  <button className="btn-next" onClick={this.RnextChange} > Next Day <i className="fa fa-angle-right">  </i></button>
                  </div>
                </Grid>
              </Grid>
              <h3 className="modify-date">{rday} {rmonthName} {ryear}</h3>
            </Container>
            <Container  className="mobile-bus-modify">
              <Grid container spacing={3} className="mar12"> 
                <Grid item xs={4}>
                  <div>
                  <button className="btn-next" onClick={this.RprevChange} ><i className="fa fa-angle-left"></i></button>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="src-middle-icon">
                    <i className="fa fa-building" aria-hidden="true"></i>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                  <button className="btn-next" onClick={this.RnextChange} ><i className="fa fa-angle-right">  </i></button>
                  </div>
                </Grid>
              </Grid>
              <h3 className="modify-date">{rday} {rmonthName} {ryear}</h3>
            </Container>
          
          </Grid>
          <Grid item xs={12}>
              <div><p className="select-data">{this.state.adult} Adult {this.state.children} Children {this.state.number_of_room} Room</p></div>
              <Button className="select-dialog dialog-btn" onClick={this.handleClickOpen}>Please click here for select </Button>
             
              <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open}
                onClose={this.handleClose}
              >
                <DialogTitle className="dialog-title">Please choose option</DialogTitle>
                <DialogContent>
                  <form  className="dialog-form" >
                  <FormControl >
                      <span className="member-title">Adult</span>
                      <div className="inc-body">
                      <a className="plus"  onClick={this.setAdultMinus}><i className="fa fa-minus"></i></a>
                      <div className="number">{this.state.adult}</div>
                      <a className="minus" onClick={this.setAdultPlus}><i className="fa fa-plus"></i></a>
                        
                        
                      </div>
                      <span className="op-footer">Above 10 years</span>
                    </FormControl>
                    <FormControl >
                      <span className="member-title">Children</span>
                      <div className="inc-body">
                      <div className="plus"  onClick={this.setChildMinus}><i className="fa fa-minus"></i></div>
                      <div className="number">{this.state.children}</div>
                      <div className="minus" onClick={this.setChildPlus}><i className="fa fa-plus"></i></div>
                        
                        
                      </div>
                      <span className="op-footer">Bellow 10 years</span>
                    </FormControl>
                    <FormControl >
                      <span className="member-title">Room</span>
                      <div className="inc-body">
                      <div className="plus"  onClick={this.setRoomMinus}><i className="fa fa-minus"></i></div>
                      <div className="number">{this.state.number_of_room}</div>
                      <div className="minus" onClick={this.setRoomPlus}><i className="fa fa-plus"></i></div>
                      </div>
                    </FormControl>
                  </form>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={this.handleClose} color="primary">
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>

        </Grid>
      </MuiPickersUtilsProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hotelList: state.SearchReducer.hotelList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hotelRequest: (creds) => dispatch(hotelRequest(creds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelModifySearch);
