import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "date-fns";
// import Button from "@material-ui/core/Button";
import Search from "./Search";
import HotelBannerSearch from "./HotelBannerSearch";
import { connect } from "react-redux";
import { getSearchData } from "../Store/Actions/SearchAction";
import { Link } from "react-router-dom";



class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      cityArray: [],
    };
  }

  componentDidMount = () => {
    this.props.getSearchData();
  };

  dateChange = (date) => {
    // alert(date);
    this.setState((state) => ({
      ...state,
      date: date,
      launchCall:false,
    }));
  };

  getChildData = (data) => {
    console.log({data},'s');
    if(data ==='launch'){
      this.setState({
        launchCall:true
      })
      return true;
    }else{
      this.setState({
        launchCall:false
      })
      return true;
    }
    
  }

  render() {
    // const searchdataList = this.props.searchdataList;
    // console.log(searchdataList);
    var select = this.props.data ;

    return (
      <div className="banner">
        <div className="banner-img">
          <img src="/assets/images/banner.jpg" className="logo web-banner" alt="img" />
          <img src="/assets/images/banner_mobile.jpg" className="logo mobile-banner" alt="img" />
        </div>
        <Container maxWidth="md">
          <div className="search-box">
            <Tabs defaultIndex={select}>
              <TabList>
                <Tab onClick={()=>this.setState({launchCall:false})}>
                  {" "}
                  <i className="fa fa-bus" aria-hidden="true"></i> Bus
                </Tab>
                <Tab onClick={()=>this.setState({launchCall:true})}>
                  {" "}
                  <i className="fa fa-ship" aria-hidden="true"></i> Launch
                </Tab>
                <Tab onClick={()=>this.setState({launchCall:false})}>
                  {" "}
                  <i className="fa fa-plane" aria-hidden="true"></i> Airlines
                </Tab>
                <Tab onClick={()=>this.setState({launchCall:false})}>
                  {" "}
                  <i className="fa fa-building" aria-hidden="true"></i> Hotels
                </Tab>
              </TabList>

              <TabPanel>
                <Search type="bus" btn_color={"search-btn"} />
              </TabPanel>
              <TabPanel>
                {/* <form
                  noValidate
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                > */}
                  <Search type="launch" btn_color={"search-btn l-btn"} />
                  {/* <Button
                    className="search-btn l-btn"
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Search
                  </Button>
                </form> */}
              </TabPanel>
              <TabPanel>
                <form noValidate autoComplete="off">
                  <div className="rocket">
                      <img src="assets/images/rocket1.gif" alt="rocket"  />
                      <h2>Coming Soon</h2>
                  </div>
                  {/* <Search type="launch" btn_color={"search-btn a-btn"} /> */}
                  {/* <Button
                    className="search-btn a-btn"
                    variant="contained"
                    color="secondary"
                  >
                    Search
                  </Button> */}
                </form>
              </TabPanel>
              <TabPanel>
                {/* <form noValidate autoComplete="off"> */}
                  <HotelBannerSearch type="hotel"  btn_color={"search-btn h-btn"} />
                  {/* <Search />
                  <Button
                    className="search-btn h-btn "
                    variant="contained"
                    color="secondary"
                  >
                    Search
                  </Button> */}
                {/* </form> */}
              </TabPanel>
            </Tabs>
          </div>
          <div className="src-box">
          {this.state.launchCall ? <div className="src-track">
            <Link to="/tracking">
              <p style={{display:'initial'}}>
                <embed height="30px"  src="images/Vessel_Track.svg" /> 
                <span className="span-name">Live Launch Tracking</span>
                </p>
            </Link> 
          </div> : '' }  
          </div>
          {/* <div className="src-box">
         
            <Grid container spacing={3} className="pad-top-60 banner-card">
              <Grid item xs={3} >
                <div className="box-icon shadow">
                  <div className="icon-box">
                    <i className="fa fa-bus" aria-hidden="true">
                      {" "}
                    </i>
                    <div className="icon-text">
                      <p className="icon-title">Bus</p>
                      <p className="icon-para">Book Bus Tickets</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="box-icon shadow">
                  <div className="icon-box">
                    <i className="fa fa-ship" aria-hidden="true">
                      {" "}
                    </i>
                    <div className="icon-text">
                      <p className="icon-title">Launch</p>
                      <p className="icon-para">Book Launch Tickets</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="box-icon shadow">
                  <div className="icon-box">
                    <i className="fa fa-plane" aria-hidden="true">
                      {" "}
                    </i>
                    <div className="icon-text">
                      <p className="icon-title">Airlines</p>
                      <p className="icon-para">Book Flights</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="box-icon shadow">
                  <div className="icon-box">
                    <i className="fa fa-building" aria-hidden="true">
                      {" "}
                    </i>
                    <div className="icon-text">
                      <p className="icon-title">Hotels</p>
                      <p className="icon-para">Book Hotels</p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          
          </div> */}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // searchdataList: state.SearchReducer.searchdataList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSearchData: () => dispatch(getSearchData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
