import HttpService from './HttpService';


export const reqForDiscount = (credentials,type) =>{
    const http = new HttpService();
    var url = '';
    if(type === "LaunchBooking" || type === 'BusBooking'){
        console.log('dsad');
        url = "coupon-validate";
    }else if(type === 'HotelBooking'){
        url = "coupon-validate-hotel";
    }
    console.log(credentials,url,type,'jk');
    return http.postData(credentials,url).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })
  }
export const BusBookingService = (credentials) =>{
    const http = new HttpService();
    let url = "hold-seats";
    return http.postData(credentials,url).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })
  }
export const LaunchBookingService = (credentials) =>{
    const http = new HttpService();
    let url = "launch-hold-seats";
    return http.postData(credentials,url).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })
  }

export const BusBookingTableService = (credentials) =>{
    const http = new HttpService();
    let url = "bus-booking";
    return http.postData(credentials,url).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })
  }

  export const HotelBookingTableService = (credentials) =>{
    //   console.log(credentials,'service');
    const http = new HttpService();
    let url = "hotel-booking";
    return http.postData(credentials,url).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })
  }



export const LaunchBookingTableService = (credentials) =>{
    const http = new HttpService();
    let url = "launch-booking";
    return http.postData(credentials,url).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })
  }


  export const BookingStatusService = () =>{
    const http = new HttpService();
    var type = localStorage.getItem('jType');
    var ref = localStorage.getItem('ref_no');
    let url = 'get-success-booking-info/'+type+'/'+ref;
    console.log(url);
    return http.getAuthenticateData(url).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })
  }