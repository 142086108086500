class HttpService {
  // url = "http://127.0.0.1:8000/";
  url = "https://powerbits.ghuri.xyz/";

  postData = async (item, added_url) => {
    const token = await localStorage.getItem("user");

    let requestOptions = {
      method: "POST",
      headers: { Authorization: token, "Content-type": "Application/json" },
      body: JSON.stringify(item),
    };
    // console.log(item,'item');
    // console.log(item,requestOptions,'requestOptions');
    return fetch(this.url + "api/" + added_url, requestOptions).then((response) =>
      response.json()
    );
  };

  getAuthenticateData = async (added_url) => {
    try {
      const token = await localStorage.getItem("user");
      let requestOptions = {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-type": "application/json",
          "Accept": "application/json",
        },
      };
      return fetch(
        this.url + "api/" + added_url,
        requestOptions
      ).then((response) => response.json());
    } catch (error) {
      console.log(error);
    }
  };

  getData = async (added_url) => {
    try {
      return fetch(this.url + "api/" + added_url).then((response) =>
        response.json()
      );
    } catch (error) {
      console.log(error);
    }
  };

  requestData = async (item, added_url) => {
    let requestOptions = {
      method: "POST",
      headers: { "Content-type": "Application/json" },
      body: JSON.stringify(item),
    };
    return fetch(this.url + "api/" + added_url, requestOptions).then((response) =>
      response.json()
    );
  };


  logout = async (added_url) => {
    const token = await localStorage.getItem("user");
    let requestOptions = {
      method: "POST",
      headers: { "Content-type": "Application/json" },
      body: JSON.stringify(token),
    };
    return fetch(this.url + "api/" + added_url, requestOptions).then((response) =>
      response.json()
    );
  };
  


};








export default HttpService;
