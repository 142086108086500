import React, { Component,Fragment } from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { HotelBookingTable, couponValidation } from "../Store/Actions/BusBookingAction";
import { withRouter } from "react-router-dom";
// import history from "../Store/Actions/history";
import { Redirect } from "react-router-dom";
import HeaderBanner from "./HeaderBanner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "../Store/Actions/history";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';


class HotelBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passenger_name: "",
      passenger_email: "",
      passenger_phone: "",
      passenger_card: "",
      nid: "",
      special_request: "",
      submit: false,
      guest_name:[],
      guest_email:[],
      dataIndex:0,
      removeItem:[],
      remain:[],
      loader:false,
      coupon_code:'',
    };
  }

  handleCoupon = (e) =>{
    e.preventDefault();
    this.setState({
      coupon_code: e.target.value,
    });
  };

  getDisCount = (e,price,hotel_id)=>{
    e.preventDefault();
    
    if(this.state.coupon_code === ''){
      toast.warning(<i className="fa fa-exclamation-triangle">{" "}The coupon code is required .</i> )
    }else{
      var disArray = {
        coupon_code : this.state.coupon_code,
        hotel_id : hotel_id,
        booking_cost : price,
      };
      this.props.couponValidation(disArray,'HotelBooking');
    }
  };



  removeCard = (cat_id,hotel_id)=>{
    var userHotelData = JSON.parse(localStorage.getItem("bookingData"));
    var ubookData = userHotelData ? userHotelData.bookData : this.props.location.state.bookData;
    if(this.state.removeItem.length !== 0){
      ubookData = this.state.removeItem;
    }
    if(this.state.removeItem.length === 1){
      this.setState({ fireRedirect: true });
    }
    if(ubookData.length >= 1){
      ubookData.map((value,index)=>{
        console.log('loop');
        if(value.id !== cat_id){
          console.log('2end');
          var dataArray ={
            hotel_id :value.hotel_id,
            id :value.id,
            room :value.room,
            price:value.price,
          }
          var removeArray =[];
          removeArray = [...this.state.removeItem];
          removeArray.push(dataArray);
          this.setState({removeItem:removeArray})
          this.state.removeItem = removeArray;
        }
      })
      console.log(this.state.removeItem,this.state.remain);
    }else{
      this.setState({ fireRedirect: true });
    }
  }
  selectedRoom = (data,type,hotel_id,av_room,price) => {

    var userHotelData = JSON.parse(localStorage.getItem("bookingData"));
    var bookData = userHotelData ? userHotelData.bookData : this.props.location.state.bookData;
    if(av_room === 0){
      toast.warning(<i className="fa fa-exclamation-triangle"> Sorry No room are available !</i>);
      return true;
    }

    if(type === 'plus'){
     
      if(bookData.length === 0){
        var dataArray ={
          hotel_id :hotel_id,
          id :data,
          room :1,
          price:price,
        }
        bookData.push(dataArray);
      }else{
        var dataCheck = true;
        bookData.filter((val,index)=>{
          if(val.id === data){
            dataCheck = false;
            var room = parseInt(val.room) + parseInt(1); 
            if(av_room < room){
              toast.warning(<i className="fa fa-exclamation-triangle"> You can booked maximum {av_room} room!</i>);
              return true;
            }
            var total_price  = (parseInt(price))*(parseInt(room));
            var dataArray ={
              hotel_id :hotel_id,
              id :data,
              room :room,
              price:total_price,
            }
            bookData.splice(index,1);
            bookData.push(dataArray);
          }
        })
        if(dataCheck){
          var dataArray ={
            hotel_id :hotel_id,
            id :data,
            room :1,
            price:price
          }
          bookData.push(dataArray);
        }
      }
    }else if(type === 'minus'){
      if(bookData.length === 0){
        return 0;
      }else{
        bookData.filter((val,index)=>{
          if(val.id === data && val.room > 1){
            var room = parseInt(val.room) - parseInt(1); 
            var total_price  = (parseInt(price))*(parseInt(room));
            var dataArray ={
              hotel_id :hotel_id,
              id :data,
              room :room,
              price:total_price,
            }
            bookData.splice(index,1);
            bookData.push(dataArray);
          }else if(val.room === 1 && val.id === data){
            // bookData.splice(index,1);
            return true;
          }
        })
      }
    }
    this.setState({bookData:bookData});
    this.state.bookData = bookData;
    // console.log( this.state.bookData);
  }
  
  handleGuest = (e,data,index,room,todos={}) =>{
    
    if(e.target.name === 'guest_name'){
      let name = [...this.state.guest_name]
      name[index] = e.target.value
      this.setState({
        guest_name:name
      })
        
    }
    else if(e.target.name === 'guest_email'){
      let email = [...this.state.guest_email]
      email[index] = e.target.value
      this.setState({
        guest_email:email
      })
    }
    console.log(this.state);

  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submit: true });
    this.state.submit = true;
    var userData = JSON.parse(localStorage.getItem("bookingData"));
    var uhotelData = userData ? userData.hotelData : this.props.location.state.hotelData;
    var ubookData = userData ? userData.bookData : this.props.location.state.bookData;
    var  check_inn = userData ? userData.check_in : this.props.location.state.check_in;
    var  check_outt = userData ? userData.check_out : this.props.location.state.check_out;
    // console.log(check_inn,'userData');
    
    // var coupon_code = ['',''];
    // var coupon_applied = [0,0];
    // var discount_amount = [0,0];

    if(this.state.removeItem.length !== 0){
      ubookData = this.state.removeItem
    }

    var book_id = [];
    var booking_details = [];
    var price = 0;
    var b = ubookData.map((val,index)=>{
      price = price + val.price;
      book_id = {
        category_id:val.id,
        number_of_room:val.room,
      };
      booking_details.push(book_id);
    });

    var discountTotal = this.props.discountData === undefined || this.props.discountData.length === 0 ? 0 : this.props.discountData.price_details.discount
    var price1 = price;
    price = parseInt(price)-parseInt(discountTotal);
    
    var guest_details = [];
    var guest = '';
    var guestArray = this.state.guest_name;
    guestArray.map((g,gindex)=>{
      guest = {
        name:g,
        email:this.state.guest_email[gindex],
      };
      guest_details.push(guest);
    })
    
  //  console.log(booking_details,'dd');

    var fromDataArray = {
      referance_no: Math.floor(Math.random() * 1000000000) + 1 +'_'+'web',
      user_id: localStorage.getItem('user_id'),
      hotel_id:uhotelData.id,
      check_in_date:check_inn.date[0]+'-'+check_inn.date[1]+'-'+check_inn.date[2],
      check_out_date:check_outt.date[0]+'-'+check_outt.date[1]+'-'+check_outt.date[2],
      booking_cost: price,
      vat: 0,
      discount_amount: 0,
      system_commission: 0,
      payable_amount: price,
      name: this.state.passenger_name,
      email: this.state.passenger_email,
      passenger_card: this.state.passenger_card,
      phone: this.state.passenger_phone,
      special_request: this.state.special_request,
      nid: this.state.nid,
      booking_details: booking_details,
      guest_details:guest_details,
      nid: this.state.nid,
      request_from: "ReactApp",
    };


    var patternPhone = new RegExp(/^[0-9\b]+$/);
    // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    // if (!pattern.test(this.state.passenger_email)) {
    //   toast.warning(<i className="fa fa-exclamation-triangle">{" "}The Passenger Email field is invalid .</i> )
    // }

    // else if (this.state.passenger_email === "") {
    //   toast.warning(<i className="fa fa-exclamation-triangle">{" "}The Passenger Email field is required</i> );
    // }


    
    if (!patternPhone.test(this.state.passenger_phone)) {
      toast.warning(<i className="fa fa-exclamation-triangle">{" "}The Passenger Phone number is invalid .</i> )
    } else if(this.state.passenger_phone.length != 11){
      toast.warning(<i className="fa fa-exclamation-triangle">{" "}The Passenger Phone number is invalid .</i> )
    } else if (this.state.passenger_name === "") {
      toast.warning(<i className="fa fa-exclamation-triangle">{" "}The Passenger Name field is required</i>);
    } else if (this.state.passenger_phone === "") {
      toast.warning(<i className="fa fa-exclamation-triangle">{" "} The Passenger Phone field is required</i>);
    } else if(this.state.passenger_card ===''){
      toast.warning(<i className="fa fa-exclamation-triangle"> The Card Type field is required</i>);
    } else if (this.state.nid === "") {
      toast.warning(<i className="fa fa-exclamation-triangle">{" "}The Passenger Nid field is required</i>);
    } else {
      // console.log(fromDataArray,'ff');
      localStorage.setItem("search_type", "bus-booking");
      this.props.HotelBookingTable(fromDataArray);
      this.setState({loader:true})
    }
  };

  

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    this.setState({ submit: true });
    this.state.submit = true;
  }



  componentWillUpdate() {
    // localStorage.removeItem("bookingData");
  }
  render() {
    const { fireRedirect } = this.state;
    var userHotelData = JSON.parse(localStorage.getItem("bookingData"));
    
    var hotelData = userHotelData ? userHotelData.hotelData : this.props.location.state.hotelData;
    var bookData = userHotelData ? userHotelData.bookData : this.props.location.state.bookData;
    var bookData = bookData === undefined ? this.props.location.state.bookData : bookData
    var hotelData = hotelData === undefined ? this.props.location.state.hotelData : hotelData

    var hhotel_id = hotelData.id;

    if(this.state.removeItem.length !== 0){
      bookData = this.state.removeItem
    }
    // console.log(this.props.location.state,userHotelData);
    var price = 0;
    bookData.map((val,index)=>{price = price + val.price;});
    // console.log(hotelData,bookData);
    var discountTotal = this.props.discountData === undefined || this.props.discountData.length === 0 ? 0 : this.props.discountData.price_details.discount
    var price1 = price;
    price = parseInt(price)-parseInt(discountTotal);


    var ratting = '';
    if(hotelData.star_ratting === 1){
        ratting = <Fragment >
            <i className="fa fa-star"> </i> 
            <i className="fa fa-star-o"> </i>  
            <i className="fa fa-star-o"> </i>  
            <i className="fa fa-star-o"> </i>  
            <i className="fa fa-star-o"> </i>  
        </Fragment>     
    }else if(hotelData.star_ratting === 2){
      ratting = <Fragment>
            <i className="fa fa-star"> </i> 
            <i className="fa fa-star"> </i>  
            <i className="fa fa-star-o"> </i>  
            <i className="fa fa-star-o"> </i>  
            <i className="fa fa-star-o"> </i>  
        </Fragment> 
    }else if(hotelData.star_ratting === 3){
      ratting = <Fragment>
            <i className="fa fa-star"> </i> 
            <i className="fa fa-star"> </i>  
            <i className="fa fa-star"> </i>  
            <i className="fa fa-star-o"> </i>  
            <i className="fa fa-star-o"> </i>  
        </Fragment> 
    }
    else if(hotelData.star_ratting === 4){
      ratting = <Fragment>
            <i className="fa fa-star"> </i> 
            <i className="fa fa-star"> </i>  
            <i className="fa fa-star"> </i>  
            <i className="fa fa-star"> </i>  
            <i className="fa fa-star-o"> </i>  
        </Fragment> 
    }
    else if(hotelData.star_ratting === 5){
      ratting = <Fragment>
            <i className="fa fa-star"> </i> 
            <i className="fa fa-star"> </i>  
            <i className="fa fa-star"> </i>  
            <i className="fa fa-star"> </i>  
            <i className="fa fa-star"> </i>  
        </Fragment> 
    }
    
    

    var  check_in = userHotelData ? userHotelData.check_in : this.props.location.state.check_in;
    var  check_out = userHotelData ? userHotelData.check_out : this.props.location.state.check_out;

    if(this.props.hotel_booking.status === "success" && this.state.submit){
      window.open(this.props.hotel_booking.data,'_self');
      localStorage.setItem('paymentUrl',this.props.hotel_booking.data);
      localStorage.setItem('jType','hotel-booking');
      if(this.state.submit){
        return <Redirect to="/payment" />
      }
    }
    return (
      <div>
        <HeaderBanner title="Hotel Booking" />
        <Container maxWidth="md" className="pad-25 ">
          <Grid container spacing={3} className="mob-order">
            <Grid item xs={8}>
              <div className="journey-details">
                <h2>Your Booking Details</h2>

                <Grid item xs={12} className="check-in-text">
                  <h2>Check In</h2>
                  <h1>{check_in.date+' '+check_in.dayName+' '+check_in.year}</h1>
                  <p>{hotelData.check_in_start+' - '+hotelData.check_in_end}</p>
                </Grid>
                <Grid item xs={12} className="check-in-text">
                  <h2>Check Out</h2>
                  <h1>{check_out.date+' '+check_out.dayName+' '+check_out.year}</h1>
                  <p>{hotelData.check_out_start+' - '+hotelData.check_out_end}</p>
                </Grid>
                
                <Grid
                  item
                  xs={12}
                  container
                  className="bg-white book-div hotel-booking "
                > 
                  <Grid item xs={5}>
                    <div className="hotel-img himg">
                      <img
                        src={hotelData.featured_image}
                        className="hotel"
                        alt="img"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <div className="hotel-name pad-left-25">{hotelData.name}</div>
                    <div className="hotel-details hotel-location">
                      <i className="fa fa-map-marker"> </i>{hotelData.address}
                    </div>
                    <div className="hotel-details hotel-location">
                      {ratting} {hotelData.total_review} review{" "}
                    </div>
                  </Grid>
                </Grid>
                <div className="good-to-know">
                  <h2>Good To Know:</h2>
                  <p>No Payment is required to secure this booking. You'll pay during your stay.</p>
                </div>  
              </div>
              <form className="order-form" onSubmit={this.handleSubmit}>
                <div className="passenger-details">
                  <h2>Passenger Details</h2>
                  <TextField
                    id="standard-required"
                    value={this.state.passenger_name}
                    name="passenger_name"
                    onChange={this.handleChange}
                    label="Passenger Name"
                  />
                  <TextField
                    id="standard-required1"
                    value={this.state.passenger_phone}
                    name="passenger_phone"
                    onChange={this.handleChange}
                    label="Passenger Phone"
                  />
                  <TextField
                    id="standard-required2"
                    value={this.state.passenger_email}
                    name="passenger_email"
                    onChange={this.handleChange}
                    label="Passenger Email"
                  />
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Select ID Type</InputLabel>
                    <Select
                      id="standard-required2"
                      value={this.state.passenger_card}
                      onChange={this.handleChange}
                      name="passenger_card"
                      className="sel0"
                    >
                      <MenuItem value="NID">NID</MenuItem>
                      <MenuItem value="Passport">Passport</MenuItem>
                      <MenuItem value="Driving Licence">Driving Licence</MenuItem>
                      <MenuItem value="Employee ID">Employee ID</MenuItem>
                      <MenuItem value="Birth Certificate">Birth Certificate</MenuItem>
                      <MenuItem value="Student ID">Student ID(Valid)</MenuItem>
                      
                    </Select>
                  </FormControl>
                  <TextField
                    id="standard-required3"
                    value={this.state.nid}
                    name="nid"
                    onChange={this.handleChange}
                    label="Passport / Driving / Student id / NID"
                  />


{hotelData.room_categories.map((val,index)=>{
  
return(
  bookData.map((data,dindex)=>{
    
    if(val.id === data.id){
      let card = [];
            for(let i = 0; i < data.room; i++){
              card.push(<Fragment >
                <Grid item xs={6} key={dindex+1+'n'}>
                  <TextField
                            id="standard-required"
                            // value={this.state.guest_name}
                            name="guest_name"
                            onChange={(e)=>this.handleGuest(e,data.id,i,data.room)}
                            label="Full guest Name"
                          />
                </Grid>
                <Grid item xs={6} key={dindex+1+'e'}>
                  <TextField
                            id="standard-required"
                            // value={this.state.guest_email}
                            name="guest_email"
                            onChange={(e)=>this.handleGuest(e,data.id,i,data.room)}
                            label="Guest Email"
                          />
                </Grid>
              </Fragment>)
              
            }
      
      return(
        <Grid item xs={12} key={dindex} container className="bg-white book-div mar-top-20">
           <Grid item xs={7}>
              <h2 className="pad-top-0 hotel-boot-rooms">{val.name}</h2>
           </Grid>
           <Grid item xs={3}>
           <div className="room-select-hotel">
                      <div className="plus-hotel" onClick={()=>this.selectedRoom(val.id,'minus',val.hotel_id,val.number_of_available_room,val.price)}>
                        <i className="fa fa-minus "></i>
                      </div>
                      <div className="number-hotel">{bookData.length === 0 ? '0' : bookData.map((data,index)=>{if(data.id === val.id){return data.room}})}</div>
                      <div className="minus-hotel" onClick={()=>this.selectedRoom(val.id,'plus',val.hotel_id,val.number_of_available_room,val.price)}>
                        <i className="fa fa-plus"></i>
                      </div>
                    </div>
           </Grid>
           <Grid item xs={2}>
              <div onClick={()=>this.removeCard(val.id,val.hotel_id)} className="remove-hotel-btn"><i className="fa fa-remove"> </i> Remove</div>
           </Grid>
           {
           val.facilities.map((fc,row)=>{
           return(
            <Grid item xs={12} container className="booking-cat">
              <p><img  src={fc.icon}className="img rule-icon" alt="img"/> {fc.name}</p>
            </Grid>
          )
          })
          }
         
          <div className="fc-p">
          <h1 className="hotel-note"> Number of Room : {bookData.map((data,index)=>{if(data.id === val.id){return data.room}})}</h1>  
          <h1 className="hotel-note">Max Persons : 4 Adults, 3 childrens (up to 6 years)</h1>  
          <p>Vat : {val.vat_applicable === 0 ? 'No' : 'Yes'}</p>
          <p>Smoking : {val.vat_applicable === 0 ? 'No' : 'Yes'}</p>
          <p>Parking : {val.vat_applicable === 0 ? 'No' : 'Yes'}</p>
          </div>
          
          {card}
          
          
        </Grid>
      )
      
    }
  })
)

})}

<h2>Special Request</h2>
<div className="fare">
  <p className="special-text">Please write your requests in English-we will share it with the property. Special requests can not be guaranteed-but the accomadation will do its best to meet your needs.You can always make a special request after your booking is complete! </p>
  <TextField id="standard-required"label="Special Request"
   value={this.state.special_request}
   name="special_request"
   onChange={this.handleChange}
   variant="outlined" />
</div>
                  <h2>Fare Details</h2>
                  <div className="fare">
                    <div className="p-row">
                      <div className="row-left">Booking Price</div>{" "}
                      <div className="row-right">{price1}</div>
                    </div>
                    <div className="p-row">
                      <div className="row-left">Processing Fee's</div>{" "}
                      <div className="row-right">0</div>
                    </div>
                    <div className="p-row">
                      <div className="row-left">
                        <TextField
                          id="standard-required"
                          label="Coupon Code"
                          variant="outlined"
                          onChange={this.handleCoupon}
                          value={this.state.coupon_code} 
                        />
                      </div>
                      <div className="row-right">
                        <button onClick={(e)=>this.getDisCount(e,price,hhotel_id)} className="coupon-apply mob-order-btn ">Apply</button>
                      </div>
                    </div>
                    <div className="p-row">
                      <div className="row-left">Discount</div>{" "}
                      <div className="row-right">{discountTotal}</div>
                    </div>
                    <div className="p-row">
                      <div className="row-left">Insurance Amount</div>{" "}
                      <div className="row-right">0</div>
                    </div>
                    <div className="p-row border-none">
                      <div className="row-left">Total</div>{" "}
                      <div className="row-right">
                        <span className="b_total">{price}</span>
                      </div>
                    </div>
                  </div>
                  <button className="pay-btn mob-order-btn">{this.state.loader ? <i className="fa fa-spinner fa-spinn"> Loading </i>:'Pay Now'}</button>
                </div>
              </form>
              
            </Grid>
            
            <Grid item xs={4}>
              <div className="order-add">
                <img src="assets/images/add1.jpg" alt="" />
              </div>
            </Grid>
          </Grid>
        </Container>
        {fireRedirect && (
          <Redirect
            to={{
              pathname: '/',
              state: {  },
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    hotel_booking: state.BusBookingReducer.hotel_booking,
    discountData: state.BusBookingReducer.discountData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    HotelBookingTable: (creds) => dispatch(HotelBookingTable(creds)),
    couponValidation: (creds,type) => dispatch(couponValidation(creds,type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HotelBooking));
