import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
// import Typography from "@material-ui/core/Typography";
import SideNav from "./SideNav";
import { connect } from "react-redux";
import { UserInformation } from "../Store/Actions/AuthAction";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { editUser } from "../Store/Actions/AuthAction";
import  HeaderBanner from "./HeaderBanner";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    var dataa = this.props.userData;
    this.state = {
      id:dataa.id,
      full_name: dataa.full_name,
      phone: dataa.phone,
      email: dataa.email,
      city: dataa.city,
      gender: dataa.gender,
      photo_id_no: dataa.photo_id_no,
      loylity_card: dataa.loylity_card,
      image:dataa.image,
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
    if(this.state.email ==='' || this.state.email === null){
      toast.warning(<i className="fa fa-exclamation-triangle"> The Email field is required</i>);
    }else if(this.state.full_name ==='' || this.state.full_name === null){
      toast.warning(<i className="fa fa-exclamation-triangle"> The Name field is required</i>);
    }else if(this.state.phone ==='' || this.state.phone === null ){
      toast.warning(<i className="fa fa-exclamation-triangle"> The Phone field is required </i>);
    }else if(this.state.photo_id_no === '' || this.state.photo_id_no === null){
      toast.warning(<i className="fa fa-exclamation-triangle"> The NID/Student/ Employee ID field is required </i>);
    }else{
      this.props.editUser(this.state);
    }
  };


  getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  fileTransform = (image) => {
    console.log('image');
    // this.getBase64(e.target.files[0], (base64String) => {
    //   this.state.image = base64String;
    //   console.log(this.state.image);
    // });
  };


  componentDidMount(props) {
    this.props.UserInformation(this.props.history);
    console.log(this.state);
    
  }
  render() {
    var userData = this.props.userData;
    return (
      <div className=" pad-bottom">
        <HeaderBanner title="Edit Profile" />
        <Container maxWidth="md">
          {/* <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Home
            </Link>
            <Typography color="textPrimary">My Account</Typography>
          </Breadcrumbs> */}
          <Grid container>
            <Grid item xs={4} className="profile-sidebar">
              <SideNav userData={userData}  />
            </Grid>
            <Grid item xs={8} className="profile-details">
              <form  autoComplete="off" onSubmit={this.handleSubmit} method="post" encType="multipart/form-data">
                <TextField id="standard-basic" onChange={this.handleChange} name="full_name" value={this.state.full_name} label="Name" />
                <TextField id="standard-basic" onChange={this.handleChange} name="phone" value={this.state.phone} label="Phone" />
                <TextField id="standard-basic" onChange={this.handleChange} name="email" value={this.state.email} label="Email" />
                <TextField id="standard-basic" onChange={this.handleChange} name="photo_id_no" label="NID/Student/Employee ID" value={this.state.photo_id_no} />
                <TextField id="standard-basic" onChange={this.handleChange} name="loylity_card" label="Loyality Card" value={this.state.loylity_card} />
                <TextField id="standard-basic" onChange={this.handleChange} name="city" value={this.state.city} label="City" />
                {/* <TextField id="standard-basic" value={this.state.gender?this.state.gender:userData.gender} label="Gender" /> */}
                <FormControl>
                <InputLabel id="demo-simple-select-helper-label">
                  Gender
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  name="gender"
                  value={
                    this.state.gender !== undefined
                      ? this.state.gender
                      : ''
                  }
                  onChange={this.handleChange}
                >
                  <MenuItem value="">
                    <em>--select--</em>
                  </MenuItem>
                  <MenuItem value='Male'>Male</MenuItem>
                  <MenuItem value='Female'>Female</MenuItem>
                </Select>
              </FormControl>
                <Link  to="my-account" className="cancle">Cancle</Link>
                {" "}
                <button className="editSave">Save</button>
              </form>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, history) => {
  return {
    userData: state.AuthReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    UserInformation: (history) => dispatch(UserInformation(history)),
    editUser: (creds) => dispatch(editUser(creds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
