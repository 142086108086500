import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
// import Typography from "@material-ui/core/Typography";
import SideNav from "./SideNav";
import { connect } from "react-redux";
import { UserInformation,passwordChange } from "../Store/Actions/AuthAction";
import TextField from '@material-ui/core/TextField';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@material-ui/core";
import  HeaderBanner from "./HeaderBanner";
import Drawer from '@material-ui/core/Drawer';

class Profile extends Component {

  constructor(props) {
    super(props);
    // var dataa = this.props.userData;
    this.state = {
      old_password:'',
      new_password:'',
      confirm_password:'',
      open:false,
      setOpenDrawer:false,
      drawerOpen:false,
    };
  }

  handleDrawerOpen = () => {
    this.setState({
      setOpenDrawer:true,
      drawerOpen:true,
    })
  };
  handleDrawerClose  = () => {
    this.setState({
      setOpenDrawer:false,
      drawerOpen:false,
    })
  };

  openPass = (e) =>{
    e.preventDefault();
    this.setState({
      open: !this.state.open,
    });
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
    if(this.state.old_password ===''){
      toast.warning(<i className="fa fa-exclamation-triangle"> The Old Password field is required</i>);
    }else if(this.state.new_password ===''){
      toast.warning(<i className="fa fa-exclamation-triangle"> The New Password field is required</i>);
    }else if(this.state.new_password.length < 8 ){
      toast.warning(<i className="fa fa-exclamation-triangle"> The password must be at least 8 characters </i>);
    }else if(this.state.confirm_password ===''){
      toast.warning(<i className="fa fa-exclamation-triangle"> The Confirm field is required</i>);
    }else if (this.state.new_password !== this.state.confirm_password){
      toast.warning(<i className="fa fa-exclamation-triangle"> Those password are not matching</i>);
    }else{
      this.props.passwordChange(this.state);
    }
  };

  componentDidMount(props) {
    this.props.UserInformation(this.props.history);
  }
  render() {
    var userData = this.props.userData;
    var openPass = this.state.open ? 'open-pass pad-30' : "close-pass";
    var changePass = this.state.open ? 'change-password close-pass' : 'change-password open-pass';

    return (
      <div className=" pad-bottom">
        <HeaderBanner title="Profile" />
        <Container maxWidth="md">
          {/* <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Home
            </Link>
            <Typography color="textPrimary">My Account</Typography>
          </Breadcrumbs> */}
          <Grid container>
            <Grid item xs={4} className="profile-sidebar">
              <SideNav userData={userData} />
            </Grid>
            <Grid item xs={8} className="profile-details">
              <p>
              <Link className="for-mob" onClick={this.handleDrawerOpen}><i className="fa fa-bars float-right mar-right-15"> </i></Link> 
              </p>
              <p><i className="fa fa-user" title="Full Name"> </i> {userData.full_name}<Link to="edit-profile"><i className="fa fa-pencil float-right mar-right-15"> </i></Link></p>
              <p><i className="fa fa-phone"> </i> {userData.phone ? userData.phone : '---'}</p>
              <p><i className="fa fa-envelope"> </i> {userData.email ? userData.email : '---'}</p>
              <p><i className="fa fa-id-card"> </i> { userData.photo_id_no ? userData.photo_id_no : '---'}</p>
              <p><i className="fa fa-bookmark-o" title="Loyality Card"> </i> { userData.loylity_card ? userData.loylity_card : '---'}</p>
              <p><i className="fa fa-building-o"> </i> {userData.city ? userData.city : '---'}</p>
              <p><i className="fa fa-mars"> </i> {userData.gender ? userData.gender : '---'}</p>
              
              <button className={changePass} onClick={this.openPass}>Change Password</button>

              <div className={openPass}>
              <form  autoComplete="off" onSubmit={this.handleSubmit} method="post" encType="multipart/form-data">
                <TextField id="standard-basic" type="password" onChange={this.handleChange} name="old_password" value={this.state.old_password} label="Old Password" />
                <TextField id="standard-basic1" type="password" onChange={this.handleChange} name="new_password" value={this.state.new_password} label="New Password" />
                <TextField id="standard-basic2" type="password" onChange={this.handleChange} name="confirm_password" value={this.state.confirm_password} label="Confirm Password" />
                
                <Button  onClick={this.openPass} className="btn-cancel">Cancel</Button>
                {" "}
                <button className="editSave">Save</button>
              </form>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Drawer
        variant="persistent"
        anchor="left"
        open={this.state.drawerOpen}
      >
         <Container maxWidth="md" className="pad-top-25">
          <Grid container>
            <Grid item xs={3} className="drawer-gird">
               <SideNav userData={userData} />
            </Grid>
            
          </Grid>
          <div className="drawer-btn">
              <button className="btn-view-seats-drawer   tracking" onClick={this.handleDrawerClose}>Close</button>
          </div>
        </Container>
      </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state,history) => {
  return {
    userData: state.AuthReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    UserInformation: (history) => dispatch(UserInformation(history)),
    passwordChange: (creds) => dispatch(passwordChange(creds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);


