import React, { Component } from "react";
// import PDFViewer from "pdf-viewer-reactjs";
import Container from "@material-ui/core/Container";
// import CustomNavigation from "./CustomNavigation";
import { connect } from "react-redux";
import { UserInformation } from "../Store/Actions/AuthAction";
import { BusBookingStatusAction } from "../Store/Actions/BusBookingAction";
import { withRouter } from "react-router-dom";
// import DownLoadFile, { DownloadFileProps } from "react-downloader-file";
import HeaderBanner from "./HeaderBanner";

class TicketPDF extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount(props) {
    this.props.UserInformation();
    this.props.BusBookingStatusAction();
  }
  render() {
    var user = this.props.userData;
    var ticket = this.props.bookingStatus;
    var pdf1 = ticket.booking_info ? ticket.booking_info[0] : [];
    var pdf2 = ticket.booking_info ? ticket.booking_info[1] : [];
    pdf1 = pdf1.ticket_pdf;
    pdf2 = pdf2?pdf2.ticket_pdf:'';
    localStorage.removeItem('jStatus');
    localStorage.removeItem('journey');
    localStorage.removeItem('returnJourney');
    localStorage.removeItem('journeyDetails');
    localStorage.removeItem('busName');
    localStorage.removeItem('departurTime');
    localStorage.removeItem('operatorName');
    localStorage.removeItem('retuenBusName');
    localStorage.removeItem('retuenDeparturTime');
    localStorage.removeItem('retuenOperatorName');
    localStorage.removeItem('paymentUrl');
    localStorage.removeItem('bookingData');
    // 04_powerbits_bus_booking_n.jpg
    return (
      <div>
        <HeaderBanner title="Ticket" />
        <Container maxWidth="md" className="pad-25 ">
          <h3>
            Welcome Mr. {user.full_name} , you booked ticket successfully{" "}
          </h3>
          {/* <DownLoadFile
            url={pdf1}
            headerParams={{
              method: "GET",
              credentials: "include",
            }}
          >
            <button className="download-btn">Download Ticket</button>
          </DownLoadFile> */}
          <div className="ifram">
            <embed height="1224" width="100%" src={pdf1}></embed>
          </div>

          {/* <button className="download-btn">Download Ticket</button> */}
          <div className="ifram">
            <embed height="1224" width="100%" src={pdf2}></embed>
          </div>
          {/* <PDFViewer
            document={{
              url: "https://powerbits.ghuri.xyz/tickets/bus/12jfjhf1548xxx_czR_Dhaka_to_Chittagong.pdf",
            }}
            // loader={
            //     <h2 style={{ color: '#fa5b35' }}>Custom loader element</h2>
            // }
            css="customViewer"
            navigation={CustomNavigation}
            protectContent
            watermark={{
              text: "powerbit !!!",
              diagonal: true,
              opacity: "0.5",
              font: "Comfortaa",
              size: "72",
              color: "#ae1a21",
            }}
          /> */}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, history) => {
  return {
    userData: state.AuthReducer.userData,
    bookingStatus: state.BusBookingReducer.bookingStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    UserInformation: () => dispatch(UserInformation()),
    BusBookingStatusAction: () => dispatch(BusBookingStatusAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TicketPDF));
