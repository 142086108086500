import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import { Fragment } from "react";
import HeaderBanner from "./HeaderBanner";

export default class Terms extends Component {
  render() {
    return (
      <Fragment>
        <HeaderBanner title="Terms of Use" />
        <Container maxWidth="md" className="pad-bottom pad-top-25 ">
          <p className="text-left">
            Vromon.com respects the privacy of others and we want you to
            understand the ways in which we collect and use information about
            you, whether you visit to browse or purchase via Vromon.com (the
            website). Please review our Privacy Policy carefully.
            
            You are agreeing to its terms by visiting or using this website. Notice of
            all changes that materially affect ways in which your personally
            identifiable information may be used or shared will be posted in our
            Privacy Policy. 
            
            If you continue to use the website after notice of
            changes have been sent to you or published on our site, you hereby
            provide your consent to the changed practices. Please note, this
            Privacy Policy does not apply to information that our affiliates may
            collect, or for our websites outside of the jurisdiction noted in
            our Terms and Conditions or any such other site, as applicable, in
            order to review those policies and manage your communication
            preferences. 
            
            Collecting, Using, and Accessing Collecting Information
            whether you make a purchase, or a visitor, we are very careful with
            what we do with your information. Collection of information is
            usually grouped into two categories: Personally identifiable, which
            is information identifiable to a specific user, and non-personally
            identifiable, which is general demographic information (gender, age,
            zip code), as well as website-wide usage information (route and
            destination popularity, pages visited, promotion participation
            levels) that does not identify any individual. 
            
            We collect non-personally identifiable information when it is affirmatively
            provided or through online activity such as cookies and other
            tracking technologies, which are discussed in greater detail below.
            We may use non-personally identifiable information for any purpose
            or disclose it to anyone. Where the two categories of information
            are combined, the resulting information is treated as personally
            identifiable information in accordance with the terms of this
            Privacy Policy. In many cases, you can view the website without
            telling us who you are or revealing any personally identifiable
            information. However, if you wish to purchase a ticket, you will
            have to provide information required of any traveler. This includes
            your name, your ID information, and your email address, your mobile
            number as well as the names of anyone traveling with you. Further,
            you must provide us with your valid payment information, including
            credit card numbers and expirations dates (all such foregoing
            information, collectively, Data). 
            
            Since Vromon.com is only the distributor and reseller of products and services provided by other
            parties (Suppliers), we are also collecting your Data on behalf of
            those Suppliers. For example, these Suppliers will need each
            traveler's name and voter ID number for identification purposes and
            to verify that each ticket is being used in accordance with that
            Supplier's terms and conditions. Our collection of and disclosure to
            our Suppliers of your Data is a required part of your transaction,
            and you agree by engaging in such transactions on the website to
            such collection and disclosure. 
            
            Apart from guest purchases, we will
            also collect personally identifiable information when you choose to
            participate in promotions, fill out surveys, complete polls, or send
            questions or comments to us via email. Please read below for more
            information on how we use information collected through these means.
            Data Use for Transactional and Promotional Purposes by Vromon.com
            We and our service providers, who assist us in connection with
            website operations, may retain and use Data and other personally
            identifiable information, as well as non-personally identifiable
            information, in providing the website to you, which uses may
            include, without limitation, the following: - To facilitate your
            purchases of bus tickets and other travel products and services that
            we distribute. - To complete your travel-product purchase
            transactions by disclosing your Data to our Suppliers. - To
            administer such purchases and provide reminders and follow-up
            information regarding your purchases and travel plans. - To allow
            participation in our various promotional programs - To inform you of
            other types of services we or our Suppliers offer. - To respond to
            your inquiries or requests, including your/customer service needs. -
            To improve our website, including after research and analysis, for
            future marketing and/or promotional use about us or the products,
            services and features we may offer. - To survey visitors about
            various topics, including their views on travel, destination
            preferences, and what further travel information we might provide. -
            For any other purpose disclosed to you at the time you are asked to
            submit your personally identifiable information. Vromon.com is a
            reseller of our Suppliers' products and services. As a requirement
            for traveling, these Suppliers must have access to the names of each
            traveler for whom a ticket is purchased, for that Supplier's product
            or service. We do not provide this information to our Suppliers for
            their direct marketing purposes, and to the best of our knowledge
            and belief, our Suppliers do not use the personal information we
            provide to them for reasons other than completing your
            travel-related transaction. If you enter our Site from the site of
            one of our marketing or referral partners, our system may recognize
            the path you have taken. Once you have left a partner's site and/or
            begin the purchase transaction on our Site, those partners do not
            have access to the personal information you provide. Such
            information is treated as Data in accordance with this Privacy
            Policy. We also may track the path you have taken, combined with
            what purchases you may make, to gauge the success of our online
            marketing and promotional campaigns. Once you leave our website, use
            of any information you provide will be governed by the privacy
            policy of that site's operator. We, our parent companies, affiliates
            and subsidiaries are not responsible for the collection, use and
            disclosure practices with respect to personally identifiable and
            other information of other or second parties. As noted, from time to
            time, we may survey you for your opinion and conduct reviews to
            enhance our website. We also send you newsletters to which you may
            have subscribed. For each of these, we may rely on service providers
            to help us collect and analyze research and provide technical
            support in sending the newsletters and other, similar emails. We do
            not disclose any Data included in this information for such
            providers' direct marketing purposes, nor so they may pass it on to
            others for the same reasons. Information Use for Site Operations,
            Corporate and Legal Compliance, we may use or disclose your
            personally identifiable information as is permitted by this Privacy
            Policy, or according to the Terms and Conditions. That includes: -
            Performing standard business operations, such transaction, payment
            processing, billing, collections, and accounting - Protecting our
            and our content providers' business interests, including ensuring
            website operation and adherence to the Terms and Conditions -
            Investigating or helping to prevent unlawful activity or activity
            that may threaten our visitors, users, employees, or property; - In
            cooperating with law-enforcement investigations, or as required by
            law or by, warrant, or other order of the court or governmental
            agency; - To help us perform these functions, we may retain other
            parties to whom we disclose personal information. These third
            parties include our customer-service providers, who handle your
            inquiries and store your Data; credit-card, debit-card and similar
            transaction processors; technical-service providers or similar
            entities that maintain the Site operations; attorneys; accountants;
            auditors; and authorized government entities or other lawfully
            authorized parties. We may also disclose your personal and other
            information to our parent companies, affiliates or subsidiaries;
            content providers; and to parties in conjunction with the
            reorganization or sale of our stock or assets, or if we are involved
            in bankruptcy proceedings. We will do so only after taking steps to
            ensure that your privacy rights continue to be protected. Any party
            to which we transfer or sell our assets will have the right to
            continue to use the personal and other information that you provide
            to us. If you have any comments or questions regarding our Privacy
            Policy, please contact us at support@axiatadigital.com.bd. While we
            cannot guarantee privacy perfection, we will address any issue to
            the best of our abilities.
          </p>
        </Container>
      </Fragment>
    );
  }
}
