import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import "date-fns";
// import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import {
  searchRequest,
  getSearchDataForHotel,
} from "../Store/Actions/SearchAction";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";

class HotelBannerSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: "",
      adult: 0,
      children: 0,
      check_in: null,
      check_out: null,
      number_of_room: 0,
      check:'',
    };
    this.onFromChange = this.onFromChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setAdultPlus = this.setAdultPlus.bind(this);
    this.setAdultMinus = this.setAdultMinus.bind(this);
    this.setChildPlus = this.setChildPlus.bind(this);
    this.setChildMinus = this.setChildMinus.bind(this);
    this.setRoomMinus = this.setRoomMinus.bind(this);
    this.setRoomPlus = this.setRoomPlus.bind(this);
  }

  handleDateChange = (date) =>{
    let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    this.setState({ check_in: [date.getFullYear(), mnth, day].join("-"),check_out: [date.getFullYear(), mnth, parseInt(day)+1].join("-") })
  } ;
  handleDateChange2 = (date) =>{
    let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    this.setState({ check_out: [date.getFullYear(), mnth, day].join("-") })
  } ;

  setAdultPlus = () =>{
    if(this.state.adult >= 0){
      var total = this.state.adult+1;
      this.setState({ adult: total});
    }
  };
  setAdultMinus = () =>{
    if(this.state.adult > 0){
      var total = this.state.adult-1;
      this.setState({adult:total})
    }
    
  };

  setRoomPlus = () =>{
    if(this.state.number_of_room >= 0){
      var total = this.state.number_of_room+1;
      this.setState({ number_of_room: total});
    }
    
  };
  setRoomMinus = () =>{
    if(this.state.number_of_room > 0){
      var total = this.state.number_of_room-1;
      this.setState({number_of_room:total})
    }
    
  };

  setChildPlus = () =>{
    if(this.state.children >= 0){
      var total = this.state.children+1;
      this.setState({ children: total});
    }
    
  };
  setChildMinus = () =>{
    if(this.state.children > 0){
      var total = this.state.children-1;
      this.setState({children:total})
    }
    
  };


   handleClickOpen = () => {
    this.setState({setOpen:true,open:true})
  };

   handleClose = () => {
    this.setState({setOpen:false,open:false})
  };



  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.check_in >= this.state.check_out) {
      toast.warning(<i className="fa fa-warning" aria-hidden="true">Invalid  Date</i>);
    } else if (this.state.city === "") {
      toast.warning(<i className="fa fa-warning" aria-hidden="true">The city field is required</i>);
    } else if (this.state.check_in === "") {
      toast.warning(<i className="fa fa-warning" aria-hidden="true">The Check in date is required</i>);
    } else if (this.state.check_out === "") {
      toast.warning(<i className="fa fa-warning" aria-hidden="true">The Check out date is required</i>);
    } else if (this.state.adult === 0) {
      toast.warning(<i className="fa fa-warning" aria-hidden="true">The Adult filed must be getter than 0</i>);
    } else {
      this.setState({ fireRedirect: true });
    }
  };

  onFromChange = (event, values) => {
    this.setState({
      city: values,
    });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount = () => {
    this.props.getSearchDataForHotel();
  };
  

  render() {
    const { fireRedirect } = this.state;
    var searchdataList =
      this.props.hotelSearchdataList.city !== undefined
        ? this.props.hotelSearchdataList.city
        : [];

    var to_url = "/hotel-search";
    
    const defaultProps = {
      options: searchdataList,
      getOptionLabel: (option) => option,
    };
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
            <Autocomplete
                {...defaultProps}
                onChange={this.onFromChange}
                autoComplete
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="City"
                    name="city"
                    value={this.state.city}
                    onChange={this.handleChange}
                    placeholder="Enter City"
                    margin="normal"
                  />
                )}
              />
            </Grid>

            <Grid container item xs={12} className="date-range">
            <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker 
                disablePast 
                format="MM/dd/yyyy"
                value={this.state.check_in}
                onChange={this.handleDateChange} 
                autoOk
                invalidDateMessage=''
                label="Check In"
                variant="inline"
                
                />
            </MuiPickersUtilsProvider>
              {/* <TextField
                onChange={this.handleChange}
                name="check_in"
                value={this.state.check_in}
                margin="normal"
                id="datepicker5"
                placeholder="mm/dd/yy"
                label="Check In"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
            </Grid>

            <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker 
                disablePast 
                format="MM/dd/yyyy"
                value={this.state.check_out}
                minDate={this.state.check_out}
                onChange={this.handleDateChange2} 
                autoOk
                invalidDateMessage=''
                label="Check Out"
                variant="inline"
                
                />
            </MuiPickersUtilsProvider>
              {/* <TextField
                onChange={this.handleChange}
                name="check_out"
                value={this.state.check_out}
                margin="normal"
                id="datepicker6"
                placeholder="mm/dd/yy"
                label="Check Out "
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
            </Grid>
            </Grid>
            <Grid item xs={12} className="mob-adult">
              <Button className="float-left select-dialog" onClick={this.handleClickOpen}>Member ( <span className="small-text">Please click here for select </span>)</Button>
              <div><p className="select-data">{this.state.adult} Adult {this.state.children} Children {this.state.number_of_room} Room</p></div>
              <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open}
                onClose={this.handleClose}
              >
                <DialogTitle className="dialog-title">Please choose option</DialogTitle>
                <DialogContent>
                  <div  className="dialog-form">
                    <FormControl >
                      <span className="member-title">Adult</span>
                      <div className="inc-body">
                      <a className="plus"  onClick={this.setAdultMinus}><i className="fa fa-minus"></i></a>
                      <div className="number">{this.state.adult}</div>
                      <a className="minus" onClick={this.setAdultPlus}><i className="fa fa-plus"></i></a>
                      </div>
                      <span className="op-footer">Above 10 years</span>
                    </FormControl>
                    <FormControl >
                      <span className="member-title">Children</span>
                      <div className="inc-body">
                      <div className="plus"  onClick={this.setChildMinus}><i className="fa fa-minus"></i></div>
                      <div className="number">{this.state.children}</div>
                      <div className="minus" onClick={this.setChildPlus}><i className="fa fa-plus"></i></div>
                      </div>
                      <span className="op-footer">Bellow 10 years</span>
                    </FormControl>
                    <FormControl >
                      <span className="member-title">Room</span>
                      <div className="inc-body">
                      <div className="plus"  onClick={this.setRoomMinus}><i className="fa fa-minus"></i></div>
                      <div className="number">{this.state.number_of_room}</div>
                      <div className="minus" onClick={this.setRoomPlus}><i className="fa fa-plus"></i></div>
                      </div>
                    </FormControl>

                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={this.handleClose} color="primary">
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item xs={12}>
              <div className="hotel-checkbox">
                <Checkbox
                  // checked={checked}
                  name="check"
                  value={this.state.check}
                  onChange={this.handleChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                I'am travaling for work
              </div>
            </Grid>
          </Grid>

          <Button
            className={this.props.btn_color}
            variant="contained"
            color="primary"
            type="submit"
          >
            Search 
          </Button>
        </form>
        {fireRedirect && (
          <Redirect
            to={{
              pathname: to_url,
              state: { searchArray: this.state },
            }}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hotelSearchdataList: state.SearchReducer.hotelSearchdataList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSearchDataForHotel: () => dispatch(getSearchDataForHotel()),
    // searchRequest: (creds) => dispatch(searchRequest(creds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelBannerSearch);
