import React, { Component,Fragment } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Loader from "./Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

export default class slide3 extends Component {
  constructor(props) {
    super();
    this.state = {
      dataArray: [],
      open3: false,
      dArray3:'',
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  handleClickOpen3 = (data) => {
    this.setState({ setOpen: true, open3: true,dArray3:data });
  };

  handleClose3 = () => {
    this.setState({ setOpen: false, open3: false });
  };


  componentDidMount() {
    this.setState({ dataArray: this.props.offer });
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            slidesToScroll: 1,
          },
        },
      ],
    };
    let dataArray = this.state.dataArray;
    
    var data = dataArray === undefined ? (
        <Loader />
      ) : (
        dataArray.map((value,index) => {
            return(
                  <div className="offer-banner-image " key={index}>
                    <img
                      src={value.image}
                      className="logo"
                      alt="img"
                      onClick={()=>this.handleClickOpen3(value)}
                    />
                  </div>
            
            )
      
        })
      );
   
    return (
      <Fragment>
        <div style={{ textAlign: "left",paddingBottom:"31px" }}>
          <button className="button sl-btn" onClick={this.previous}>
            <i className="fa fa-angle-left"></i>
          </button>
          <button className="button sl-btn" onClick={this.next}>
            <i className="fa fa-angle-right"></i>
          </button>
        </div>
        <Slider ref={(c) => (this.slider = c)} {...settings} >
           {dataArray.length > 3 ? data : ''}
        </Slider>
        {this.state.dArray3 !== ''?
        <Dialog
                open={this.state.open3}
                onClose={this.handleClose3}
                maxWidth={100}
              >
                <DialogActions>
                  <Button onClick={this.handleClose3} >
                    <i className="fa fa-close"></i>
                  </Button>
                </DialogActions>
                <DialogContent>
                  <img src={this.state.dArray3.image} className="offer-view-img" alt="img" />
                  <h3>{this.state.dArray3.name}</h3>
                  <p>{this.state.dArray3.description}</p>
                  <p><b>Hotel Name : </b>{this.state.dArray3.hotel}</p>
                  <p><b>Coupon Code : </b>{this.state.dArray3.coupon_code}</p>
                  <p><b>Discount : </b>{this.state.dArray3.discount_parcent} %</p>
                  <p><b>Maximum Discount : </b>{this.state.dArray3.maximum_discount}</p>
                  <p><b>Minimum Price : </b>{this.state.dArray3.minimum_price}</p>
                </DialogContent>
                {/* <DialogActions>
                  <Button onClick={this.handleClose3} className="footer-btn">
                    Cancel
                  </Button>
                </DialogActions> */}
              </Dialog> :''}
      </Fragment>
    );
  }
}
