import React, { Component } from "react";
import { Fragment } from "react";

export default class HeaderBanner extends Component {
  render() {
    return (
      <Fragment>
        <div
          className="header-banner web-header-banner"
          style={{
            backgroundImage:
              "url('/assets/images/04_powerbits_bus_booking_n.jpg')",
          }}
        >
          <h3 className="breadcum">{this.props.title}</h3>
        </div>
        <div
          className="header-banner mob-header-banner"
          style={{
            backgroundImage: "url('/assets/images/profile_mobile.jpg')",
          }}
        >
          <h3 className="breadcum">{this.props.title}</h3>
        </div>
      </Fragment>
    );
  }
}
