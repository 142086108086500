import React, { Component, Fragment } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

export default class AppStore extends Component {
  render() {
    return (
      <Fragment>
        <div className="app-store web-app">
          <Container maxWidth="md" >
          <Grid container spacing={3} className="pad-top-60">
              <Grid item xs={6}>
                <div className="phone-img">
                   <img src="/assets/images/app.png" className="logo" alt="img" />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="app-text">
                    <h3>Save time with the Vromon app</h3>
                    <p><i className="fa fa-check-circle-o" aria-hidden="true"> Way to booking quickly</i></p>
                    <p><i className="fa fa-check-circle-o" aria-hidden="true"> Save your time</i></p>
                    <p><i className="fa fa-check-circle-o" aria-hidden="true"> Enjoy journey & Save your time</i></p>
                    <div className="img-icon">
                       <img src="/assets/images/ios.png" className="logo" alt="img" />
                       <img src="/assets/images/android.png" className="logo" alt="img" />
                    </div>
                  
                </div>
              </Grid>
              </Grid>
            
          </Container>
        </div>
        <div className="app-store mobile-app">
          <Container maxWidth="md" >
          <Grid container spacing={3}>
              
              <Grid item xs={12}>
                <div className="app-text">
                    <h3>Save time with the Vromon app</h3>
                    <p><i className="fa fa-check-circle-o" aria-hidden="true"> Way to booking quickly</i></p>
                    <p><i className="fa fa-check-circle-o" aria-hidden="true"> Save your time</i></p>
                    <p><i className="fa fa-check-circle-o" aria-hidden="true"> Enjoy journey & Save your time</i></p>
                    <div className="img-icon">
                       <img src="/assets/images/ios.png" className="logo" alt="img" />
                       <img src="/assets/images/android.png" className="logo" alt="img" />
                    </div>
                  
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="phone-img text-center">
                   <img src="/assets/images/app.png" className="logo" alt="img" />
                </div>
              </Grid>
              </Grid>
            
          </Container>
        </div>
      </Fragment>
    );
  }
}
