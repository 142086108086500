import React, { Component,Fragment } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { UserInformation,Logout } from "../Store/Actions/AuthAction";
import { withRouter } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.uLogout = this.uLogout.bind();
  }
  uLogout =()=>{
    this.props.Logout();
    localStorage.removeItem("user");
    localStorage.removeItem("user_id");
    this.props.history.push("/");
  }
  componentDidMount(props) {
    this.props.UserInformation();
  }
  render() {
    var userData = this.props.userData;
    const authMenu = localStorage.getItem("user");
    const authUrl = userData ? (
      <Fragment>
        <ul className="drop-menu">
          <li>
            <Link to="/">
              <i className="fa fa-user"></i> {userData.full_name ? userData.full_name.substring(0,5)+'..':' '}{" "}
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </Link>

            <ul className="drop-content">
              <li>
                <Link to="/my-account">Profile</Link>
              </li>
              <li>
                <div className="logout" onClick={this.uLogout}>Logout</div>
              </li>
            </ul>
          </li>
        </ul>
      </Fragment>
    ):'';
    const menu = (
      <Fragment>
        <ul className="drop-menu">
          <li>
            <Link to="/">
              <i className="fa fa-user"></i> Sign In / Up{" "}
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </Link>

            <ul className="drop-content">
              <li>
                <Link to="/sign-in">Sign In</Link>
              </li>
              <li>
                <Link to="/sign-up">Sign Up</Link>
              </li>
            </ul>
          </li>
        </ul>
      </Fragment>
    );

    var dataHtml = authMenu ? authUrl :menu ;
    return (
     <Fragment>
       <div className="web-header">
        <AppBar position="static">
          <Container maxWidth="md">
            <Toolbar>
              <IconButton>
                <Link to="/">
                  <img
                    src="/assets/images/logo.png"
                    className="logo"
                    alt="img"
                  />
                </Link>
              </IconButton>
              <div container="true" className="menu-bar ">
                <Grid item xs={3}></Grid>

                <Grid item xs={3}>
                  <div className="profile-bar">
                    {dataHtml}
                  </div>
                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={3}>
                  <div className="social-icon">
                    <Link to="/">
                      <i className="fa fa-facebook"></i>
                    </Link>
                    <Link to="/">
                      <i className="fa fa-twitter"></i>
                    </Link>
                    <Link to="/">
                      <i className="fa fa-linkedin"></i>
                    </Link>
                    <Link to="/">
                      <i className="fa fa-google-plus"></i>
                    </Link>
                  </div>
                </Grid>
              </div>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
      <div className="mobile-header">
        <AppBar position="static">
          <Container maxWidth="md">
            <Toolbar>
              <IconButton>
                <Link to="/">
                  <img
                    src="/assets/images/logo.png"
                    className="logo"
                    alt="img"
                  />
                </Link>
              </IconButton>
              <div container="true" className="menu-bar ">
             
                  <div className="profile-bar">
                    {dataHtml}
                  </div>
 
                
                </div>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
     </Fragment>
    );
  }
}


const mapStateToProps = (state,history) => {
  return {
    userData: state.AuthReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    UserInformation: () => dispatch(UserInformation()),
    Logout: () => dispatch(Logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));