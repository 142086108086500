import HttpService from './HttpService';

export const getFactDataList = () =>{
    const http = new HttpService();
    let url = "facts";
    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });
}

export const getOfferList = () =>{
    const http = new HttpService();
    let url = "get-bus-offers";
    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });
}

export const getLaunchOfferList = () =>{
    const http = new HttpService();
    let url = "get-launch-offers";
    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });
}

export const getHotelOfferList = () =>{
    const http = new HttpService();
    let url = "get-hotel-offers";
    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });
}

export const getOfferBanner = () =>{
    const http = new HttpService();
    let url = "get-admin-offer";
    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });
}


export const getTestimonial = () =>{
    const http = new HttpService();
    let url = "testimonials";
    return http.getData(url).then(data=>{
        return data;
    }).catch((error)=> {
        console.log(error)
        return error; 
    });
}

