import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { connect } from "react-redux";
import { searchRequest } from "../Store/Actions/SearchAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ModifySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from_city: "",
      to_city: "",
      journey_date: "",
      journey_time: "",
      return_journey_date: "",
      return_journey_time: "",
    };
  }
  nextChange = (e) => {
    e.preventDefault();
    let jDate =  this.props.modArray.journey_date.split("-")
    let monthName =jDate[1];
    let day = jDate[2];
    let year = jDate[0];
    let sum = parseInt(day)+1;
    let fullDate = year+'-'+monthName+'-'+sum;
    this.props.modArray.journey_date = fullDate;
    this.props.searchRequest(this.props.modArray);

  };
  prevChange = (e) => {
    e.preventDefault();
    let jDate =  this.props.modArray.journey_date.split("-")
    let monthName =jDate[1];
    let day = jDate[2];
    let year = jDate[0];
    let sum = parseInt(day)-1;
    let fullDate = year+'-'+monthName+'-'+sum;
    this.props.modArray.journey_date = fullDate;
    this.props.searchRequest(this.props.modArray);
  };


  RnextChange = (e) => {
    e.preventDefault();
    let jDate =  this.props.modArray.return_journey_date.split("-")
    let monthName =jDate[1];
    let day = jDate[2];
    let year = jDate[0];
    let sum = parseInt(day)+1;
    let fullDate = year+'-'+monthName+'-'+sum;
    this.props.modArray.return_journey_date = fullDate;
    this.props.searchRequest(this.props.modArray);
    this.props.toggleCheck(e);
  };
  RprevChange = (e) => {
    e.preventDefault();
    let jDate =  this.props.modArray.return_journey_date.split("-")
    let monthName =jDate[1];
    let day = jDate[2];
    let year = jDate[0];
    let sum = parseInt(day)-1;
    let fullDate = year+'-'+monthName+'-'+sum;
    this.props.modArray.return_journey_date = fullDate;
    if( this.props.modArray.return_journey_date !== '' && (this.props.modArray.journey_date >= this.props.modArray.return_journey_date)){
      toast.warning(<i className="fa fa-exclamation-triangle"> Invalid Return journey </i>);
      return false;
    }
    this.props.searchRequest(this.props.modArray);
  };






  render() {
    // console.log(this.props.toggleCheck);
    var mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let jDate =  this.props.modArray.journey_date.split("-")
    let monthName = mL[jDate[1]-1];
    let day = jDate[2];
    let year = jDate[0];
    
    let rmonthName = '';
    let rday = '';
    let ryear = '';
    console.log( this.props.modArray.return_journey_date);
    if(this.props.modArray.return_journey_date !== null){
      let rDate =  this.props.modArray.return_journey_date.split("-")
      let rmonthName = mL[rDate[1]-1];
      let rday = rDate[2];
      let ryear = rDate[0];
    }
    


    var opacity1 = this.props.returnStatus === true ? 'lineHeight':'lineHeight op-04';
    var opacity2 = this.props.returnStatus === false ? 'border-right':' border-right op-04';

    var event_prev = this.props.returnStatus === false ? 'btn-next':'btn-next none-event';
    var event_prev2 = this.props.returnStatus === true ? 'btn-next':'btn-next none-event';
    // var opacity2 = this.props.returnStatus === false ? 'pad-10':'op-04';


    
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container  className="pad-10">
          <Grid item xs={6} className={opacity2}>
            <h4 className="search-title">Departure</h4>
            <h3 className="from-to">{this.props.modArray.from_city} - {this.props.modArray.to_city}</h3>
            <Container className="web-bus-modify">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div className="pad-10">
                    <button onClick={this.prevChange} className={event_prev}><i className="fa fa-angle-left"> Prev Day </i></button>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="src-middle-icon">
                    {this.props.type === 'bus' ? <i className="fa fa-bus" aria-hidden="true"></i> :<i className="fa fa-ship" aria-hidden="true"></i> }
                    
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="pad-10">
                    <button onClick={this.nextChange} className={event_prev}>Next Day <i className="fa fa-angle-right"> </i></button>
                  </div>
                </Grid>
              </Grid>
              <h3 className="modify-date">{day} {monthName} {year}</h3>
            </Container>
            <Container className="mobile-bus-modify">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div >
                    <button onClick={this.prevChange} className={event_prev}><i className="fa fa-angle-left"></i></button>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="src-middle-icon">
                  {this.props.type === 'bus' ? <i className="fa fa-bus" aria-hidden="true"></i> :<i className="fa fa-ship" aria-hidden="true"></i> }
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div >
                    <button onClick={this.nextChange} className={event_prev}><i className="fa fa-angle-right"> </i></button>
                  </div>
                </Grid>
              </Grid>
              <h3 className="modify-date">{day} {monthName} {year}</h3>
            </Container>
          </Grid>
          <Grid item xs={6} className={opacity1} >
            <h4 className="search-title">Return</h4>
            <h3 className="from-to">{this.props.modArray.to_city} - {this.props.modArray.from_city} </h3>
            <Container className="web-bus-modify">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div className="pad-10">
                  <button onClick={this.RprevChange}   className={event_prev2} ><i className="fa fa-angle-left"> Prev Day </i></button>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="src-middle-icon">
                  {this.props.type === 'bus' ? <i className="fa fa-bus" aria-hidden="true"></i> :<i className="fa fa-ship" aria-hidden="true"></i> }
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="pad-10">
                  <button onClick={this.RnextChange}   className={event_prev2} > Next Day <i className="fa fa-angle-right">  </i></button>
                  </div>
                </Grid>
              </Grid>
              <h3 className="modify-date">{rday} {rmonthName} {ryear}</h3>
            </Container>
            <Container className="mobile-bus-modify">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div>
                  <button onClick={this.RprevChange}   className={event_prev2} ><i className="fa fa-angle-left"></i></button>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="src-middle-icon">
                  {this.props.type === 'bus' ? <i className="fa fa-bus" aria-hidden="true"></i> :<i className="fa fa-ship" aria-hidden="true"></i> }
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                  <button onClick={this.RnextChange}   className={event_prev2} ><i className="fa fa-angle-right">  </i></button>
                  </div>
                </Grid>
              </Grid>
              <h3 className="modify-date">{rday} {rmonthName} {ryear}</h3>
            </Container>
          </Grid>
       

        </Grid>
      </MuiPickersUtilsProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busSearchList: state.SearchReducer.busSearchList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchRequest: (creds) => dispatch(searchRequest(creds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifySearch);
