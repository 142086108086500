import React, { Component } from 'react'
import Banner from './Banner';
import Offer from './Offer';
import OfferBanner from './OfferBanner';
import Facts from './Facts';
import Testimonial from './Testimonial';
import AppStore from './AppStore';
import Partners from './Partners';
import Accept from './Accept';
import Pop from './Pop';
import { connect } from "react-redux";
import { UserInformation } from "../Store/Actions/AuthAction";
import { factData } from "../Store/Actions/OfferAction";

class  Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
          open:false,
        };
      }
    handleClickOpen = () => {
        this.setState({open: true,});
    };
    handleClickClose = () => {
        this.setState({open: false,});
    };
    componentDidMount(props) {
        this.handleClickOpen();
        this.props.UserInformation(this.props.history);
        this.props.factData();
    }
    render() {
        var userData = this.props.userData;
        let addData = this.props.facts;
        userData = userData && userData.length !== 0 ? userData :addData;
        console.log(userData);
        return (
            <div>
                <Banner data={this.props.history.location.state !==undefined ? this.props.history.location.state.from : 0 }/>
                <Offer />
                <OfferBanner />
                <Facts />
                <Testimonial />
                <AppStore />
                <Partners />
                <Accept />
                {userData.home_pop === '' || userData.home_pop === undefined ? '' : <Pop img={userData.home_pop}/> }
                
            </div>
        )
    }
}

const mapStateToProps = (state,history) => {
    return {
      userData: state.AuthReducer.userData,
      facts: state.OfferReducer.facts,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      UserInformation: (history) => dispatch(UserInformation(history)),
      factData: () => dispatch(factData()),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Home);