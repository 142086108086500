import "./App.css";
import { ToastContainer,Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from "./Components/Header";
import Home from "./Components/Home";
import BusSearch from "./Components/BusSearch";
import LaunchSearch from "./Components/LaunchSearch";
import Footer from "./Components/Footer";
import Register from "./Components/Register";
import Login from "./Components/Login";
import OrderDetails from "./Components/OrderDetails";
import LaunchOrderDetails from "./Components/LaunchOrderDetails";
import Profile from "./Components/Profile";
import EditProfile from "./Components/EditProfile";
import MyTrips from "./Components/MyTrips";
import PaymentStatus from "./Components/PaymentStatus";
import TicketPDF from "./Components/TicketPDF";
import HotelSearch from "./Components/HotelSearch";
import HotelDetails from "./Components/HotelDetails";
import HotelBooking from "./Components/HotelBooking";
import NotFound from "./Components/NotFound";
import About from "./Components/About";
import Faq from "./Components/Faq";
import Privacy from "./Components/Privacy";
import Terms from "./Components/Terms";
import Contact from "./Components/Contact";
import Fail from "./Components/Fail";
import ResetPassword from "./Components/ResetPassword";
import ForgetPassword from "./Components/ForgetPassword";
import ErrorBoundary from "./Components/ErrorBoundary";
import LaunchTracking from "./Components/LaunchTracking";


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Zoom}
        />
        <Switch>
          {/* <ErrorBoundary> */}
          <Route exact path="/" component={Home} />
          <Route exact path="/bus-search" component={BusSearch} />
          <Route exact path="/launch-search" component={LaunchSearch} />
          <Route exact path="/sign-up" component={Register} />
          <Route exact path="/sign-in" component={Login} />
          <Route exact path="/order" component={OrderDetails} />
          <Route exact path="/launch-order" component={LaunchOrderDetails} />
          <Route exact path="/my-account" component={Profile} />
          <Route exact path="/edit-profile" component={EditProfile} />
          <Route exact path="/my-trips" component={MyTrips} />
          <Route exact path="/payment" component={PaymentStatus} />
          <Route exact path="/success" component={TicketPDF} />
          <Route exact path="/hotel-search" component={HotelSearch} />
          <Route exact path="/hotel-details" component={HotelDetails} />
          <Route exact path="/hotel-booking" component={HotelBooking} />
          <Route exact path="/about-us" component={About} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/tracking" component={LaunchTracking} />
          <Route exact path="/privacy-policy" component={Privacy} />
          <Route exact path="/terms-use-condition" component={Terms} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/fail" component={Fail} />
          <Route exact path="/forget/passwrod" component={ForgetPassword} />
          <Route exact path="/reset/passwrod/:token" component={ResetPassword} />
          {/* </ErrorBoundary> */}
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
