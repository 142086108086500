import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { factData } from "../Store/Actions/OfferAction";

class Facts extends Component {

  componentDidMount = () => {
    this.props.factData();
  };

  render() {
    return (
      <div className="facts">
        <Container maxWidth="md" spacing={6}>
          <h3 className="text-center pad-top-25">Some Facts</h3>
          <Grid container className="pad-top-25 mar-left-5 mobile-facts">
            <Grid item xs={3}>
              <div className="fact-icon">
                  <i className="fa fa-credit-card-alt" aria-hidden="true"></i>
                  <h4>{this.props.facts.transaction} +</h4>
                  <span>Transactions</span>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="fact-icon">
                  <i className="fa fa-users" aria-hidden="true"></i>
                  <h4>{this.props.facts.user} +</h4>
                  <span>Happy Customers</span>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="fact-icon">
                  <i className="fa fa-ship ship" aria-hidden="true"></i>
                  <h4>{this.props.facts.vendor} +</h4>
                  <span>Our Merchant</span>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="fact-icon">
                  <i className="fa fa-mobile" aria-hidden="true"></i>
                  <h4>7890 +</h4>
                  <span>App Download</span>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    facts: state.OfferReducer.facts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    factData: () => dispatch(factData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Facts);