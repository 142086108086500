import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from "react-router-dom";
import FormGroup from '@material-ui/core/FormGroup';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import {reqForResetPass} from '../Store/Actions/AuthAction'
import { withRouter } from "react-router-dom";
import {signInWithGoogle} from "./firebase";
import {signInWithFacebook} from "./firebase";
import {auth} from "./firebase";



class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
          email: "",
          password: "",
          password_confirmation: "",
          token:this.props.match.params.token,
          loader:false,
        };
      }
    
      handleSubmit = (e) => {
        e.preventDefault();
       if(this.state.email ===''){
        toast.warning(<i className="fa fa-exclamation-triangle"> The Email field is required</i>);
      }else if(this.state.password ===''){
        toast.warning(<i className="fa fa-exclamation-triangle"> The Password field is required</i>);
      }else if(this.state.password.length < 8 ){
        toast.warning(<i className="fa fa-exclamation-triangle"> The password must be at least 8 characters </i>);
      }else if (this.state.password !== this.state.password_confirmation){
        toast.warning(<i className="fa fa-exclamation-triangle"> Those password are not matching</i>);
      }else{
        this.props.reqForResetPass(this.state,this.props.history);
        this.setState({loader:true,});
      }
        
      };
      handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      };

  render() {
    var loadingLogin = this.props.loadingLogin;  

    return (
      <div>
        <Container maxWidth="md">
          <Container maxWidth="md">
            <div className="powerbit-login">
              <h3>Welcome To Powerbit !</h3>
              <p>Please login with your email .</p>
              <div className="form-controll">
                <div className="login-header">Reset Password</div>
                <form className="login-form" onSubmit={this.handleSubmit}>

                <TextField
                  id="standard-textarea"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  name="email"
                  onChange = {this.handleChange}
                />
                
                <TextField
                  id="standard-textarea"
                  label="Password"
                  placeholder="Password"
                  variant="outlined"
                  type="password"
                  name="password"
                  onChange = {this.handleChange}
                />
                <TextField
                  id="standard-textarea"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  variant="outlined"
                  type="password"
                  name="password_confirmation"
                  onChange = {this.handleChange}
                />
                
                <button className="sign-up-btn mar-top-10">{loadingLogin && this.state.loader ? <i className="fa fa-spinner fa-spin"></i> :''}Sign In</button>
                <div className="forget-text">
                <FormGroup row>
                </FormGroup>
                </div>
                </form>
              </div>
            </div>
          </Container>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state,history) => {
  
    return {
      authResponse:state.AuthReducer.authResponse,
      loadingLogin:state.AuthReducer.loading,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        reqForResetPass:(creds,history) => dispatch(reqForResetPass(creds,history)),
    };
  };

  
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));
