const initState = {
  loading: false,
  dataList: [],
  launchData: [],
  hotelData: [],
  banner: [],
  testimonial: [],
  facts: [],
  error:[],
};

const OfferReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOAD_DATA":
      return {
        ...state,
        loading: false,
      };
    case "LOADING":
      return {
        ...state,
        loading: true,
      };

    case "SUCCESS_BUS":
      return {
        ...state,
        loading: false,
        dataList: action.res,
      };
    case "SUCCESS_LAUNCH":
      return {
        ...state,
        loading: false,
        launchData: action.res,
      };

      case "SUCCESS_HOTEL":
      return {
        ...state,
        loading: false,
        hotelData: action.res,
      };

      case "SUCCESS_BANNER":
      return {
        ...state,
        loading: false,
        banner: action.res,
      };

      case "SUCCESS_TESTIMONIAL":
        return {
          ...state,
          loading: false,
          testimonial: action.res,
        };
      case "FACTS":
        return {
          ...state,
          loading: false,
          facts: action.res,
        };

    case "ERROR":
      return {
        ...state,
        loading: false,
        error: action.res,
      };

    default:
      return state;
  }
};

export default OfferReducer;
