import { getOfferList } from "../Services/OfferService";
import { getLaunchOfferList } from "../Services/OfferService";
import { getOfferBanner } from "../Services/OfferService";
import { getTestimonial } from "../Services/OfferService";
import { getHotelOfferList } from "../Services/OfferService";
import { getFactDataList } from "../Services/OfferService";


export const factData = () => {
  return (dispatch) => {
    dispatch({ type: "LOAD_DATA" });
    dispatch({ type: "LOADING" });

    getFactDataList().then(
      (res) => {
          dispatch({type:'FACTS',res});

      },
      (error) => {
        dispatch({ type: "ERROR", error });
      }
    );
  };
};

export const getOfferData = () => {
  return (dispatch) => {
    dispatch({ type: "LOAD_DATA" });
    dispatch({ type: "LOADING" });

    getOfferList().then(
      (res) => {
          dispatch({type:'SUCCESS_BUS',res});

      },
      (error) => {
        dispatch({ type: "ERROR", error });
      }
    );
  };
};

export const getLaunchOfferData = () => {
    return (dispatch) => {
      dispatch({ type: "LOAD_DATA" });
      dispatch({ type: "LOADING" });
  
      getLaunchOfferList().then(
        (res) => {
            dispatch({type:'SUCCESS_LAUNCH',res});
  
        },
        (error) => {
          dispatch({ type: "ERROR", error });
        }
      );
    };
  };

  export const getHotelOfferData = () => {
    return (dispatch) => {
      dispatch({ type: "LOAD_DATA" });
      dispatch({ type: "LOADING" });
  
      getHotelOfferList().then(
        (res) => {
            dispatch({type:'SUCCESS_HOTEL',res});
  
        },
        (error) => {
          dispatch({ type: "ERROR", error });
        }
      );
    };
  };


  export const offerBanner = () => {
    return (dispatch) => {
      dispatch({ type: "LOAD_DATA" });
      dispatch({ type: "LOADING" });
  
      getOfferBanner().then(
        (res) => {
            dispatch({type:'SUCCESS_BANNER',res});
  
        },
        (error) => {
          dispatch({ type: "ERROR", error });
        }
      );
    };
  };

  export const testimonialData = () => {
    return (dispatch) => {
      dispatch({ type: "LOAD_DATA" });
      dispatch({ type: "LOADING" });
  
      getTestimonial().then(
        (res) => {
            dispatch({type:'SUCCESS_TESTIMONIAL',res});
  
        },
        (error) => {
          dispatch({ type: "ERROR", error });
        }
      );
    };
  };