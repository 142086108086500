import React, { Component, Fragment } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Container from "@material-ui/core/Container";
import HotelModifySearch from "./HotelModifySearch";
// import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Collapsible from "react-collapsible";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { hotelRequest } from "../Store/Actions/SearchAction";
import { Redirect } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import { UserInformation } from "../Store/Actions/AuthAction";
import Pop from './Pop';

class HotelSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      city:'',
      adult:'',
      children:'',
      number_of_room:'',
      check_in:'',
      check_out:'',
      facility:[],
      facilityData:[],
      healthy:false,
      healthyData:[],
      ratting:[],
      rattingData:[],
      distance:[],
      distanceData:[],
      filArray:[],
      priceRange:[],
      priceRangeData:[],
      id:'',
      setOpenDrawer:false,
      drawerOpen:false,
    };
    this.goToDetails = this.goToDetails.bind(this);
  }

  handleDrawerOpen = () => {
    this.setState({
      setOpenDrawer:true,
      drawerOpen:true,
    })
  };
  handleDrawerClose  = () => {
    this.setState({
      setOpenDrawer:false,
      drawerOpen:false,
    })
  };

  goToDetails(id){
    this.setState({id:id});
    this.setState({ fireRedirect: true });
  }

  componentDidMount(){
    var data = this.props.location.state.searchArray;
    this.setState({
      city:data.city,
      adult:data.adult,
      children:data.children,
      number_of_room:data.number_of_room,
      check_in : data.check_in,
      check_out : data.check_out,
    }); 
    var dataArray = {
      city : data.city,
      check_in : data.check_in,
      check_out : data.check_out,
      children : data.children,
      adult : data.adult,
      number_of_room : data.number_of_room,
    }
    this.props.hotelRequest(dataArray);
  }

  setPriceRange = (e,price) =>{
    var mapArray = this.props.hotelList.results;
    var arr = [];
    var hArr = [];
      mapArray.map(function (data,index) {
        data.room_categories.map((val,index)=>{
          if(index === 0){
            hArr.push(data.id);
          }else{
            if (hArr.indexOf(data.id) !== -1) {
              hArr.push(data.id);
              if(val.price <= price){
                arr.push(data);
              }
            }
          }
          
        })
        
      });
      var pArr = [];
      pArr.push(price);
      this.state.priceRangeData = arr;
      this.state.priceRange = pArr;
      this.setState({ priceRangeData: arr,priceRange: pArr});
      console.log(arr,pArr);
      this.Filter();
  }

  facilityCheck = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.facility.length === 0) {
      var facility = [];
      facility.push(val);
    } else {
      var facility = this.state.facility;
      if (facility.indexOf(val) !== -1) {
        facility.splice(facility.indexOf(val), 1);
      } else {
        facility.push(val);
      }
    }
    this.state.facility = facility;
    this.setState({ facility: facility });
    
    if (this.state.facility.length !== 0) {
      var facility = this.state.facility;
      var mapArray = this.props.hotelList.results;
      mapArray.filter(function (e) {
        e.facilities.filter(function(data){ 
          if (facility.indexOf(data) !== -1) {
            filterArray.push(e);
            return filterArray;
          }
        })
      });
      this.state.facilityData = filterArray;
      this.setState({ facilityData: filterArray });
    }
    // console.log(this.state.facilityData,facility,'ioiu');
    this.Filter();
  };


healtyCheck = (e,val) => {
    e.preventDefault();
    var filterArray = [];
    if(this.state.healthy){
      this.state.healthy = false;
      this.setState({healthy:false})
    }else{
      this.state.healthy = true;
      this.setState({healthy:true})
    }
    if (this.state.healthy) {
      var mapArray = this.props.hotelList.results;
      mapArray.filter(function (e) {
          if (e.additional_helth_saftey_measures === 1) {
            filterArray.push(e);
            return filterArray;
          }
      });
      this.state.healthyData = filterArray;
      this.setState({ healthyData: filterArray });
    }
    this.Filter();
  };


rattingCheck = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.ratting.length === 0) {
      var ratting = [];
      ratting.push(val);
    } else {
      var ratting = this.state.ratting;
      if (ratting.indexOf(val) !== -1) {
        ratting.splice(ratting.indexOf(val), 1);
      } else {
        ratting.push(val);
      }
    }
    this.state.ratting = ratting;
    this.setState({ ratting: ratting });

    if (this.state.ratting.length !== 0) {
      var ratting = this.state.ratting;
      var mapArray = this.props.hotelList.results;
      mapArray.filter(function (e) {
          if (ratting.indexOf(e.star_ratting) !== -1) {
            filterArray.push(e);
            return filterArray;
          }
      });
      this.state.rattingData = filterArray;
      this.setState({ rattingData: filterArray });
    }
    this.Filter();
  };
distanceCheck = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.distance.length === 0) {
      var distance = [];
      distance.push(val);
    } else {
      var distance = this.state.distance;
      if (distance.indexOf(val) !== -1) {
        distance.splice(distance.indexOf(val), 1);
      } else {
        distance.push(val);
      }
    }
    this.state.distance = distance;
    this.setState({ distance: distance });

    if (this.state.distance.length !== 0) {
      var distance = this.state.distance;
      var mapArray = this.props.hotelList.results;
      mapArray.filter(function (e) {
        distance.filter(function(value){
          if(value === 9){

          }else{
            if (e.distance_from_center_of_city < value) {
              filterArray.push(e);
              return filterArray;
            }
          }
        })
      });
      this.state.distanceData = filterArray;
      this.setState({ distanceData: filterArray });
    }
    this.Filter();
  };


  Filter = () => {
    console.log('called filter');
    var data1 = this.props.hotelList.results;
    var facilityData = this.state.facility.length > 0 ? this.state.facilityData : data1;
    var healthyData = this.state.healthy ? this.state.healthyData : data1;
    var rattingData = this.state.ratting.length > 0 ? this.state.rattingData : data1;
    var distanceData = this.state.distance.length > 0 ? this.state.distanceData : data1;
    var priceWiseData = this.state.priceRangeData;


    var arrays = [
      data1,
      facilityData,
      healthyData,
      rattingData,
      distanceData,
      priceWiseData,
    ];
    

    var global = [];
    for (var i = 0; i < data1.length; i++) {
      var presence = true;
      for (var j = 0; j < arrays.length; j++) {
        var temp = arrays[j].find(function (value) {
          return data1[i].id === value.id;
        });
        
        if (!temp) {
          presence = false;
          break;
        }
      }
      if (presence) {
        global.push(data1[i]);
      }
    }
    this.state.filArray = global;
    this.setState({ filArray: global});
  };




  render() {
      var userData = this.props.userData;
      const { fireRedirect } = this.state;
      var priceRange = this.props.hotelList.filters !== undefined ? this.props.hotelList.filters.price_range_per_night : [];
      var hfacilities = this.props.hotelList.filters !== undefined ? this.props.hotelList.filters.hfacilities : [];
      var hotelData = this.props.hotelList.results !== undefined ? this.props.hotelList.results : [];
      // var priceStep = (priceRange[1] - priceRange[0])/5;
      // priceRange[0] = priceRange[0]-(0.1*priceRange[0]);
      // priceRange[1] = priceRange[1]+(0.1*priceRange[1]);
      var middleRange = (priceRange[1] + priceRange[0])/2;
      const marks = [
          {
            value: priceRange[0],
            label: priceRange[0],
          },
          {
            value: middleRange,
            label: middleRange,
          },
          {
            value: priceRange[1],
            label: priceRange[1],
          }

      ];
      
      function valuetext(value) {
        return `${value}`;
      }

      var facilityArray = hfacilities.map((val,index)=>{
         return(
           <Fragment>
             <FormControlLabel
                  key={index}
                  control={<Checkbox 
                    checked={ this.state.facility.indexOf(val) !== -1 ? true : false }
                    onClick={(e) => this.facilityCheck(e,val)}
                    name={val} 
                    />}
                  label={val}
                />
           </Fragment>
         )
      });
      var hotelListArray = this.state.facility.length > 0 || this.state.healthy || this.state.ratting.length > 0 || this.state.distance.length > 0
       || this.state.priceRange.length > 0 ? this.state.filArray : hotelData;
      


      var hotelArray =hotelListArray.map((val,index)=>{
        var ratting = '';
          if(val.star_ratting === 1){
              ratting = <Fragment >
                  <i className="fa fa-star"> </i> 
                  <i className="fa fa-star-o"> </i>  
                  <i className="fa fa-star-o"> </i>  
                  <i className="fa fa-star-o"> </i>  
                  <i className="fa fa-star-o"> </i>  
              </Fragment>     
          }else if(val.star_ratting === 2){
            ratting = <Fragment>
                  <i className="fa fa-star"> </i> 
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star-o"> </i>  
                  <i className="fa fa-star-o"> </i>  
                  <i className="fa fa-star-o"> </i>  
              </Fragment> 
          }else if(val.star_ratting === 3){
            ratting = <Fragment>
                  <i className="fa fa-star"> </i> 
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star-o"> </i>  
                  <i className="fa fa-star-o"> </i>  
              </Fragment> 
          }
          else if(val.star_ratting === 4){
            ratting = <Fragment>
                  <i className="fa fa-star"> </i> 
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star-o"> </i>  
              </Fragment> 
          }
          else if(val.star_ratting === 5){
            ratting = <Fragment>
                  <i className="fa fa-star"> </i> 
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star"> </i>  
              </Fragment> 
          }
          return(
            <Fragment >
            <Grid item xs={12} container className="bg-white mar-top-20 bus-card hotel-search-list">
              <Grid item xs={4}>
              <div className="hotel-img">
                 <img  src={val.featured_image} className="hotel" alt="img" />
              </div>
              </Grid>
              <Grid item xs={8} >
              <div className="hotel-name pad-left-25">{val.name}</div>
              <div className="hotel-details hotel-location">
                  <i className="fa fa-map-marker"> </i> {val.address}
              </div>
              <div className="hotel-details hotel-location">
                  {ratting}
                  {' '}
                  {val.total_review} review
                  {' '}
              </div>
              {/* <div className="hotel-details font-family">
                  price for 7 night 1 adult
              </div>
              <div className="hotel-details hotel-price">
                  TK. 14088
              </div> */}
              <button className="view-details-hotel"  onClick={()=>this.goToDetails(val.id)}>View Details</button>
              </Grid>
            </Grid>
         </Fragment>
       
          )
      });

      
    return (
      
      <div className="search-banner">
        <div className="src-banner-img">
          <img
            src="/assets/images/Hotel_desktop.jpg"
            className="logo web-bus-banner"
            alt="img"
          />
          <img
            src="/assets/images/Hotel_mobile.jpg"
            className="logo mobile-bus-banner"
            alt="img"
          />
        </div>
        <Container maxWidth="md">
          <div className="search-box">
            <Tabs>
              <TabList>
                <Tab>
                  {" "}
                  <i className="fa fa-building" aria-hidden="true"></i> Hotels
                </Tab>
              </TabList>

              <TabPanel>
                <form noValidate autoComplete="off">
                  <HotelModifySearch  stateArray={this.state}/>
                  {/* <Button
                    className="search-btn h-btn "
                    variant="contained"
                    color="secondary"
                  >
                    Modify Search
                  </Button> */}
                </form>
                <button className="btn-view-seats tracking mob-modify" onClick={this.handleDrawerOpen}>Modify Search</button>
              </TabPanel>
            </Tabs>
          </div>
        </Container>
        <Container maxWidth="md" className="pad-top-25">
          <Grid container>
            <Grid item xs={12} className="flex">
            <Grid item xs={3}  className="mobile-gird">
              <h3 className="refine-search">Refine Search</h3>
              <Collapsible trigger="Popular for bussiness " open={true}>
                {facilityArray}
              </Collapsible>

              <Collapsible trigger="Your budget( per night )" open={true}>
                <Typography id="discrete-slider-always" gutterBottom>
                  Budget Range
                </Typography>
                <Slider
                  min={priceRange[0]}
                  max={priceRange[1]}
                  defaultValue={priceRange[1]}
                  getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider-always"
                  step={1}
                  marks={marks}
                  valueLabelDisplay="auto"
                  valueLabelDisplay="on"
                  onChange={(e,marks)=>this.setPriceRange(e,marks)}
                />
              </Collapsible>

              <Collapsible trigger="Healthy & Safety" open={true}>
                <FormControlLabel
                  key={1}
                  control={<Checkbox 
                    checked={ this.state.healthy ? true : false }
                    onClick={(e) => this.healtyCheck(e,'1')} 
                    name={"Yes"} 
                    />}
                  label={"Yes"}
                />
                
              </Collapsible>

              <Collapsible trigger="Star & Other rating" open={true} >
                <FormControlLabel
                  key={1}
                  control={<Checkbox 
                    checked={ this.state.ratting.indexOf(1) !== -1 ? true : false }
                    onClick={(e) => this.rattingCheck(e,1)}
                    name={"Freewifi"} />}
                  label={<i className="fa fa-star"> </i>}
                />
                <FormControlLabel
                  key={2}
                  control={<Checkbox 
                    checked={ this.state.ratting.indexOf(2) !== -1 ? true : false }
                    onClick={(e) => this.rattingCheck(e,2)}
                    name={"Freewifi"} />}
                  label={
                  <Fragment>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    </Fragment>
                    }
                />
                <FormControlLabel
                  key={3}
                  control={<Checkbox 
                    checked={ this.state.ratting.indexOf(3) !== -1 ? true : false }
                    onClick={(e) => this.rattingCheck(e,3)}
                    name={"Freewifi"} />}
                  label={
                  <Fragment>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    </Fragment>
                    }
                />
                <FormControlLabel
                  key={4}
                  control={<Checkbox 
                    checked={ this.state.ratting.indexOf(4) !== -1 ? true : false }
                    onClick={(e) => this.rattingCheck(e,4)}
                    name={"Freewifi"} />}
                  label={
                  <Fragment>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    </Fragment>
                    }
                />
                <FormControlLabel
                  key={5}
                  control={<Checkbox 
                    checked={ this.state.ratting.indexOf(5) !== -1 ? true : false }
                    onClick={(e) => this.rattingCheck(e,5)}
                    name={"Freewifi"} />}
                  label={
                  <Fragment>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    </Fragment>
                    }
                />
              </Collapsible>

              <Collapsible
                trigger= {'Distance from center of'+' '+this.state.city}
                open={true}
              >
                <FormControlLabel
                  key={1}
                  control={<Checkbox 
                    checked={ this.state.distance.indexOf(2) !== -1 ? true : false }
                    onClick={(e) => this.distanceCheck(e,2)}
                    name={"Freewifi"} />}
                  label={"Less than 2 k.m."}
                />
                <FormControlLabel
                  key={2}
                  control={<Checkbox 
                    checked={ this.state.distance.indexOf(4) !== -1 ? true : false }
                    onClick={(e) => this.distanceCheck(e,4)}
                    name={"Freewifi"} />}
                  label={"Less than 4 k.m."}
                />
                <FormControlLabel
                  key={3}
                  control={<Checkbox 
                    checked={ this.state.distance.indexOf(6) !== -1 ? true : false }
                    onClick={(e) => this.distanceCheck(e,6)}
                    name={"Freewifi"} />}
                  label={"Less than 6 k.m."}
                />
                <FormControlLabel
                  key={4}
                  control={<Checkbox 
                    checked={ this.state.distance.indexOf(8) !== -1 ? true : false }
                    onClick={(e) => this.distanceCheck(e,8)}
                    name={"Freewifi"} />}
                  label={"Less than 8 k.m."}
                />
                <FormControlLabel
                  key={5}
                  control={<Checkbox 
                    checked={ this.state.distance.indexOf(9) !== -1 ? true : false }
                    onClick={(e) => this.distanceCheck(e,9)}
                    name={"Freewifi"} />}
                  label={"Getter than 8 k.m."}
                />
              </Collapsible>

              </Grid>
            <Grid item xs={9}>
               {hotelArray}
            </Grid>
            </Grid>  
          </Grid>
        </Container>
        {fireRedirect && (
          <Redirect
            to={{
              pathname: 'hotel-details',
              state: { dataArray: this.state },
            }}
          />
        )}


<Drawer
        variant="persistent"
        anchor="left"
        open={this.state.drawerOpen}
      >
         <Container maxWidth="md" className="pad-top-25">
          <Grid container>
          <Grid item xs={3}  className="drawer-gird">
              <h3 className="refine-search">Refine Search</h3>
              <Collapsible trigger="Popular for bussiness " open={true}>
                {facilityArray}
              </Collapsible>

              <Collapsible trigger="Your budget( per night )" open={true}>
                <Typography id="discrete-slider-always" gutterBottom>
                  Budget Range
                </Typography>
                {/* <Slider
                  min={priceRange[0]}
                  max={priceRange[1]}
                  defaultValue={priceRange[1]}
                  getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider-always"
                  step={1}
                  marks={marks}
                  valueLabelDisplay="auto"
                  valueLabelDisplay="on"
                /> */}
              </Collapsible>

              <Collapsible trigger="Healthy & Safety" open={true}>
                <FormControlLabel
                  key={1}
                  control={<Checkbox 
                    checked={ this.state.healthy ? true : false }
                    onClick={(e) => this.healtyCheck(e,'1')} 
                    name={"Yes"} 
                    />}
                  label={"Yes"}
                />
                
              </Collapsible>

              <Collapsible trigger="Star & Other rating" open={true} >
                <FormControlLabel
                  key={1}
                  control={<Checkbox 
                    checked={ this.state.ratting.indexOf(1) !== -1 ? true : false }
                    onClick={(e) => this.rattingCheck(e,1)}
                    name={"Freewifi"} />}
                  label={<i className="fa fa-star"> </i>}
                />
                <FormControlLabel
                  key={2}
                  control={<Checkbox 
                    checked={ this.state.ratting.indexOf(2) !== -1 ? true : false }
                    onClick={(e) => this.rattingCheck(e,2)}
                    name={"Freewifi"} />}
                  label={
                  <Fragment>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    </Fragment>
                    }
                />
                <FormControlLabel
                  key={3}
                  control={<Checkbox 
                    checked={ this.state.ratting.indexOf(3) !== -1 ? true : false }
                    onClick={(e) => this.rattingCheck(e,3)}
                    name={"Freewifi"} />}
                  label={
                  <Fragment>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    </Fragment>
                    }
                />
                <FormControlLabel
                  key={4}
                  control={<Checkbox 
                    checked={ this.state.ratting.indexOf(4) !== -1 ? true : false }
                    onClick={(e) => this.rattingCheck(e,4)}
                    name={"Freewifi"} />}
                  label={
                  <Fragment>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    </Fragment>
                    }
                />
                <FormControlLabel
                  key={5}
                  control={<Checkbox 
                    checked={ this.state.ratting.indexOf(5) !== -1 ? true : false }
                    onClick={(e) => this.rattingCheck(e,5)}
                    name={"Freewifi"} />}
                  label={
                  <Fragment>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    <i className="fa fa-star"> </i>
                    </Fragment>
                    }
                />
              </Collapsible>

              <Collapsible
                trigger= {'Distance from center of'+' '+this.state.city}
                open={true}
              >
                <FormControlLabel
                  key={1}
                  control={<Checkbox 
                    checked={ this.state.distance.indexOf(2) !== -1 ? true : false }
                    onClick={(e) => this.distanceCheck(e,2)}
                    name={"Freewifi"} />}
                  label={"Less than 2 k.m."}
                />
                <FormControlLabel
                  key={2}
                  control={<Checkbox 
                    checked={ this.state.distance.indexOf(4) !== -1 ? true : false }
                    onClick={(e) => this.distanceCheck(e,4)}
                    name={"Freewifi"} />}
                  label={"Less than 4 k.m."}
                />
                <FormControlLabel
                  key={3}
                  control={<Checkbox 
                    checked={ this.state.distance.indexOf(6) !== -1 ? true : false }
                    onClick={(e) => this.distanceCheck(e,6)}
                    name={"Freewifi"} />}
                  label={"Less than 6 k.m."}
                />
                <FormControlLabel
                  key={4}
                  control={<Checkbox 
                    checked={ this.state.distance.indexOf(8) !== -1 ? true : false }
                    onClick={(e) => this.distanceCheck(e,8)}
                    name={"Freewifi"} />}
                  label={"Less than 8 k.m."}
                />
                <FormControlLabel
                  key={5}
                  control={<Checkbox 
                    checked={ this.state.distance.indexOf(9) !== -1 ? true : false }
                    onClick={(e) => this.distanceCheck(e,9)}
                    name={"Freewifi"} />}
                  label={"Getter than 8 k.m."}
                />
              </Collapsible>
            </Grid>
          </Grid>
          <div className="drawer-btn">
              <button className="btn-view-seats  tracking" onClick={this.handleDrawerClose}>Apply</button>
          </div>
        </Container>
      </Drawer>
      {userData.other_pop === '' || userData.other_pop === undefined ? '' : <Pop img={userData.other_pop}/> }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hotelList: state.SearchReducer.hotelList,
    userData: state.AuthReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hotelRequest: (creds) => dispatch(hotelRequest(creds)),
    UserInformation: (history) => dispatch(UserInformation(history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelSearch);
