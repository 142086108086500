import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

export default class Accept extends Component {
  render() {
    return (
      <div className="accept">
        <Container maxWidth="md">
          <h3 className="text-center pad-top-60">We Accept</h3>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <div className="box-icon shadow">
                <div className="accept-img">
                  <img
                    src="/assets/images/we_accept_1.png"
                    className="logo"
                    alt="img"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="box-icon shadow">
                <div className="accept-img">
                  <img
                    src="/assets/images/we_accept_2.png"
                    className="logo"
                    alt="img"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="box-icon shadow">
                <div className="accept-img">
                  <img
                    src="/assets/images/we_accept_3.png"
                    className="logo"
                    alt="img"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="box-icon shadow">
                <div className="accept-img">
                  <img
                    src="/assets/images/we_accept_4.png"
                    className="logo"
                    alt="img"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="box-icon shadow">
                <div className="accept-img">
                  <img
                    src="/assets/images/we_accept_5.png"
                    className="logo"
                    alt="img"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="box-icon shadow">
                <div className="accept-img">
                  <img
                    src="/assets/images/we_accept_6.png"
                    className="logo"
                    alt="img"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
