import React, { Component } from 'react';
import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { editProfileImage } from "../Store/Actions/AuthAction";

class SideNav extends Component {

  constructor(props) {
    super(props);
    var dataa = this.props.userData;
    this.state = {
      id:dataa.id,
      full_name: dataa.full_name,
      phone: dataa.phone,
      email: dataa.email,
      city: dataa.city,
      gender: dataa.gender,
      photo_id_no: dataa.photo_id_no,
      loylity_card: dataa.loylity_card,
      image:'',
    };
  }

  getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  fileTransform = (e) => {
    var dataa = this.props.userData;

    if(e.target.files[0].size > 2097152){
      toast.warning(<i className="fa fa-exclamation-triangle"> Maximum Image Size is 2 MB.</i>);
    }else{
      this.getBase64(e.target.files[0], (base64String) => {
        // this.setState({
        //   'image':base64String,
        //   'id':dataa.id,
        // })
        this.state.image = base64String;
        this.state.id = dataa.id;
        this.props.editProfileImage(this.state);
      });
    }
    
  };



  render() {
    var data = this.props.userData;
    const location = window.location.pathname;
    return (
      <div>
      <div className="profile-img ">
        <div className="container">
          <img src={data !== undefined ? data.image : ''} className="profile" alt="img" />
          <div className="middle">
            <div className="text">
              
              <div className="image-upload">
                <label htmlFor="file-input">
                  <i className="fa fa-camera"></i>
                </label>
                   <input id="file-input" onChange={this.fileTransform}  type="file" />
              </div>
            </div>
          </div>
        </div>
        <h3>{data.full_name}</h3>
      </div>
      <div className="profile-menu">
        <ul>
          <li>
            <Link to="/" className="side-menu">
              <i className="fa fa-home"></i>Home
            </Link>
          </li>
          <li>
            <Link
              to="/my-account"
              className={
                location === "/my-account"
                  ? "side-menu active"
                  : "side-menu"
              }
            >
              <i className="fa fa-user"></i>Profile Info
            </Link>
          </li>
          <li>
            <Link
              to="/my-trips"
              className={
                location === "/my-trips"
                  ? "side-menu active"
                  : "side-menu"
              }
            >
              <i className="fa fa-briefcase"></i>My Trips
            </Link>
          </li>
        </ul>
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state, history) => {
  return {
    // userData: state.AuthReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // UserInformation: (history) => dispatch(UserInformation(history)),
    editProfileImage: (creds) => dispatch(editProfileImage(creds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
