import React from 'react'

export default function Fail() {
    return (
        <div className="fail">
            <img src="assets/images/fail.png"  alt="fail" />
            <h2>Your payment failed . </h2>
            <p>Please try again .</p>
        </div>
    )
}
