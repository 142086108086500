import { getSearchList, reqSearchList, reqForSeatLayout,getSearchListForLaunch,reqForSeatLayoutForLaunch,
  getSearchListForHotel,hotelSearchRequest,hotelSearchRequestForDetails,reqForFoodMenu,reqForLocation,reqForReview } from "../Services/SearchService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Redirect } from 'react-router';


export const getHotelReview = (hotel_id) => {
  return (dispatch) => {
    dispatch({ type: "LOADING" });
    reqForReview(hotel_id).then(
      (res) => {
        dispatch({ type: "REVIEW",res });
      },
      (error) => {
        console.log(error);
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};

export const getLocation = (journey_id,req_type) => {
  return (dispatch) => {
    dispatch({ type: "LOADING" });
    reqForLocation(journey_id,req_type).then(
      (res) => {
        dispatch({ type: "LOCATION",res });
      },
      (error) => {
        console.log(error);
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};


export const getFoodMenu = (journey_id) => {
  return (dispatch) => {
    dispatch({ type: "LOADING" });
    reqForFoodMenu(journey_id).then(
      (res) => {
        dispatch({ type: "FOOD_MENU",res });
      },
      (error) => {
        console.log(error);
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};

export const getSearchData = () => {
  return (dispatch) => {
    dispatch({ type: "LOAD_DATA" });
    dispatch({ type: "LOADING" });
    dispatch({ type: "BUS_LOADING" });

    getSearchList().then(
      (res) => {
        dispatch({ type: "SUCCESS", res });
      },
      (error) => {
        dispatch({ type: "ERROR", error });
      }
    );
  };
};
export const getSearchDataForHotel = () => {
  return (dispatch) => {
    dispatch({ type: "LOAD_DATA" });
    dispatch({ type: "LOADING" });
    dispatch({ type: "HOTEL_LOADING" });

    getSearchListForHotel().then(
      (res) => {
        // console.log(res);
        dispatch({ type: "HOTEL_SUCCESS",res });
      },
      (error) => {
        dispatch({ type: "ERROR", error });
      }
    );
  };
};



export const getSearchDataForLaunch = () => {
  return (dispatch) => {
    dispatch({ type: "LOAD_DATA" });
    dispatch({ type: "LOADING" });

    getSearchListForLaunch().then(
      (res) => {
        dispatch({ type: "LAUNCH_SUCCESS", res });
      },
      (error) => {
        dispatch({ type: "ERROR", error });
      }
    );
  };
};




export const searchRequest = (credentials) => {
  return (dispatch) => {
    dispatch({ type: "LOADING" });
    // if (credentials.from !=='') {
    //   toast.warning("From Field is Required !");
    // }

    reqSearchList(credentials).then(
      
      (res) => {
        if (res.errors) {
          if (res.errors.from_city) {
            toast.warning(res.errors.from_city[0]);
          }
          if (res.errors.to_city) {
            toast.warning(res.errors.to_city[0]);
          }
          if (res.errors.journey_date) {
            toast.warning(res.errors.journey_date[0]);
          }
        } else {
            dispatch({ type: "BUS_SEARCH_SUCCESS",res });
        }
      },
      (error) => {
        console.log(error);
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};



export const hotelRequest = (credentials) => {
  return (dispatch) => {
    dispatch({ type: "HOTEL_SEARCH_LOADING" });
    hotelSearchRequest(credentials).then(
      (res) => {
        if (res.errors) {
          if (res.errors.city) {
            toast.warning(res.errors.city[0]);
          }
          if (res.errors.check_in) {
            toast.warning(res.errors.check_in[0]);
          }
          if (res.errors.check_out) {
            toast.warning(res.errors.check_out[0]);
          }
          if (res.errors.adult) {
            toast.warning(res.errors.adult[0]);
          }
          if (res.errors.children) {
            toast.warning(res.errors.children[0]);
          }
        } else {
            dispatch({ type: "HOTEL_SEARCH_SUCCESS",res });
        }
      },
      (error) => {
        console.log(error);
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};



export const hotelRequestForDetails = (credentials) => {
  return (dispatch) => {
    dispatch({ type: "HOTEL_DETAILS_LOADING" });
    hotelSearchRequestForDetails(credentials).then(
      (res) => {
        if (res.errors) {
          if (res.errors.city) {
            toast.warning(res.errors.city[0]);
          }
          if (res.errors.check_in) {
            toast.warning(res.errors.check_in[0]);
          }
          if (res.errors.check_out) {
            toast.warning(res.errors.check_out[0]);
          }
          if (res.errors.adult) {
            toast.warning(res.errors.adult[0]);
          }
          if (res.errors.children) {
            toast.warning(res.errors.children[0]);
          }
        } else {
            dispatch({ type: "HOTEL_DETAILS_SUCCESS",res });
        }
      },
      (error) => {
        console.log(error);
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};






export const searcForSeatLayout = (journey_id) => {
  return (dispatch) => {
    dispatch({ type: "LOADING" });
    reqForSeatLayout(journey_id).then(
      (res) => {
        dispatch({ type: "BUS_SEAT_SUCCESS",res });
      },
      (error) => {
        console.log(error);
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};
export const searcForSeatLayoutForLaunch = (journey_id) => {
  return (dispatch) => {
    dispatch({ type: "LOADING" });
    reqForSeatLayoutForLaunch(journey_id).then(
      (res) => {
        dispatch({ type: "BUS_SEAT_SUCCESS",res });
      },
      (error) => {
        console.log(error);
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};
