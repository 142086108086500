import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { searcForSeatLayoutForLaunch,getFoodMenu,getLocation } from "../Store/Actions/SearchAction";
// import Loader from "./Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Link } from "react-router-dom";
import { LaunchBooking } from "../Store/Actions/BusBookingAction";
// import history from '../Store/Actions/history';
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import "./Dialog.css";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';


class LaunchSeat extends Component {
  constructor(props) {
    super(props);
   //  alert(this.props.journey_id);
    var ran = Math.floor(Math.random() * 1000000000) + 1;
    this.state = {
      boarding_point_id:'1',
      referance_no:ran,
      user_id:localStorage.getItem('user_id'),
      search_parameter:this.props.searchPara,
      journey_id:this.props.journey_id,
      place_of_boarding_point:'',
      layout_type:'launch',
      price:0,
      seat_idArray:[],
      seat_nameArray:[],
      seat_categoryArray:[],
      floor:'',
      openReview: false,
      setOpen: false,
      open: false,
      catDetails:[],
      isOpenFoodModal: false,
      setOpenFoodModal: false,
      openFoodModal: false,
      isOpenTrackingModal: false,
      setOpenTrackingModal: false,
      openTrackingModal: false,
      
    };
    // this.handleChange = this.handleChange.bind(this);
    this.selectedSeat = this.selectedSeat.bind(this);
    this.returnSave = this.returnSave.bind(this);
    this.floorChange = this.floorChange.bind(this);
    this.handleCloseReview = this.handleCloseReview.bind(this);
    this.openReview = this.openReview.bind(this);
  }

  handleClickOpenTracking = (data) => {
   this.setState({ setOpenTrackingModal: true, openTrackingModal: true});
   this.getLocationData();
 };

 getLocationData= ()=>{
   this.props.getLocation(this.props.journey_id,'launch');
 }
 
 handleCloseTracking = () => {
   this.setState({ setOpenTrackingModal: false, openTrackingModal: false});
 };
  
  handleClickOpen = (data) => {
   this.setState({ setOpenFoodModal: true, openFoodModal: true});
   this.getFoodMenuList();
 };
 handleClose = () => {
   this.setState({ setOpenFoodModal: false, openFoodModal: false});
 };

  openReview = (cat) => {
   this.setState({ setOpen: true, openReview: true,catDetails:cat });
   this.state.catDetails = cat;
  };
  handleCloseReview = () => {
   this.setState({ setOpen: false, openReview: false,catDetails:[] });
  };

  floorChange = (e) =>{
      e.preventDefault();
      this.setState({
        floor:e.target.value
      })
      this.state.floor = e.target.value
      // console.log(e.target.value);
  }


  returnSave =(e)=>{
    e.preventDefault();
    this.props.returnDataShow(e)
    if(localStorage.getItem('user_id')){
      
      if(localStorage.getItem('ref_no')){
        var ref = localStorage.getItem('ref_no');
      }else{
        localStorage.setItem('ref_no',this.state.referance_no+'web')
        var ref = localStorage.getItem('ref_no');
      }
      
      var postArray = {
        referance_no:ref,
        user_id:localStorage.getItem('user_id'),
        launch_journey_id:this.state.journey_id,
        launch_seat_cabin_id:this.state.seat_idArray,
        booking_cost:this.state.price,
        launch_seatArray:this.state.seat_nameArray,
      }
      this.props.LaunchBooking(postArray,this.props.history);
      var jo = JSON.parse(localStorage.getItem('journey'));
      var rjo = JSON.parse(localStorage.getItem('returnJourney'));
      if(jo){
        localStorage.setItem('returnJourney',JSON.stringify(postArray));
      }else{
        localStorage.setItem('journey',JSON.stringify(postArray));
      }
      var orderCheck = JSON.parse(localStorage.getItem("bookingData"));
      var retData = orderCheck?orderCheck.search_parameter:this.props.searchPara;

      localStorage.setItem('journeyDetails',JSON.stringify(retData));

      var ret = orderCheck?orderCheck.search_parameter.return_journey_date:this.props.searchPara.return_journey_date;
      if(ret && jo && rjo){
        this.props.history.push("/launch-order");
      }else if(jo){
        this.props.history.push("/launch-order");
      }
      

      if(this.props.returnBusBookingList === 'Please proceed'){
        this.props.returnDataShow()
      }

    }
  }

  selectedSeat = (e, seat_name,launch_id,category,price,seat_id) => {
    e.preventDefault();
   //  console.log(seat_name,launch_id,category,price);

    if(this.state.seat_nameArray.length >= 4){
      var selectArray = this.state.seat_nameArray;
      var seat_idArray =this.state.seat_idArray;
      // console.log(seat_idArray,seat_idArray.indexOf(seat_id));
      if(seat_idArray.indexOf(seat_id) !== -1){
          
        seat_idArray.splice(seat_idArray.indexOf(seat_id), 1);
        selectArray.splice(selectArray.indexOf(seat_name), 1);

        this.setState({ seat_nameArray: selectArray, seat_idArray: seat_idArray });

      }else{
        toast.warning(<i className="fa fa-exclamation-triangle"> You can buy maximum 4 ticket's</i>);
      }
    }else{

    if(this.state.seat_nameArray.length === 0){
      var seat_name_Array = [];
      var seat_id_Array = [];
      var seat_category_Array = [];
    }else{
      var seat_name_Array = this.state.seat_nameArray;
      var seat_id_Array = this.state.seat_idArray;
      var seat_category_Array = this.state.seat_categoryArray;
    }
    
    if (seat_name_Array.indexOf(seat_name) !== -1) {
      seat_name_Array.splice(seat_name_Array.indexOf(seat_name), 1);
    } else {
      seat_name_Array.push(seat_name);
    }

    if (seat_id_Array.indexOf(seat_id) !== -1) {
      seat_id_Array.splice(seat_id_Array.indexOf(seat_id), 1);
    } else {
      seat_id_Array.push(seat_id);
    }

    var cat_price = category+'_'+price+'_'+seat_name;

    if (seat_category_Array.indexOf(cat_price) !== -1) {
      seat_category_Array.splice(seat_category_Array.indexOf(cat_price), 1);
    } else {
      seat_category_Array.push(cat_price);
    }




    this.setState({ 
      seat_nameArray: seat_name_Array,
      seat_idArray: seat_id_Array,
      seat_categoryArray: seat_category_Array,
      });
    
     this.state.seat_nameArray =  seat_name_Array;
     this.state.seat_idArray =  seat_id_Array;
     this.state.seat_categoryArray =  seat_category_Array;
      
   //  this.setState({seatDataArray:dataArray});
   //  var price = this.props.busSeatLayout.seat_price;
    // var total =parseInt(this.state.dataArray.length)*parseInt(price);
   //  this.setState({Singleprice:price});
    }
    
   //  console.log(this.state);

  }
  
  getFoodMenuList = () =>{
   this.props.getFoodMenu(this.props.journey_id);
  }


  handleChange = (event) => {
    event.preventDefault();
    // console.log(event.target);
    // console.log( event.target.selectedOptions[0].getAttribute('place') );
    this.setState({
      boarding_point_id:event.target.value,
      place_of_boarding_point: event.target.selectedOptions[0].getAttribute('place'),
    });
  };

  checkValidation =()=>{
    if(this.state.seat_idArray.length < 1){
      toast.warning(<i className="fa fa-exclamation-triangle"> Please Choose Seat First</i>);
    }else if(this.state.boarding_point_id ===''){
      toast.warning(<i className="fa fa-exclamation-triangle"> Please Select Boarding Point </i>);
    }
  }



  componentDidMount(props) {
    //   console.log(this.props.journey_id);
    this.props.searcForSeatLayoutForLaunch(this.props.journey_id);
    
  }


   render() {
    var seatArray = this.props.busSeatLayout === undefined ? '' : this.props.busSeatLayout ;
   //  console.log(seatArray,'dfah');
    // console.log(seatArray,seatArray.cabin_categories);
    var cabin_seatArray =seatArray.cabin_seat_layout === undefined ? []: seatArray.cabin_seat_layout.floor_1.cabins;
    var launch_seatArray =seatArray.cabin_seat_layout === undefined || seatArray.cabin_seat_layout.floor_1.seats === null ? []: seatArray.cabin_seat_layout.floor_1.seats;
   //  console.log(launch_seatArray,typeof(launch_seatArray));
   //  if(launch_seatArray === null){
   //     alert('sd');
   //  }

    var cabin_seatArray_2 =seatArray.cabin_seat_layout === undefined ? []: seatArray.cabin_seat_layout.floor_2.cabins;
    var launch_seatArray_2 =seatArray.cabin_seat_layout === undefined || seatArray.cabin_seat_layout.floor_2.seats === null ? []: seatArray.cabin_seat_layout.floor_2.seats;
    
   
    
    var cabin_b2 = true;
    var cabin_b1 = true;
    var show_cab = 0;
    var show_cab2 = 0;

    var cabin = (
      <div className="seat-row minWidth" >
         {
            cabin_seatArray === undefined?[]:Object.keys(cabin_seatArray).map((val,index) => {
               // console.log(cabin_seatArray[val],'dsj');
               var prev = 0;
                var curr = 0;
                var diff = 0;
                if(cabin_seatArray[val].length > 0 || show_cab === 1){
                  cabin_b1 = true;
                  show_cab = 1;
                }else{
                  cabin_b1 = false;
                }
               //  console.log(cabin_b1);
                return(
                <div className="row-one seat padL" key={index}>
                    {
                       cabin_seatArray[val] === undefined ? (<div className="cabin-column" style={{Width:"15px"}}></div>) : cabin_seatArray[val].map((seatVal,indexx)=>{
                        // console.log(seatVal);
                        // console.log(indexx);
                        prev = seatVal.row_index
                        diff =parseInt(seatVal.row_index)-parseInt(curr);
                        curr = parseInt(seatVal.row_index);
                        if(seatVal.category_parent === 'Single AC'){
                           if(seatVal.status === 'Available'){
                              if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                                 var icon ='/assets/images/launch/ac_single_cabin_selected.png'
                              }else{
                                 var icon ='/assets/images/launch/ac_single_cabin_available.png'
                              }
                               
                            }else if(seatVal.status === 'Held by operator') {
                               var icon ='/assets/images/launch/ac_single_cabin_held.png'
                            }else if(seatVal.status === 'Booked') {
                              var icon ='/assets/images/launch/ac_single_cabin_booked.png'
                           }
                        }else if(seatVal.category_parent === 'Delux'){
                           if(seatVal.status === 'Available'){
                              if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                                 var icon ='/assets/images/launch/delux_cabin_selected.png'
                              }else{
                                 var icon ='/assets/images/launch/delux_cabin_available.png'
                              }
                               
                            }else if( seatVal.status === 'Held by operator') {
                               var icon ='/assets/images/launch/delux_cabin_held.png'
                            }else if(seatVal.status === 'Booked') {
                              var icon ='/assets/images/launch/delux_cabin_booked.png'
                           }
                        }else if(seatVal.category_parent === 'Semi Delux'){
                           if(seatVal.status === 'Available'){
                              if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                                 var icon ='/assets/images/launch/semi_delux_cabin_selected.png'
                              }else{
                                 var icon ='/assets/images/launch/semi_delux_cabin_available.png'
                              }
                               
                            }else if(seatVal.status === 'Held by operator') {
                               var icon ='/assets/images/launch/semi_delux_cabin_held.png'
                            }else if(seatVal.status === 'Booked') {
                              var icon ='/assets/images/launch/semi_delux_cabin_booked.png'
                           }
                        }else if(seatVal.category_parent === 'Double AC'){
                           if(seatVal.status === 'Available'){
                              if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                                 var icon ='/assets/images/launch/ac_double_cabin_selected.png'
                              }else{
                                 var icon ='/assets/images/launch/ac_double_cabin_available.png'
                              }
                               
                            }else if(seatVal.status === 'Held by operator') {
                               var icon ='/assets/images/launch/ac_double_cabin_held.png'
                            }else if(seatVal.status === 'Booked' ) {
                              var icon ='/assets/images/launch/ac_double_cabin_booked.png'
                           }
                        }else if(seatVal.category_parent === 'Family AC'){
                           if(seatVal.status === 'Available'){
                              if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                                 var icon ='/assets/images/launch/ac_family_cabin_selected.png'
                              }else{
                                 var icon ='/assets/images/launch/ac_family_cabin_available.png'
                              }
                               
                            }else if( seatVal.status === 'Held by operator') {
                               var icon ='/assets/images/launch/ac_family_cabin_held.png'
                            }else if(seatVal.status === 'Booked' ) {
                              var icon ='/assets/images/launch/ac_family_cabin_booked.png'
                           }

                        }else if(seatVal.category_parent === 'Double'){
                           if(seatVal.status === 'Available'){
                              if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                                 var icon ='/assets/images/launch/double_cabin_selected.png'
                              }else{
                                 var icon ='/assets/images/launch/double_cabin_available.png'
                              }
                               
                            }else if( seatVal.status === 'Held by operator') {
                               var icon ='/assets/images/launch/double_cabin_held.png'
                            }else if(seatVal.status === 'Booked') {
                              var icon ='/assets/images/launch/double_cabin_booked.png'
                           }
                        }else if(seatVal.category_parent === 'Family'){
                           if(seatVal.status === 'Available'){
                              if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                                 var icon ='/assets/images/launch/family_cabin_selected.png'
                              }else{
                                 var icon ='/assets/images/launch/family_cabin_available.png'
                              }
                               
                            }else if(seatVal.status === 'Held by operator') {
                               var icon ='/assets/images/launch/family_cabin_held.png'
                            }else if(seatVal.status === 'Booked') {
                              var icon ='/assets/images/launch/family_cabin_booked.png'
                           }
                        }else if(seatVal.category_parent === 'Single'){
                           if(seatVal.status === 'Available'){
                              if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                                 var icon ='/assets/images/launch/single_cabin_selected.png'
                              }else{
                                 var icon ='/assets/images/launch/single_cabin_available.png'
                              }
                               
                            }else if( seatVal.status === 'Held by operator') {
                               var icon ='/assets/images/launch/single_cabin_held.png'
                            }else if(seatVal.status === 'Booked' ) {
                              var icon ='/assets/images/launch/single_cabin_booked.png'
                           }
                        }else if(seatVal.category_parent === 'Sofa'){
                           if(seatVal.status === 'Available'){
                              if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                                 var icon ='/assets/images/launch/sofa_selected.png'
                              }else{
                                 var icon ='/assets/images/launch/sofa_available.png'
                              }
                               
                            }else if( seatVal.status === 'Held by operator') {
                               var icon ='/assets/images/launch/sofa_held.png'
                            }else if(seatVal.status === 'Booked' ) {
                              var icon ='/assets/images/launch/sofa_booked.png'
                           }
                        }else if(seatVal.category_parent === 'Semi VIP'){
                           if(seatVal.status === 'Available'){
                              if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                                 var icon ='/assets/images/launch/svip_cabin_selected.png'
                              }else{
                                 var icon ='/assets/images/launch/svip_cabin_available.png'
                              }
                               
                            }else if(seatVal.status === 'Held by operator') {
                               var icon ='/assets/images/launch/svip_cabin_held.png'
                            }else if(seatVal.status === 'Booked') {
                              var icon ='/assets/images/launch/svip_cabin_booked.png'
                           }
                        }else if(seatVal.category_parent === 'VIP'){
                           if(seatVal.status === 'Available'){
                              if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                                 var icon ='/assets/images/launch/vip_cabin_selected.png'
                              }else{
                                 var icon ='/assets/images/launch/vip_cabin_available.png'
                              }
                               
                            }else if( seatVal.status === 'Held by operator') {
                               var icon ='/assets/images/launch/vip_cabin_held.png'
                            }else if(seatVal.status === 'Booked' ) {
                              var icon ='/assets/images/launch/vip_cabin_booked.png'
                           }
                        }else if(seatVal.category_parent === 'Seat'){
                           if(seatVal.status === 'Available'){
                              if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                                 var icon ='/assets/images/launch/Choose_seat_selected.png'
                              }else{
                                 var icon ='/assets/images/launch/Choose_seat_available.png'
                              }
                               
                            }else if(seatVal.status === 'Held by operator') {
                               var icon ='/assets/images/launch/Choose_seat_held.png'
                            }else if(seatVal.status === 'Booked' ) {
                              var icon ='/assets/images/launch/Choose_seat_booked.png'
                           }
                        }
                        // console.log(icon,seatVal.status,indexx);
                        if(indexx == 0 && diff != 0){
                           // console.log(icon,seatVal.status,indexx,'a');
                           var html = (<div style={{paddingTop:'12px'}}><span>{" "}</span>
                           <br/>
                           <Tooltip title={seatVal.name} arrow placement="right" >
                           <img className={seatVal.status === 'Booked' || seatVal.status === 'Held by operator' ? 'not-ok':'ok'} onClick={(e) => this.selectedSeat(e, seatVal.name,seatVal.launch_id,seatVal.category,seatVal.price,seatVal.id)} src={icon} alt={seatVal.name}  />
                           </Tooltip>
                           </div>);
                          }else if(indexx != 0 && diff !=1){
                           if(parseInt(diff) === 4){
                              var mar= diff > 2 ? parseInt(20)*parseInt(diff) : 0;
                            }else if (parseInt(diff) === 3) {
                              var mar= diff > 2 ? parseInt(16)*parseInt(diff) : 0;
                            }
                            var pad= diff > 2 ? 0 : 12;
                           var html = (<div style={{marginTop:mar+'px',paddingTop:pad+'px'}}><span>{" "}</span>
                           <br/>
                           <Tooltip title={seatVal.name} arrow placement="right" >
                           <img className={seatVal.status === 'Booked' || seatVal.status === 'Held by operator' ? 'not-ok':'ok'} onClick={(e) => this.selectedSeat(e, seatVal.name,seatVal.launch_id,seatVal.category,seatVal.price,seatVal.id)} src={icon} alt={seatVal.name}  />
                           </Tooltip>
                           </div>);
                          }else{
                             
                           var html = (<div >
                              <Tooltip title={seatVal.name} arrow placement="right" >
                              <img className={seatVal.status === 'Booked' || seatVal.status === 'Held by operator' ? 'not-ok':'ok'} onClick={(e) => this.selectedSeat(e, seatVal.name,seatVal.launch_id,seatVal.category,seatVal.price,seatVal.id)} src={icon} alt={seatVal.name}  />
                              </Tooltip>
                              </div>);
                          }

                        //   console.log(icon,indexx,diff,html); 
                        //   if(cabin_seatArray[val] == []){
                        //      console.log('sdjf',cabin_seatArray[val]);
                        //   }
                        //   console.log('pk',cabin_seatArray['col'].length);
                        return(
                           <div className="cabin-column" key={indexx}>
                              {html}    
                           </div>
                        )
                     
                       })

                    }
                </div> 
            )
            
         })
         }
      </div>);
    

    var seat = (
        <div className="seat-row " >
           {
            launch_seatArray === null ? '' : Object.keys(launch_seatArray).map((val,index) => {
                // console.log(launch_seatArray[val]);
                var prev = 0;
                var curr = 0;
                var diff = 0;
                return(
                <div className="row-one seat padL " key={index}>
                    {
                       launch_seatArray[val].map((seatVal,indexx)=>{
                             prev = seatVal.row_index
                             diff =parseInt(seatVal.row_index)-parseInt(curr);
                             curr = parseInt(seatVal.row_index);
                            
                            if(seatVal.status === 'Available'){
                              if(this.state.seat_idArray.indexOf(seatVal.id) !== -1){
                                 var icon ='/assets/images/launch/choos_seat_selected.png'
                              }else{
                                 var icon ='/assets/images/launch/choos_seat_available.png'
                              }
                              
                            }else if( seatVal.status === 'Held by operator') {
                              var icon ='/assets/images/launch/choos_seat_held.png'
                            }else if(seatVal.status === 'Booked' ) {
                              var icon ='/assets/images/launch/choos_seat_booked.png'
                            }
                             if(indexx == 0 && diff != 0){
                              var html = (<div style={{paddingTop:'12px'}}><span>{" "}</span>
                              <br/>
                              <Tooltip title={seatVal.name} arrow placement="right" >
                              <img src={icon} alt={seatVal.name}  />
                              </Tooltip>
                              </div>);
                             }else if(indexx != 0 && diff !=1){
                              var html = (<div style={{paddingTop:'12px'}}><span>{" "}</span>
                              <br/>
                              <Tooltip title={seatVal.name} arrow placement="right" >
                              <img className={seatVal.status === 'Booked' || seatVal.status === 'Held by operator' ? 'not-ok':'ok'} onClick={(e) => this.selectedSeat(e, seatVal.name,seatVal.launch_id,seatVal.category,seatVal.price,seatVal.id)} src={icon} alt={seatVal.name}  />
                              </Tooltip>
                              </div>);
                             }else{
                              var html = (<div >
                                 <Tooltip title={seatVal.name} arrow placement="right" >
                                 <img className={seatVal.status === 'Booked' || seatVal.status === 'Held by operator' ? 'not-ok':'ok'} onClick={(e) => this.selectedSeat(e, seatVal.name,seatVal.launch_id,seatVal.category,seatVal.price,seatVal.id)} src={icon} alt={seatVal.name}  />
                                 </Tooltip>
                                 </div>);
                             }
                            return(
                              <div className="cabin-column" key={indexx}>
                                  {html}
                                  {/* <img src={icon} alt={seatVal.name}  /> */}
                                 
                              </div>
                            )
                        })
                    }  

                </div>
                )
            })
           }
        
        </div>
        
        );


// second floor  

var cabin_2 = (
  <div className="seat-row minWidth" >
     {
        cabin_seatArray_2 === undefined?[]:Object.keys(cabin_seatArray_2).map((val,index) => {
           var prev = 0;
            var curr = 0;
            var diff = 0;
            if(cabin_seatArray[val].length > 0 || show_cab2 === 1){
               cabin_b2 = true;
               show_cab2 = 1;
             }else{
               cabin_b2 = false;
             }
            return(
            <div className="row-one seat padL" key={index}>
                {
                   cabin_seatArray_2[val] === undefined ? (<div className="cabin-column" style={{Width:"15px"}}></div>) : cabin_seatArray_2[val].map((seatVal,indexx)=>{
                    // console.log(cabin_seatArray[val],seatVal.length);
                    prev = seatVal.row_index
                    diff =parseInt(seatVal.row_index)-parseInt(curr);
                    curr = parseInt(seatVal.row_index);
                    if(seatVal.category_parent === 'Single AC'){
                       if(seatVal.status === 'Available'){
                          if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                             var icon ='/assets/images/launch/ac_single_cabin_selected.png'
                          }else{
                             var icon ='/assets/images/launch/ac_single_cabin_available.png'
                          }
                           
                        }else if(seatVal.status === 'Held by operator') {
                           var icon ='/assets/images/launch/ac_single_cabin_held.png'
                        }else if(seatVal.status === 'Booked') {
                           var icon ='/assets/images/launch/ac_single_cabin_booked.png'
                        }
                    }else if(seatVal.category_parent === 'Delux'){
                       if(seatVal.status === 'Available'){
                          if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                             var icon ='/assets/images/launch/delux_cabin_selected.png'
                          }else{
                             var icon ='/assets/images/launch/delux_cabin_available.png'
                          }
                           
                        }else if(seatVal.status === 'Held by operator') {
                           var icon ='/assets/images/launch/delux_cabin_held.png'
                        }else if(seatVal.status === 'Booked') {
                           var icon ='/assets/images/launch/delux_cabin_booked.png'
                        }
                    }else if(seatVal.category_parent === 'Semi Delux'){
                       if(seatVal.status === 'Available'){
                          if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                             var icon ='/assets/images/launch/semi_delux_cabin_selected.png'
                          }else{
                             var icon ='/assets/images/launch/semi_delux_cabin_available.png'
                          }
                           
                        }else if(seatVal.status === 'Held by operator') {
                           var icon ='/assets/images/launch/semi_delux_cabin_held.png'
                        }else if(seatVal.status === 'Booked') {
                           var icon ='/assets/images/launch/semi_delux_cabin_booked.png'
                        }
                    }else if(seatVal.category_parent === 'Double AC'){
                       if(seatVal.status === 'Available'){
                          if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                             var icon ='/assets/images/launch/ac_double_cabin_selected.png'
                          }else{
                             var icon ='/assets/images/launch/ac_double_cabin_available.png'
                          }
                           
                        }else if(seatVal.status === 'Held by operator') {
                           var icon ='/assets/images/launch/ac_double_cabin_held.png'
                        }else if(seatVal.status === 'Booked') {
                           var icon ='/assets/images/launch/ac_double_cabin_booked.png'
                        }
                    }else if(seatVal.category_parent === 'Family AC'){
                       if(seatVal.status === 'Available'){
                          if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                             var icon ='/assets/images/launch/ac_family_cabin_selected.png'
                          }else{
                             var icon ='/assets/images/launch/ac_family_cabin_available.png'
                          }
                           
                        }else if(seatVal.status === 'Held by operator') {
                           var icon ='/assets/images/launch/ac_family_cabin_held.png'
                        }else if(seatVal.status === 'Booked') {
                           var icon ='/assets/images/launch/ac_family_cabin_booked.png'
                        }
                    }else if(seatVal.category_parent === 'Double'){
                       if(seatVal.status === 'Available'){
                          if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                             var icon ='/assets/images/launch/double_cabin_selected.png'
                          }else{
                             var icon ='/assets/images/launch/double_cabin_available.png'
                          }
                           
                        }else if( seatVal.status === 'Held by operator') {
                           var icon ='/assets/images/launch/double_cabin_held.png'
                        }else if(seatVal.status === 'Booked') {
                           var icon ='/assets/images/launch/double_cabin_booked.png'
                        }
                    }else if(seatVal.category_parent === 'Family'){
                       if(seatVal.status === 'Available'){
                          if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                             var icon ='/assets/images/launch/family_cabin_selected.png'
                          }else{
                             var icon ='/assets/images/launch/family_cabin_available.png'
                          }
                           
                        }else if(seatVal.status === 'Held by operator') {
                           var icon ='/assets/images/launch/family_cabin_held.png'
                        }else if(seatVal.status === 'Booked') {
                           var icon ='/assets/images/launch/family_cabin_booked.png'
                        }
                    }else if(seatVal.category_parent === 'Single'){
                       if(seatVal.status === 'Available'){
                          if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                             var icon ='/assets/images/launch/single_cabin_selected.png'
                          }else{
                             var icon ='/assets/images/launch/single_cabin_available.png'
                          }
                           
                        }else if(seatVal.status === 'Held by operator') {
                           var icon ='/assets/images/launch/single_cabin_held.png'
                        }else if(seatVal.status === 'Booked') {
                           var icon ='/assets/images/launch/single_cabin_booked.png'
                        }
                    }else if(seatVal.category_parent === 'Sofa'){
                       if(seatVal.status === 'Available'){
                          if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                             var icon ='/assets/images/launch/sofa_selected.png'
                          }else{
                             var icon ='/assets/images/launch/sofa_available.png'
                          }
                           
                        }else if(seatVal.status === 'Held by operator') {
                           var icon ='/assets/images/launch/sofa_held.png'
                        }else if(seatVal.status === 'Booked') {
                           var icon ='/assets/images/launch/sofa_booked.png'
                        }
                    }else if(seatVal.category_parent === 'Semi VIP'){
                       if(seatVal.status === 'Available'){
                          if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                             var icon ='/assets/images/launch/svip_cabin_selected.png'
                          }else{
                             var icon ='/assets/images/launch/svip_cabin_available.png'
                          }
                           
                        }else if(seatVal.status === 'Held by operator') {
                           var icon ='/assets/images/launch/svip_cabin_held.png'
                        }else if(seatVal.status === 'Booked' ) {
                           var icon ='/assets/images/launch/svip_cabin_booked.png'
                        }
                    }else if(seatVal.category_parent === 'VIP'){
                       if(seatVal.status === 'Available'){
                          if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                             var icon ='/assets/images/launch/vip_cabin_selected.png'
                          }else{
                             var icon ='/assets/images/launch/vip_cabin_available.png'
                          }
                           
                        }else if(seatVal.status === 'Held by operator') {
                           var icon ='/assets/images/launch/vip_cabin_held.png'
                        }else if(seatVal.status === 'Booked' ) {
                           var icon ='/assets/images/launch/vip_cabin_booked.png'
                        }
                    }else if(seatVal.category_parent === 'Seat'){
                       if(seatVal.status === 'Available'){
                          if(this.state.seat_nameArray.indexOf(seatVal.name) !== -1){
                             var icon ='/assets/images/launch/Choose_seat_selected.png'
                          }else{
                             var icon ='/assets/images/launch/Choose_seat_available.png'
                          }
                           
                        }else if(seatVal.status === 'Held by operator') {
                           var icon ='/assets/images/launch/Choose_seat_held.png'
                        }else if(seatVal.status === 'Booked') {
                           var icon ='/assets/images/launch/Choose_seat_booked.png'
                        }
                    }
                    if(indexx == 0 && diff != 0){
                       var html_cabin = (<div style={{paddingTop:'12px'}}><span>{" "}</span>
                       <br/>
                       <Tooltip title={seatVal.name} arrow placement="right" >
                       <img className={seatVal.status === 'Booked' || seatVal.status === 'Held by operator' ? 'not-ok':'ok'} onClick={(e) => this.selectedSeat(e, seatVal.name,seatVal.launch_id,seatVal.category,seatVal.price,seatVal.id)} src={icon} alt={seatVal.name}  />
                       </Tooltip>
                       </div>);
                      }else if(indexx != 0 && diff !=1){
                         if(parseInt(diff) === 4){
                           var mar= diff > 2 ? parseInt(20)*parseInt(diff) : 0;
                         }else if (parseInt(diff) === 3) {
                           var mar= diff > 2 ? parseInt(16)*parseInt(diff) : 0;
                         }
                         var pad= diff > 2 ? 0 : 12;
                         
                       var html_cabin = (<div style={{marginTop:mar+'px',paddingTop:pad+'px'}}><span>{" "}</span>
                       <br/>
                       <Tooltip title={seatVal.name} arrow placement="right" >
                       <img className={seatVal.status === 'Booked' || seatVal.status === 'Held by operator' ? 'not-ok':'ok'} onClick={(e) => this.selectedSeat(e, seatVal.name,seatVal.launch_id,seatVal.category,seatVal.price,seatVal.id)} src={icon} alt={seatVal.name}  />
                       </Tooltip>
                       </div>);
                      }else{
                       var html_cabin = (<div >
                          <Tooltip title={seatVal.name} arrow placement="right" >
                          <img className={seatVal.status === 'Booked' || seatVal.status === 'Held by operator' ? 'not-ok':'ok'} onClick={(e) => this.selectedSeat(e, seatVal.name,seatVal.launch_id,seatVal.category,seatVal.price,seatVal.id)} src={icon} alt={seatVal.name}  />
                          </Tooltip>
                          </div>);
                      }
                    //   if(cabin_seatArray[val] == []){
                    //      console.log('sdjf',cabin_seatArray[val]);
                    //   }
                    //   console.log('pk',cabin_seatArray['col'].length);
                    return(
                       <div className="cabin-column" key={indexx}>
                          {html_cabin}    
                       </div>
                    )
                 
                   })

                }
            </div> 
        )
        
     })
     }
  </div>);


var seat_2 = (
    <div className="seat-row" >
       {
        launch_seatArray_2 === null ? '' : Object.keys(launch_seatArray_2).map((val,index) => {
            // console.log(launch_seatArray[val]);
            var prev = 0;
            var curr = 0;
            var diff = 0;
            return(
            <div className="row-one seat padL" key={index}>
                {
                   launch_seatArray_2[val].map((seatVal,indexx)=>{
                         prev = seatVal.row_index
                         diff =parseInt(seatVal.row_index)-parseInt(curr);
                         curr = parseInt(seatVal.row_index);
                        
                        if(seatVal.status === 'Available'){
                          if(this.state.seat_idArray.indexOf(seatVal.id) !== -1){
                             var icon ='/assets/images/launch/choos_seat_selected.png'
                          }else{
                             var icon ='/assets/images/launch/choos_seat_available.png'
                          }
                          
                        }else if( seatVal.status === 'Held by operator') {
                          var icon ='/assets/images/launch/choos_seat_held.png'
                        }else if(seatVal.status === 'Booked') {
                           var icon ='/assets/images/launch/choos_seat_booked.png'
                         }
                         if(indexx == 0 && diff != 0){
                          var html_seat = (<div style={{paddingTop:'12px'}}><span>{" "}</span>
                          <br/>
                          <Tooltip title={seatVal.name} arrow placement="right" >
                          <img src={icon} alt={seatVal.name}  />
                          </Tooltip>
                          </div>);
                         }else if(indexx != 0 && diff !=1){
                          var html_seat = (<div style={{paddingTop:'12px'}}><span>{" "}</span>
                          <br/>
                          <Tooltip title={seatVal.name} arrow placement="right" >
                          <img className={seatVal.status === 'Booked' || seatVal.status === 'Held by operator' ? 'not-ok':'ok'} onClick={(e) => this.selectedSeat(e, seatVal.name,seatVal.launch_id,seatVal.category,seatVal.price,seatVal.id)} src={icon} alt={seatVal.name}  />
                          </Tooltip>
                          </div>);
                         }else{
                          var html_seat = (<div >
                             <Tooltip title={seatVal.name} arrow placement="right" >
                             <img className={seatVal.status === 'Booked' || seatVal.status === 'Held by operator' ? 'not-ok':'ok'} onClick={(e) => this.selectedSeat(e, seatVal.name,seatVal.launch_id,seatVal.category,seatVal.price,seatVal.id)} src={icon} alt={seatVal.name}  />
                             </Tooltip>
                             </div>);
                         }
                        return(
                          <div className="cabin-column" key={indexx}>
                              {html_seat}
                              {/* <img src={icon} alt={seatVal.name}  /> */}
                             
                          </div>
                        )
                    })
                }  

            </div>
            )
        })
       }
    
    </div>
    
    );






        var total_p = 0;
        var selectedSeatRow = this.state.seat_nameArray.length > 0 ?this.state.seat_nameArray.map((val,index)=>{
         // var total_price =this.state.price;
           var cat =  this.state.seat_categoryArray[index];
           var arr =cat.split("_");
           var category = arr[0];
           var price = arr[1];
           total_p =parseInt(total_p)+parseInt(price);
           this.state.price = total_p;
           
            return(
              <tr key={index}>
                <td>{val}</td>
                <td>{category}</td>
                <td>{price}</td>
              </tr>
            )
          }):<tr><td colSpan={2}>Please choose your seat</td></tr>;


         //  console.log(total_p);


         //  this.setState({price:total_p});
         //  this.state.price = total_p;

        var boarding  = this.props.busSeatLayout.boarding_points;
        var boarding_pointArray = boarding !== undefined  ? this.props.busSeatLayout.boarding_points : [];
        var returnPage = this.state.boarding_point_id  ?(<button onClick={(e) => this.props.returnDataShow(e),this.returnSave} >Confirm</button>):(<button onClick={this.checkValidation}>Confirm</button>) ;
        var loginPage  = this.state.boarding_point_id  ?(<Link to={{ pathname: '/sign-in', state: { bookJourney: this.state} }}>Confirm</Link>):(<button onClick={this.checkValidation}>Confirm</button>) ;
        var reBtn = localStorage.getItem('user')?returnPage:loginPage;
        var floor = this.state.floor ? this.state.floor : 1;
      //   console.log(floor);
        if(floor == 2){
          var s_floor = 'show-floor';
          var f_floor = 'hide-floor'
        }else if(floor == 1){
          var f_floor = 'show-floor';
          var s_floor = 'hide-floor'
        }
      
       console.log(launch_seatArray,launch_seatArray_2);
    return (
      <Fragment>
          {/* <TextField
                id="standard-select-currency-native"
                select
                // label="Choose Boarding Point"
                value={this.state.floor}
                onChange={this.floorChange}
                name="floor"
                SelectProps={{
                  native: true,
                }}
                helperText="Please select your floor"
              >
                <option value="1">First Floor</option>
                <option value="2">Second Floor</option>
                
              </TextField> */}
        <Grid container className="pad-10 ">
            {seatArray.cabin_categories === undefined ? [] : seatArray.cabin_categories.map((cat,ind)=>{
                return(
                    <Grid item xs={2} key={ind}>
                      <div className="cat-div pointer" onClick={()=>this.openReview(cat)}>
                        <img className="catgory-image" src={cat.icon} alt={cat.name}/>
                        <p>{cat.name}</p>
                      </div>

                      <Dialog
                           disableBackdropClick
                           disableEscapeKeyDown
                           open={this.state.openReview}
                           onClose={this.handleCloseReview}
                           maxWidth={'md'}
                           // TransitionComponent={'Fade'}
                           // transitionDuration={3000}
                           >
                        <DialogContent>
                           <Grid container spacing={3}>
                              <img src={this.state.catDetails.image} alt={this.state.catDetails.name} className="category-details-pop-img" />
                              <h3 className="text-center">{this.state.catDetails.name}</h3>
                           </Grid>
                           <Grid container spacing={3}>
                           <div className="category-details">
                                 <p><b>Price</b> :{this.state.catDetails.price}</p>
                                 <p>{this.state.catDetails.description}</p>
                           </div>
                           </Grid>
                        </DialogContent>
                        <DialogActions>
                           <Button onClick={this.handleCloseReview} className="footer-btn"> Cancel</Button>
                        </DialogActions>
                     </Dialog>
                    </Grid>

                )
            })}
            
        </Grid>      
        <div className="operator text-center">Choose Your Seats</div>
        <div className="seat-section">
          <div className="available-icon">
            <img src="/assets/images/choos_seat_Available.png" alt="img" />
            <h3>Available Seats</h3>
            <h3>{seatArray.available_seats_cabins}</h3>
          </div>
          <div className="available-icon">
            <img src="/assets/images/choos_seat_booked.png" alt="img" />
            <h3>Booked</h3>
            <h3>{seatArray.booked_seats_cabins}</h3>
          </div>
          <div className="available-icon">
            <img src="/assets/images/choos_seat_held.png" alt="img" />
            <h3>Held By Operator</h3>
            <h3>{seatArray.held_by_operator_seats_cabins}</h3>
          </div>
          <div className="available-icon">
            <img src="/assets/images/choos_seat_selected.png" alt="img" />
            <h3>Selected Seats</h3>
            <h3>{this.state.seat_nameArray.length}</h3>
          </div>
        </div>
        <div className="seat-text launch-text">
              Hold the cursor on seats numbers, click to select or deselect .{" "}
            </div>
        <Grid container spacing={3} className="pad-imp-20 mob-bus-seat-layout">
        <TextField
                id="standard-select-currency-native"
                select
                // label="Choose Boarding Point"
                value={this.state.floor}
                onChange={this.floorChange}
                name="floor"
                SelectProps={{
                  native: true,
                }}
                helperText="Please select your floor"
              >
                <option value="1">First Floor</option>
                <option value="2">Second Floor</option>
                
              </TextField>
          <Grid item xs={12} className="text-center">
            <h1  className={f_floor}>First Floor</h1>
            <h1  className={s_floor}>Second Floor</h1>
          </Grid>
          <Grid item xs={7}   className={f_floor}>
            {/* <h1 className="text-center">First Floor</h1> */}
            {cabin_b1 ? <div className="seat-layout">{cabin}</div> : ''}
             
            { launch_seatArray === null ||  launch_seatArray.length === 0 ? ' ' : <Grid item xs={12}  className={f_floor+' '+'seat-border'}>{seat}</Grid> }
            
         </Grid>
         {/* second floor */}
         <Grid item xs={7} className={s_floor}>
          {/* <h1 className="text-center">Second Floor</h1> */}
            {cabin_b2 ? <div className="seat-layout">{cabin_2}</div> : ''}
            { launch_seatArray_2.length === 0 || launch_seatArray_2 === null  ? '' :  <Grid item xs={12}  className={s_floor+' '+'seat-border'}>{seat_2}</Grid> }
            
          </Grid>

         <Grid item xs={5} >
            <div className="selected-seat">
              <table className="seat-table min-width-252">
                <thead className="seat-head">
                  <tr>
                    <td style={{Width:'33.33%'}}>Seat/Cabin</td>
                    <td style={{Width:'33.33%'}}>Seat/Cabin Type</td>
                    <td style={{Width:'33.33%'}}>Fare</td>
                    {/* <td>Class</td> */}
                  </tr>
                </thead>
                <tbody>
                  {selectedSeatRow}

                  <tr className="seat-footer">
                    <td colSpan="3">Total {this.state.seat_nameArray.length} Seats/Cabin, {this.state.price} Tk.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div className="done"> 
             {reBtn}
               <br/>
               <a className="note launch-note padp">Note : Due to traffic condition the trip may get conncted</a>
            </div>
            {/* <button onClick={this.handleClickOpenTracking} className="btn-view-seats  tracking">
              Tracking Launch <i className="fa fa-angle-right"></i>
            </button> */}
            <button onClick={this.handleClickOpen} className="food-btn">
              Food Menu <i className="fa fa-angle-right"></i>
            </button>
          </Grid>
          
          <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.openFoodModal}
          onClose={this.handleClose}
          maxWidth={100}
        >
           <DialogActions>
                  <Button onClick={this.handleClose} >
                    <i className="fa fa-close"></i>
                  </Button>
                </DialogActions>
          {this.state.openFoodModal ? <Fragment>
            <DialogContent>
            <div className="food-border">
            <table className="min-width-252">
                <thead className="food">
                   <tr>
                   <th className="text-center">Item</th>
                   <th className="text-center">Price</th>
                   </tr>
                </thead>
                <tbody className="food-body">
                   {this.props.food_menu.map((val,index)=>{
                      return(
                         <Fragment>
                         <tr>
                           <td className="text-center" style={{display:'inline-flex',padding:'2px'}}>
                              <img src={val.image} alt={val.name} style={{width:'25px',paddingRight:'12px'}} />
                              {val.name}
                              </td>
                           <td className="text-center">{val.price}</td>
                         </tr>
                         </Fragment>
                      )
                   })
                   }
                   
                </tbody>
             </table>
            </div> 
          </DialogContent>
          
          </Fragment>:''}
          {/* <DialogActions>
            <Button onClick={this.handleClose} className="footer-btn">
              Close
            </Button>
          </DialogActions> */}
        </Dialog>

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.openTrackingModal}
          onClose={this.handleCloseTracking}
          maxWidth={100}
        >
          <DialogContent>
          <div>
            <iframe width="100%" height="300" 
             src={`https://www.google.com/maps/embed/v1/place?q=${this.props.locationData.latitude},${ this.props.locationData.longitude}&key=AIzaSyDZeKmppB1BbqEao2horwtegrIJN8CoRVw`}> </iframe>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseTracking} className="footer-btn">
              Close
            </Button>
          </DialogActions>
        </Dialog>
          
         
      
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
   locationData: state.SearchReducer.location,
    food_menu: state.SearchReducer.food_menu,
    busSeatLayout: state.SearchReducer.busSeatLayout,
    returnBusBookingList: state.BusBookingReducer.launchbookingList,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
   getLocation: (journey_id,type) => dispatch(getLocation(journey_id,type)),
    searcForSeatLayoutForLaunch: (journey_id) => dispatch(searcForSeatLayoutForLaunch(journey_id)),
    getFoodMenu: (journey_id) => dispatch(getFoodMenu(journey_id)),
    LaunchBooking: (creds,history) => dispatch(LaunchBooking(creds,history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LaunchSeat));
