import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { searcForSeatLayout,getLocation } from "../Store/Actions/SearchAction";
import Loader from "./Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Link,Redirect } from "react-router-dom";
import { BusBooking } from "../Store/Actions/BusBookingAction";
// import history from '../Store/Actions/history';
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import "./Dialog.css";
import Button from "@material-ui/core/Button";


class BusSeat extends Component {
  constructor(props) {
    super(props);
    var ran = Math.floor(Math.random() * 1000000000) + 1;
    this.state = {
      setBoardingPoint: "",
      bus_id:'',
      seat_nameArray:[],
      price:0,
      Singleprice:'',
      seat_idArray:[],
      seatDataArray:[],
      boarding_point_id:'',
      referance_no:ran,
      user_id:localStorage.getItem('user_id'),
      search_parameter:this.props.searchPara,
      bus_journey_id:this.props.journey_id,
      place_of_boarding_point:'',
      isOpenTrackingModal: false,
      setOpenTrackingModal: false,
      openTrackingModal: false,
      location:'',
      
    };
    // this.handleChange = this.handleChange.bind(this);
    this.selectedSeat = this.selectedSeat.bind(this);
    this.returnSave = this.returnSave.bind(this);
  }

  handleClickOpenTracking = (data) => {
    this.setState({ setOpenTrackingModal: true, openTrackingModal: true});
    this.getLocationData();
  };

  getLocationData= ()=>{
    this.props.getLocation(this.props.journey_id,'bus');
  }
  
  handleCloseTracking = () => {
    this.setState({ setOpenTrackingModal: false, openTrackingModal: false});
  };

  returnSave =(e)=>{
    e.preventDefault();
    this.props.returnDataShow(e)
    if(localStorage.getItem('user_id')){
      var total_cost = this.state.Singleprice*this.state.seat_idArray.length;
      

      if(localStorage.getItem('ref_no')){
        var ref = localStorage.getItem('ref_no');
      }else{
        localStorage.setItem('ref_no',this.state.referance_no+'web')
        var ref = localStorage.getItem('ref_no');
      }
      
      var postArray = {
        referance_no:ref,
        user_id:localStorage.getItem('user_id'),
        bus_seat_id:this.state.seat_idArray,
        boarding_point_id:this.state.boarding_point_id,
        bus_journey_id:this.state.bus_journey_id,
        seat_nameArray:this.state.seat_nameArray,
        booking_cost:total_cost,
        place_of_boarding_point: this.state.place_of_boarding_point,
        request_from:'ReactApp',
      }
      this.props.BusBooking(postArray,this.props.history);
      var jo = JSON.parse(localStorage.getItem('journey'));
      var rjo = JSON.parse(localStorage.getItem('returnJourney'));
      if(jo){
        localStorage.setItem('returnJourney',JSON.stringify(postArray));
      }else{
        localStorage.setItem('journey',JSON.stringify(postArray));
      }
      var orderCheck = JSON.parse(localStorage.getItem("bookingData"));
      var retData = orderCheck?orderCheck.search_parameter:this.props.searchPara;

      localStorage.setItem('journeyDetails',JSON.stringify(retData));

      var ret = orderCheck?orderCheck.search_parameter.return_journey_date:this.props.searchPara.return_journey_date;
      if(ret && jo && rjo){
        this.props.history.push("/order");
      }else if(jo){
        this.props.history.push("/order");
      }



      if(this.props.returnBusBookingList === 'Please proceed'){
        this.props.returnDataShow()
      }
      // if(busBookingList === 'Please proceed'){
      //   this.setState({returnStatus:true,})
      // }else{
      //   this.setState({ open: true, openJourney: localSData.bus_journey_id });
      // }
    }
    
  }

  selectedSeat = (e, seat_name,bus_id,seat_id,dataArray) => {
    e.preventDefault();
    // console.log(bus_id);
    // console.log(this.state.seat_nameArray.length);

    if(this.state.seat_nameArray.length >= 4){
      var selectArray = this.state.seat_nameArray;
      var seat_idArray =this.state.seat_idArray;
      // console.log(seat_idArray,seat_idArray.indexOf(seat_id));
      if(seat_idArray.indexOf(seat_id) !== -1){
          
        seat_idArray.splice(seat_idArray.indexOf(seat_id), 1);
        selectArray.splice(selectArray.indexOf(seat_name), 1);

        this.setState({ seat_nameArray: selectArray, seat_idArray: seat_idArray });
        this.setState({seatDataArray:dataArray});

      }else{
        toast.warning(<i className="fa fa-exclamation-triangle"> You can buy maximum 4 ticket's</i>);
      }
      
    }else{
    if(this.state.seat_nameArray.length === 0){
      var selectArray = [];
      var seat_idArray = [];
    }else{
      var selectArray = this.state.seat_nameArray;
      var seat_idArray =this.state.seat_idArray;
    }
    
    if (selectArray.indexOf(seat_name) !== -1) {
      selectArray.splice(selectArray.indexOf(seat_name), 1);
    } else {
      selectArray.push(seat_name);
    }

    if (seat_idArray.indexOf(seat_id) !== -1) {
      seat_idArray.splice(seat_idArray.indexOf(seat_id), 1);
    } else {
      seat_idArray.push(seat_id);
    }
    // console.log(seat_name,selectArray,seat_idArray);
    this.setState({ seat_nameArray: selectArray, seat_idArray: seat_idArray });
    this.setState({seatDataArray:dataArray});
    var price = this.props.busSeatLayout.seat_price;
    // var total =parseInt(this.state.dataArray.length)*parseInt(price);
    this.setState({Singleprice:price});
    }
    
    // console.log(this.state);

  }



  handleChange = (event) => {
    event.preventDefault();
    // console.log(event.target);
    // console.log( event.target.selectedOptions[0].getAttribute('place') );
    this.setState({
      boarding_point_id:event.target.value,
      place_of_boarding_point: event.target.selectedOptions[0].getAttribute('place'),
    });
  };

  checkValidation =()=>{
    if(this.state.seat_idArray.length < 1){
      toast.warning(<i className="fa fa-exclamation-triangle"> Please Choose Seat First</i>);
    }else if(this.state.boarding_point_id ===''){
      toast.warning(<i className="fa fa-exclamation-triangle"> Please Select Boarding Point </i>);
    }
  }



  componentDidMount(props) {
    this.props.searcForSeatLayout(this.props.journey_id);
  }


   render() {
    var seatArray = this.props.busSeatLayout === undefined ? '' : this.props.busSeatLayout ;
    var items = seatArray.seat_layout === undefined ? 0 : seatArray.seat_layout.length ;
    const n = seatArray.seat_columns ;
    var result = items === 0 ?[] : new Array(Math.ceil( items / n))
      .fill()
      .map(_ => seatArray.seat_layout.splice(0, n));

    result = this.state.seatDataArray.length > 0 ? this.state.seatDataArray : result;
    result = result !== undefined ? result : [];
    // console.log('jsjd',result,n);

    if(n===3 || n=== "3"){
      var response = result.map((val,index)=>{
        var image_icon1 ='';
        var image_icon2 ='';
        var image_icon3 ='';
        var clName = 'ok';
        var clName2 = 'ok';
        var clName3 = 'ok';
        if(val[0].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(val[0].name) !== -1){
            image_icon1= '/assets/images/choos_seat_selected.png';
          }else{
            image_icon1= '/assets/images/choos_seat_Available.png';
          }
        }else if(val[0].status ==='Held by operator'){
          image_icon1 = '/assets/images/choos_seat_held.png';
          clName = 'not-ok';
        }else if(val[0].status === 'Booked'){
          image_icon1 = '/assets/images/choos_seat_booked.png';
          clName = 'not-ok';
        }

        if(val[1].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(val[1].name) !== -1){
            image_icon2= '/assets/images/choos_seat_selected.png';
          }else{
            image_icon2= '/assets/images/choos_seat_Available.png';
          }
        }else if(val[1].status ==='Held by operator'){
          image_icon2 = '/assets/images/choos_seat_held.png';
          clName2 = 'not-ok';
        }else if(val[1].status === 'Booked'){
          image_icon2 = '/assets/images/choos_seat_booked.png';
          clName2 = 'not-ok';
        }


        if(val[2].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(val[2].name) !== -1){
            image_icon3= '/assets/images/choos_seat_selected.png';
          }else{
            image_icon3= '/assets/images/choos_seat_Available.png';
          }
        }else if(val[2].status ==='Held by operator'){
          image_icon3 = '/assets/images/choos_seat_held.png';
          clName3 = 'not-ok';
        }else if(val[2].status === 'Booked'){
          image_icon3 = '/assets/images/choos_seat_booked.png';
          clName3 = 'not-ok';
        }


        return(
          <div className="seat-row" key={index}>
        <div className="row-one seat"> <img className={clName}  onClick={(e) => this.selectedSeat(e, val[0].name,val[0].bus_id,val[0].id,result)} src={image_icon1} title={val[0].name} alt={val[0].name} /></div>
        <div className="row-two seat"> </div>
        <div className="row-three seat"></div>
        <div className="row-four seat"><img className={clName2} onClick={(e) => this.selectedSeat(e, val[1].name,val[1].bus_id,val[1].id,result)} src={image_icon2} title={val[1].name} alt="img" /></div>
        <div className="row-five seat"><img className={clName3} onClick={(e) => this.selectedSeat(e, val[2].name,val[2].bus_id,val[2].id,result)}  src={image_icon3} title={val[2].name} alt="img" /> </div>
        </div>
        )
      })
    }else{
      var response = result.map((val,index)=>{
        var image_icon1 ='';
        var image_icon2 ='';
        var image_icon3 ='';
        var image_icon4 ='';
        var clName = 'ok';
        var clName2 = 'ok';
        var clName3 = 'ok';
        var clName4 = 'ok';
        if(val[0].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(val[0].name) !== -1){
            image_icon1= '/assets/images/choos_seat_selected.png';
          }else{
            image_icon1= '/assets/images/choos_seat_Available.png';
          }
        }else if(val[0].status ==='Held by operator'){
          image_icon1 = '/assets/images/choos_seat_held.png';
          clName = 'not-ok';
        }else if(val[0].status === 'Booked'){
          image_icon1 = '/assets/images/choos_seat_booked.png';
          clName = 'not-ok';
        }

        if(val[1].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(val[1].name) !== -1){
            image_icon2= '/assets/images/choos_seat_selected.png';
          }else{
            image_icon2= '/assets/images/choos_seat_Available.png';
          }
        }else if(val[1].status ==='Held by operator'){
          image_icon2 = '/assets/images/choos_seat_held.png';
          clName2 = 'not-ok';
        }else if(val[1].status === 'Booked'){
          image_icon2 = '/assets/images/choos_seat_booked.png';
          clName2 = 'not-ok';
        }



        if(val[2].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(val[2].name) !== -1){
            image_icon3= '/assets/images/choos_seat_selected.png';
          }else{
            image_icon3= '/assets/images/choos_seat_Available.png';
          }
        }else if(val[2].status ==='Held by operator'){
          image_icon3 = '/assets/images/choos_seat_held.png';
          clName3 = 'not-ok';
        }else if(val[2].status === 'Booked'){
          image_icon3 = '/assets/images/choos_seat_booked.png';
          clName3 = 'not-ok';
        }
       
        if(val[3].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(val[3].name) !== -1){
            image_icon4= '/assets/images/choos_seat_selected.png';
          }else{
            image_icon4= '/assets/images/choos_seat_Available.png';
          }
        }else if(val[3].status ==='Held by operator'){
          image_icon4 = '/assets/images/choos_seat_selected.png';
          clName4 = 'not-ok';
        }else if(val[3].status === 'Booked'){
          image_icon4 = '/assets/images/choos_seat_booked.png';
          clName4 = 'not-ok';
        }


        return(
          <div className="seat-row" key={index}>
        <div className="row-one seat"> <img className={clName} onClick={(e) => this.selectedSeat(e, val[0].name,val[0].bus_id,val[0].id,result)} src={image_icon1} title={val[0].name} alt={val[0].name} /></div>

        <div className="row-three seat"><img className={clName2} onClick={(e) => this.selectedSeat(e, val[1].name,val[1].bus_id,val[1].id,result)} src={image_icon2} title={val[1].name} alt="img" /></div>
        <div className="row-two seat"> </div>
        <div className="row-four seat"><img className={clName3}  onClick={(e) => this.selectedSeat(e, val[2].name,val[2].bus_id,val[2].id,result)} src={image_icon3} title={val[2].name} alt="img" /></div>
        <div className="row-five seat"><img className={clName4}  onClick={(e) => this.selectedSeat(e, val[3].name,val[3].bus_id,val[3].id,result)} src={image_icon4} title={val[3].name} alt="img" /> </div>
        </div>
        )
      })

    }



    var last_row = seatArray.seat_layout_last_row ===undefined ? [] : seatArray.seat_layout_last_row;
    // console.log(last_row);
    if(last_row.length > 0){
      if(last_row.length === 4){
        var last_image_icon1 ='';
        var last_image_icon2 ='';
        var last_image_icon3 ='';
        var last_image_icon4 ='';
        var clName = 'ok';
        var clName2 = 'ok';
        var clName3 = 'ok';
        var clName4 = 'ok';
        if(last_row[0].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(last_row[0].name) !== -1){
            last_image_icon1= '/assets/images/choos_seat_selected.png';
          }else{
            last_image_icon1= '/assets/images/choos_seat_Available.png';
          }
        }else if(last_row[0].status ==='Held by operator'){
          last_image_icon1 = '/assets/images/choos_seat_held.png';
          clName = 'not-ok';
        }else if(last_row[0].status === 'Booked'){
          last_image_icon1 = '/assets/images/choos_seat_booked.png';
          clName = 'not-ok';
        }

        if(last_row[1].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(last_row[1].name) !== -1){
            last_image_icon2= '/assets/images/choos_seat_selected.png';
          }else{
            last_image_icon2= '/assets/images/choos_seat_Available.png';
          }
        }else if(last_row[1].status ==='Held by operator'){
          last_image_icon2 = '/assets/images/choos_seat_held.png';
          clName2 = 'not-ok';
        }else if(last_row[1].status === 'Booked'){
          last_image_icon2 = '/assets/images/choos_seat_booked.png';
          clName2 = 'not-ok';
        }


        if(last_row[2].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(last_row[2].name) !== -1){
            last_image_icon3= '/assets/images/choos_seat_selected.png';
          }else{
            last_image_icon3= '/assets/images/choos_seat_Available.png';
          }
        }else if(last_row[2].status ==='Held by operator'){
          last_image_icon3 = '/assets/images/choos_seat_held.png';
          clName3 = 'not-ok';
        }else if(last_row[2].status === 'Booked'){
          last_image_icon3 = '/assets/images/choos_seat_booked.png';
          clName3 = 'not-ok';
        }


  
        if(last_row[3].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(last_row[3].name) !== -1){
            last_image_icon4= '/assets/images/choos_seat_selected.png';
          }else{
            last_image_icon4= '/assets/images/choos_seat_Available.png';
          }
        }else if(last_row[3].status ==='Held by operator'){
          last_image_icon4 = '/assets/images/choos_seat_held.png';
          clName4 = 'not-ok';
        }else if(last_row[3].status === 'Booked'){
          last_image_icon4 = '/assets/images/choos_seat_booked.png';
          clName4 = 'not-ok';
        }
  
  
        var lastRow = (<div className="seat-row ">
        <div className="row-one seat">
        <img className={clName}  onClick={(e) => this.selectedSeat(e, last_row[0].name,last_row[0].bus_id,last_row[0].id,result)} src={last_image_icon1} title={last_row[0].name} alt={last_row[0].name} />
        </div>
        
        <div className="row-three seat "> 
           <img className={clName2}  onClick={(e) => this.selectedSeat(e, last_row[1].name,last_row[1].bus_id,last_row[1].id,result)} src={last_image_icon2} title={last_row[1].name} alt={last_row[1].name} />
        </div>
        <div className="row-two seat"></div>
        <div className="row-four seat" style={{ marginLeft: '-30px'}}>
        <img className={clName3}  onClick={(e) => this.selectedSeat(e, last_row[2].name,last_row[2].bus_id,last_row[2].id,result)} src={last_image_icon3} title={last_row[2].name} alt={last_row[2].name} />
        </div>
        <div className="row-five seat">
        <img className={clName4}  onClick={(e) => this.selectedSeat(e, last_row[3].name,last_row[3].bus_id,last_row[3].id,result)} src={last_image_icon4} title={last_row[3].name} alt={last_row[3].name} />
        </div>
      </div>
    );
      }else if(last_row.length === 3){
        var last_image_icon1 ='';
        var last_image_icon2 ='';
        var last_image_icon3 ='';
        var clName = 'ok';
        var clName2 = 'ok';
        var clName3 = 'ok';
        if(last_row[0].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(last_row[0].name) !== -1){
            last_image_icon1= '/assets/images/choos_seat_selected.png';
          }else{
            last_image_icon1= '/assets/images/choos_seat_Available.png';
          }
        }else if(last_row[0].status ==='Held by operator'){
          last_image_icon1 = '/assets/images/choos_seat_held.png';
          clName = 'not-ok';
        }else if(last_row[0].status === 'Booked'){
          last_image_icon1 = '/assets/images/choos_seat_booked.png';
          clName = 'not-ok';
        }

        if(last_row[1].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(last_row[1].name) !== -1){
            last_image_icon2= '/assets/images/choos_seat_selected.png';
          }else{
            last_image_icon2= '/assets/images/choos_seat_Available.png';
          }
        }else if(last_row[1].status ==='Held by operator'){
          last_image_icon2 = '/assets/images/choos_seat_held.png';
          clName2 = 'not-ok';
        }else if(last_row[1].status === 'Booked'){
          last_image_icon2 = '/assets/images/choos_seat_booked.png';
          clName2 = 'not-ok';
        }


        if(last_row[2].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(last_row[2].name) !== -1){
            last_image_icon3= '/assets/images/choos_seat_selected.png';
          }else{
            last_image_icon3= '/assets/images/choos_seat_Available.png';
          }
        }else if(last_row[2].status ==='Held by operator'){
          last_image_icon3 = '/assets/images/choos_seat_held.png';
          clName3 = 'not-ok';
        }else if(last_row[2].status === 'Booked'){
          last_image_icon3 = '/assets/images/choos_seat_booked.png';
          clName3 = 'not-ok';
        }
  
        var lastRow = (<div className="seat-row">
          
        <div className="row-one seat">
        <img className={clName}  onClick={(e) => this.selectedSeat(e, last_row[0].name,last_row[0].bus_id,last_row[0].id,result)} src={last_image_icon1} title={last_row[0].name} alt={last_row[0].name} />
        </div>

        <div className="row-two seat"></div>

        <div className="row-three seat pad-imp"> 
           <img className={clName2}  onClick={(e) => this.selectedSeat(e, last_row[1].name,last_row[1].bus_id,last_row[1].id,result)} src={last_image_icon2} title={last_row[1].name} alt={last_row[1].name} />
        </div>
       
        <div className="row-four seat">
        <img className={clName3}  onClick={(e) => this.selectedSeat(e, last_row[2].name,last_row[2].bus_id,last_row[2].id,result)} src={last_image_icon3} title={last_row[2].name} alt={last_row[2].name} />
        </div>
      </div>
    );
      }
      else{
        var last_image_icon1 ='';
        var last_image_icon2 ='';
        var last_image_icon3 ='';
        var last_image_icon4 ='';
        var last_image_icon5 ='';
        var clName = 'ok';
        var clName2 = 'ok';
        var clName3 = 'ok';
        var clName4 = 'ok';
        var clName5 = 'ok';

        if(last_row[0].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(last_row[0].name) !== -1){
            last_image_icon1= '/assets/images/choos_seat_selected.png';
          }else{
            last_image_icon1= '/assets/images/choos_seat_Available.png';
          }
        }else if(last_row[0].status ==='Held by operator'){
          last_image_icon1 = '/assets/images/choos_seat_held.png';
          clName = 'not-ok';
        }else if(last_row[0].status === 'Booked'){
          last_image_icon1 = '/assets/images/choos_seat_booked.png';
          clName = 'not-ok';
        }


        if(last_row[1].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(last_row[1].name) !== -1){
            last_image_icon2= '/assets/images/choos_seat_selected.png';
          }else{
            last_image_icon2= '/assets/images/choos_seat_Available.png';
          }
        }else if(last_row[1].status ==='Held by operator'){
          last_image_icon2 = '/assets/images/choos_seat_held.png';
          clName2 = 'not-ok';
        }else if(last_row[1].status === 'Booked' ){
          last_image_icon2 = '/assets/images/choos_seat_booked.png';
          clName2 = 'not-ok';
        }



        if(last_row[2].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(last_row[2].name) !== -1){
            last_image_icon3= '/assets/images/choos_seat_selected.png';
          }else{
            last_image_icon3= '/assets/images/choos_seat_Available.png';
          }
        }else if(last_row[2].status ==='Held by operator'){
          last_image_icon3 = '/assets/images/choos_seat_held.png';
          clName3 = 'not-ok';
        }else if(last_row[2].status === 'Booked'){
          last_image_icon3 = '/assets/images/choos_seat_booked.png';
          clName3 = 'not-ok';
        }


  
        if(last_row[3].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(last_row[3].name) !== -1){
            last_image_icon4= '/assets/images/choos_seat_selected.png';
          }else{
            last_image_icon4= '/assets/images/choos_seat_Available.png';
          }
        }else if(last_row[3].status ==='Held by operator'){
          last_image_icon4 = '/assets/images/choos_seat_held.png';
          clName4 = 'not-ok';
        }else if(last_row[3].status === 'Booked'){
          last_image_icon4 = '/assets/images/choos_seat_booked.png';
          clName4 = 'not-ok';
        }
  
  
        if(last_row[4].status === 'Available'){
          if(this.state.seat_nameArray.indexOf(last_row[4].name) !== -1){
            last_image_icon5= '/assets/images/choos_seat_selected.png';
          }else{
            last_image_icon5= '/assets/images/choos_seat_Available.png';
          }
        }else if(last_row[4].status ==='Held by operator'){
          last_image_icon5 = '/assets/images/choos_seat_held.png';
          clName5 = 'not-ok';
        }else if(last_row[4].status === 'Booked'){
          last_image_icon5 = '/assets/images/choos_seat_booked.png';
          clName5 = 'not-ok';
        }
  
  
  
        var lastRow = (<div className="seat-row">
        <div className="row-one seat">
        <img className={clName}  onClick={(e) => this.selectedSeat(e, last_row[0].name,last_row[0].bus_id,last_row[0].id,result)} src={last_image_icon1} title={last_row[0].name} alt={last_row[0].name} />
        </div>
        <div className="row-two seat">
        <img className={clName2}  onClick={(e) => this.selectedSeat(e, last_row[1].name,last_row[1].bus_id,last_row[1].id,result)} src={last_image_icon2} title={last_row[1].name} alt={last_row[2].name} />
        </div>
        <div className="row-three seat pad-imp"> 
        <img className={clName3} onClick={(e) => this.selectedSeat(e, last_row[2].name,last_row[2].bus_id,last_row[2].id,result)} src={last_image_icon3} title={last_row[2].name} alt={last_row[2].name} />
        </div>
        <div className="row-four seat" >
        <img  className={clName4} onClick={(e) => this.selectedSeat(e, last_row[3].name,last_row[3].bus_id,last_row[3].id,result)} src={last_image_icon4} title={last_row[3].name} alt={last_row[3].name} />
        </div>
        <div className="row-five seat">
        <img className={clName5} onClick={(e) => this.selectedSeat(e, last_row[4].name,last_row[4].bus_id,last_row[4].id,result)} src={last_image_icon5} title={last_row[4].name} alt={last_row[4].name} />
        </div>
      </div>
    );
  
      }
    }
    



    var selectedSeatRow = this.state.seat_nameArray.length > 0 ?this.state.seat_nameArray.map((val,index)=>{
      return(
        <tr key={index}>
          <td>{val}</td>
          <td>{this.state.Singleprice}</td>
        </tr>
      )
    }):<tr><td colSpan={2}>Please choose your seat</td></tr>;

    



    var total_price = Math.ceil(parseInt(this.state.Singleprice)*parseInt(this.state.seat_nameArray.length));
    var boarding  = this.props.busSeatLayout.boarding_points;
    var boarding_pointArray = boarding !== undefined  ? this.props.busSeatLayout.boarding_points : [];
    var returnPage = this.state.boarding_point_id  ?(<button onClick={(e) => this.props.returnDataShow(e),this.returnSave} >Confirm</button>):(<button onClick={this.checkValidation}>Confirm</button>) ;
    var loginPage  = this.state.boarding_point_id  ?(<Link to={{ pathname: '/sign-in', state: { bookJourney: this.state} }}>Confirm</Link>):(<button onClick={this.checkValidation}>Confirm</button>) ;
    var reBtn = localStorage.getItem('user')?returnPage:loginPage;
    console.log(this.state.location);
    var locationUrl = 'https://www.google.com/maps/embed/v1/place?q='+this.state.location.latitude+','+this.state.location.longitude+'&amp;key=AIzaSyDZeKmppB1BbqEao2horwtegrIJN8CoRVw';

    return (
      <Fragment>
        <div className="operator text-center">Choose Your Seats</div>
        <div className="seat-section">
          <div className="available-icon" >
            <img src="/assets/images/choos_seat_Available.png" alt="img" />
            <h3>Available Seats</h3>
            <h3>{seatArray.available_seats}</h3>
          </div>
          <div className="available-icon">
            <img src="/assets/images/choos_seat_booked.png" alt="img" />
            <h3>Booked Seat</h3>
            <h3>{seatArray.booked_seats}</h3>
          </div>
          <div className="available-icon">
            <img src="/assets/images/choos_seat_held.png" alt="img" />
            <h3>Held By Operator</h3>
            <h3>{seatArray.held_by_operator_seats}</h3>
          </div>
          <div className="available-icon">
            <img src="/assets/images/choos_seat_selected.png" alt="img" />
            <h3>Selected Seats</h3>
            <h3>{this.state.seat_nameArray.length}</h3>
          </div>
        </div>
        <Grid container spacing={3} className="mob-bus-seat-layout">
          <Grid item xs={6}>
            <div className="seat-text">
              Hold the cursor on seats numbers, click to select or deselect .{" "}
            </div>
            <div className="seat-layout">
            {/* <i className="fa fa-spinner fa-spin load-fa"></i> */}
            {/* {response.length > 0 ? response : (<i className="fa fa-spinner fa-spin load-fa"></i>)} */}

            {response}
            {lastRow}

            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="selected-seat">
              <table className="seat-table">
                <thead className="seat-head">
                  <tr>
                    <td style={{Width:'50%'}}>Seat</td>
                    <td style={{Width:'50%'}}>Fare</td>
                    {/* <td>Class</td> */}
                  </tr>
                </thead>
                <tbody>
                  {selectedSeatRow}

                  <tr className="seat-footer">
                    <td colSpan="2">Total {this.state.seat_nameArray.length} Seats, {total_price > 0 ? total_price : 0} Tk.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <form noValidate autoComplete="off" className="pad-top-25">
              {this.state.seat_idArray.length > 0 ?(<TextField
                id="standard-select-currency-native"
                select
                // label="Choose Boarding Point"
                value={this.state.boarding_point_id}
                onChange={this.handleChange}
                name="boarding_point_id"
                SelectProps={{
                  native: true,
                }}
                helperText="Please select your Boarding  Point"
              >
                <option value="">--select--</option>
                {boarding_pointArray.map((option,index) => (
                  <option key={index} value={option.id} place={option.name+'('+option.time+')'}>
                    {option.name} ({option.time})
                  </option>
                ))}
              </TextField>
           ):''}
               </form>
               <div className="done">
                {reBtn}
                <br/>
                <a className="note launch-note">Note : Due to traffic condition the trip may get conncted</a>
                </div>
                {/* <button onClick={this.handleClickOpenTracking} className="btn-view-seats tracking">
                  Tracking Bus <i className="fa fa-angle-right"></i>
                </button> */}       
            
          </Grid>
         
          <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.openTrackingModal}
          onClose={this.handleCloseTracking}
          maxWidth={100}
        >
          <DialogContent>
          <div>
            <iframe width="100%" height="300" 
             src={`https://www.google.com/maps/embed/v1/place?q=${this.props.locationData.latitude},${ this.props.locationData.longitude}&key=AIzaSyDZeKmppB1BbqEao2horwtegrIJN8CoRVw`}> </iframe>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseTracking} className="footer-btn">
              Close
            </Button>
          </DialogActions>
        </Dialog>

      
        </Grid>
        
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locationData: state.SearchReducer.location,
    busSeatLayout: state.SearchReducer.busSeatLayout,
    returnBusBookingList: state.BusBookingReducer.bookingList,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLocation: (journey_id,type) => dispatch(getLocation(journey_id,type)),
    searcForSeatLayout: (journey_id) => dispatch(searcForSeatLayout(journey_id)),
    BusBooking: (creds,history) => dispatch(BusBooking(creds,history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusSeat));
