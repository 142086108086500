import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { signUp } from "../Store/Actions/AuthAction";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      fname: "",
      lname: "",
      password: "",
      password_confirmation: "",
      phone: "",
      accept: false,
      loader:false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
  if(this.state.fname ===''){
      toast.warning(<i className="fa fa-exclamation-triangle"> The First Name field is required</i>);
  }else if(this.state.lname ===''){
    toast.warning(<i className="fa fa-exclamation-triangle"> The Last Name field is required</i>);
  }else if(this.state.phone ===''){
    toast.warning(<i className="fa fa-exclamation-triangle"> The Phone field is required</i>);
  }else if(this.state.email ===''){
    toast.warning(<i className="fa fa-exclamation-triangle"> The Email field is required</i>);
  }else if(this.state.password ===''){
    toast.warning(<i className="fa fa-exclamation-triangle"> The Password field is required</i>);
  }else if(this.state.password.length < 8 ){
    toast.warning(<i className="fa fa-exclamation-triangle"> The password must be at least 8 characters </i>);
  }else if(this.state.password_confirmation ===''){
    toast.warning(<i className="fa fa-exclamation-triangle"> The Re type password field is required</i>);
  }else if (this.state.password !== this.state.password_confirmation){
    toast.warning(<i className="fa fa-exclamation-triangle"> Those password are not matching</i>);
  }else if (!this.state.accept){
    toast.warning(<i className="fa fa-exclamation-triangle"> Terms and conditions & privacy policy is required</i>);
  }else{
    this.props.signUp(this.state,this.props.history);
    this.setState({
      loader:true,
    });
  }
    
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChange2 = (e) => {
      this.setState({
        accept: !this.state.accept,
      });
    
  };


  render() {
    const loading  = this.props.loadingSignUp;
    return (
      <div>
        <Container maxWidth="md">
          <Container maxWidth="md">
            <div className="powerbit-login">
              <h3>Welcome To Powerbit !</h3>
              <p>Please Sign Up with your email or phone .</p>
              <div className="form-controll">
                <div className="login-header">Sign Up</div>
                <form className="login-form" onSubmit={this.handleSubmit}>
                <TextField
                  id="standard-textarea"
                  label="First Name"
                  placeholder="First Name"
                  variant="outlined"
                  name="fname"
                  type="text"
                  onChange={this.handleChange}
                />
                <TextField
                  id="standard-textarea"
                  label="Last Name"
                  placeholder="Last Name"
                  variant="outlined"
                  name="lname"
                  type="text"
                  onChange={this.handleChange}
                />
                <TextField
                  id="standard-textarea"
                  label="Phone"
                  placeholder="Phone"
                  variant="outlined"
                  type="text"
                  name="phone"
                  onChange={this.handleChange}
                />
                <TextField
                  id="standard-textarea"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                />
                <TextField
                  id="standard-textarea"
                  label="Password"
                  placeholder="Password"
                  variant="outlined"
                  type="password"
                  name="password"
                  onChange={this.handleChange}
                />
                <TextField
                  id="standard-textarea"
                  label="Re Type Password"
                  placeholder="Re Type Password"
                  variant="outlined"
                  type="password"
                  name="password_confirmation"
                  onChange={this.handleChange}
                />
                <FormControlLabel
                control={<Checkbox   name="accept" onChange={this.handleChange2} />}
                label="I accept terms and conditions & privacy policy"
                />
                <button className="sign-up-btn">{loading && this.state.loader ? <i className="fa fa-spinner fa-spinn"></i>:''}Sign Up</button>
                </form>
                <p>Or sign up with  social login</p>
                <div className="social-login-btn">
                    <button className="sign-up-fb-btn"><i className="fa fa-facebook"></i> facebook</button>
                    <span> </span>
                    <button className="sign-up-google-btn"><i className="fa fa-google"></i> google</button>
                </div>
                <p>Click here to login <Link to="/sign-in"> Alreay have an account ?</Link></p>
              </div>
            </div>
          </Container>
        </Container>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  
  return {
    authResponse: state.AuthReducer.authResponse,
    // validation: state.AuthReducer.validation,
    loadingSignUp: state.AuthReducer.loadingSignUp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (creds,history) => dispatch(signUp(creds,history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
