const initState = {
  authResponse: [],
  loading: false,
  loadingLogin: false,
  loadingSignUp: false,
  validation: [],
  userData: [],
  mytripsLoading: false,
  mytrips: [],
  forget_pass: [],
};

const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case "RESTART_AUTH_RESPONSE":
      return {
        ...state,
        authResponse: null,
        loading: false,
        loadingSignUp: false,
        loadingLogin: false,
      };

    case "LOADING":
      return {
        ...state,
        loading: true,
        loadingSignUp: true,
        loadingLogin: true,
        authResponse: "loading...",
      };

    case "SHORT_PASSWORD":
      return {
        ...state,
        loading: false,
        loadingSignUp: false,
        loadingLogin: false,
        authResponse: "Password is too short",
      };

    case "FORGET":
      return {
        ...state,
        loading: false,
        forget_pass: action.res,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        loading: false,
        loadingLogin: false,
        loadingSignUp: false,
        authResponse: action.res,
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        loading: false,
        loadingSignUp: false,
        loadingLogin: false,
        authResponse: action.res,
      };

    case "SIGNUP_SUCCESS":
      return {
        ...state,
        loadingSignUp: false,
        loading: false,
        loadingLogin: false,
        authResponse: action.res,
      };

    case "TRIPS_LOADING":
      return {
        ...state,
        mytripsLoading: true,
      };
    case "MY_TRIPS":
      return {
        ...state,
        mytripsLoading: false,
        mytrips: action.res,
      };

    case "LOGOUT_SUCCESS":
      return {
        ...state,
        loading: false,
        loadingSignUp: false,
        loadingLogin: false,
        authResponse: action.res,
      };

    case "LOAD_USER_DATA":
      return {
        ...state,
        loading: false,
        loadingSignUp: false,
        loadingLogin: false,
        userData: action.response,
      };

    case "FETCH_DATA_ERROR":
      return {
        ...state,
        loading: false,
        loadingSignUp: false,
        loadingLogin: false,
        userData: action.res,
      };

    case "CODE_ERROR":
      return {
        ...state,
        loading: false,
        loadingSignUp: false,
        loadingLogin: false,
        authResponse: "There seems to be a problem please try again later",
      };

    default:
      return state;
  }
};

export default AuthReducer;
