import React, { Component, Fragment } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "date-fns";
import ModifySearch from "./ModifySearch";
import Collapsible from "react-collapsible";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BusSeat from "./BusSeat";
import { connect } from "react-redux";
import { searchRequest } from "../Store/Actions/SearchAction";
import { BusBooking } from "../Store/Actions/BusBookingAction";
import { Link } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import { UserInformation } from "../Store/Actions/AuthAction";
import Pop from './Pop';


class BusSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      open: false,
      openJourney:'',
      tIndex: "",
      filArray: [],
      operator: [],
      type: [],
      bus_boarding_point: [],
      bus_dropping_point: [],
      bus_facility: [],
      departure: [],
      arrival: [],
      operatorData: [],
      typeData: [],
      bus_boarding_pointData: [],
      bus_dropping_pointData: [],
      bus_facilityData: [],
      departureData: [],
      arrivalData: [],
      filterCheck: [],
      returnStatus: false,
      fireRedirect: false,
      setOpenDrawer:false,
      drawerOpen:false,
    };
    this.togglePanel = this.togglePanel.bind(this);
    this.returnDataShow = this.returnDataShow.bind(this);
    this.toggleCheck = this.toggleCheck.bind(this);
    this.Filter = this.Filter.bind();
  }

  handleDrawerOpen = () => {
    this.setState({
      setOpenDrawer:true,
      drawerOpen:true,
    })
  };
  handleDrawerClose  = () => {
    this.setState({
      setOpenDrawer:false,
      drawerOpen:false,
    })
  };


  togglePanel(e, index,operatorName,busName,departurTime) {
    if(this.state.returnStatus){
      localStorage.setItem('retuenOperatorName',operatorName);
      localStorage.setItem('retuenBusName',busName);
      localStorage.setItem('retuenDeparturTime',departurTime);
    }else{
      localStorage.setItem('operatorName',operatorName);
      localStorage.setItem('busName',busName);
      localStorage.setItem('departurTime',departurTime);
    }
     if(this.state.tIndex === index){
      this.setState({ open: !this.state.open, tIndex: index });
     }else{
      this.setState({ open: true, tIndex: index });
     }
    
  }

  toggleCheck =(e) =>{
    e.preventDefault();
    this.setState({ open: false});
  }

  returnDataShow = (e)=>{
    if((!this.props.busSearchList.return_journeys || this.props.busSearchList.return_journeys === null) && localStorage.getItem('journey')){
      this.props.history.push("/order");
      // alert('kjbk');
    }
    this.setState({ 
      filArray: [],
      operator: [],
      type: [],
      bus_boarding_point: [],
      bus_dropping_point: [],
      bus_facility: [],
      departure: [],
      arrival: [],
      operatorData: [],
      typeData: [],
      bus_boarding_pointData: [],
      bus_dropping_pointData: [],
      bus_facilityData: [],
      departureData: [],
      arrivalData: [],
      filterCheck: [],
      returnStatus: true,
      open:false,
    });
    this.state.returnStatus = true;
    this.state.open = false;
  };

  dateChange = (date) => {
    this.setState((state) => ({
      ...state,
      date: date,
    }));
  };

  Filter = () => {
    var data1 = this.props.busSearchList.journeys.result;

    var operatorData =
      this.state.operator.length > 0 ? this.state.operatorData : data1;
    var typeData = this.state.type.length > 0 ? this.state.typeData : data1;
    var bus_boarding_pointData =
      this.state.bus_boarding_point.length > 0
        ? this.state.bus_boarding_pointData
        : data1;
    var bus_dropping_pointData =
      this.state.bus_dropping_point.length > 0
        ? this.state.bus_dropping_pointData
        : data1;
    var bus_facilityData =
      this.state.bus_facility.length > 0 ? this.state.bus_facilityData : data1;
    var departureData =
      this.state.departure.length > 0 ? this.state.departureData : data1;
    var arrivalData =
      this.state.arrival.length > 0 ? this.state.arrivalData : data1;
    //  console.log(departureData);

    var arrays = [
      data1,
      operatorData,
      typeData,
      bus_boarding_pointData,
      bus_dropping_pointData,
      bus_facilityData,
      departureData,
      arrivalData,
    ];

    //  console.log(arrays);

    var global = [];
    for (var i = 0; i < data1.length; i++) {
      var presence = true;
      for (var j = 0; j < arrays.length; j++) {
        var temp = arrays[j].find(function (value) {
          return data1[i].journey_id === value.journey_id;
        });
        if (!temp) {
          presence = false;
          break;
        }
      }
      if (presence) {
        global.push(data1[i]);
      }
    }

    // if(global.length ===0){
    //   this.setState({ filArray: [] })
    // }
    this.state.filArray = global;
    this.setState({ filArray: global,open: false });
    // console.log(global,this.state.filArray);

  };

  // journey

  operatorCheck = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.operator.length === 0) {
      var operator = [];
      operator.push(val);
    } else {
      var operator = this.state.operator;
      if (operator.indexOf(val) !== -1) {
        operator.splice(operator.indexOf(val), 1);
      } else {
        operator.push(val);
      }
    }
    this.state.operator = operator;
    this.setState({ operator: operator });

    if (this.state.operator.length !== 0) {
      var operator = this.state.operator;
      var mapArray = this.props.busSearchList.journeys.result;
      mapArray.filter(function (e) {
        if (operator.indexOf(e.bus_operator_name) !== -1) {
          filterArray.push(e);
          return filterArray;
        }
      });
      this.state.operatorData = filterArray;
      this.setState({ operatorData: filterArray });
    }
    this.Filter();
  };

  busTypeFilter = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.type.length === 0) {
      var type = [];
      type.push(val);
    } else {
      // var type = new Array();
      type = this.state.type;
      if (type.indexOf(val) !== -1) {
        type.splice(type.indexOf(val), 1);
      } else {
        type.push(val);
      }
    }
    this.state.type = type;
    this.setState({ type: type });
    if (this.state.type.length !== 0) {
      var type = this.state.type;
      var mapArray = this.props.busSearchList.journeys.result;
      mapArray.filter(function (e) {
        if (type.indexOf(e.bus_type) !== -1) {
          filterArray.push(e);
          return filterArray;
        }
      });
      this.state.typeData = filterArray;
      this.setState({ typeData: filterArray });
    }

    this.Filter();
  };

  bus_boarding_point = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.bus_boarding_point.length === 0) {
      var bus_boarding_point = [];
      bus_boarding_point.push(val);
    } else {
      // var type = new Array();
      bus_boarding_point = this.state.bus_boarding_point;
      if (bus_boarding_point.indexOf(val) !== -1) {
        bus_boarding_point.splice(bus_boarding_point.indexOf(val), 1);
      } else {
        bus_boarding_point.push(val);
      }
    }
    this.state.bus_boarding_point = bus_boarding_point;
    this.setState({ bus_boarding_point: bus_boarding_point });

    if (this.state.bus_boarding_point.length !== 0) {
      var bus_boarding_point = this.state.bus_boarding_point;
      var mapArray = this.props.busSearchList.journeys.result;
      mapArray.filter(function (e) {
        e.boarding_points.map((bor) => {
          if (bus_boarding_point.indexOf(bor) !== -1) {
            filterArray.push(e);
            return filterArray;
          }
        });
      });
      this.state.bus_boarding_pointData = filterArray;
      this.setState({ bus_boarding_pointData: filterArray });
    }

    this.Filter();
  };

  bus_dropping_point = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.bus_dropping_point.length === 0) {
      var bus_dropping_point = [];
      bus_dropping_point.push(val);
    } else {
      // var type = new Array();
      bus_dropping_point = this.state.bus_dropping_point;
      if (bus_dropping_point.indexOf(val) !== -1) {
        bus_dropping_point.splice(bus_dropping_point.indexOf(val), 1);
      } else {
        bus_dropping_point.push(val);
      }
    }
    this.state.bus_dropping_point = bus_dropping_point;
    this.setState({ bus_dropping_point: bus_dropping_point });

    if (this.state.bus_dropping_point.length !== 0) {
      var bus_dropping_point = this.state.bus_dropping_point;
      var mapArray = this.props.busSearchList.journeys.result;
      mapArray.filter(function (e) {
        e.droping_points.map((bor) => {
          if (bus_dropping_point.indexOf(bor) !== -1) {
            filterArray.push(e);
            return filterArray;
          }
        });
      });
      this.state.bus_dropping_pointData = filterArray;
      this.setState({ bus_dropping_pointData: filterArray });
    }

    this.Filter();
  };

  bus_facility = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.bus_facility.length === 0) {
      var bus_facility = [];
      bus_facility.push(val);
    } else {
      // var type = new Array();
      bus_facility = this.state.bus_facility;
      if (bus_facility.indexOf(val) !== -1) {
        bus_facility.splice(bus_facility.indexOf(val), 1);
      } else {
        bus_facility.push(val);
      }
    }
    this.state.bus_facility = bus_facility;
    this.setState({ bus_facility: bus_facility });
    if (this.state.bus_facility.length !== 0) {
      var bus_facility = this.state.bus_facility;
      var mapArray = this.props.busSearchList.journeys.result;
      mapArray.filter(function (e) {
        e.bus_facilities.map((bor) => {
          if (bus_facility.indexOf(bor) !== -1) {
            filterArray.push(e);
            return filterArray;
          }
        });
      });
      this.state.bus_facilityData = filterArray;
      this.setState({ bus_facilityData: filterArray });
    }
    this.Filter();
  };

  // return journey

  operatorCheckReturn = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.operator.length === 0) {
      var operator = [];
      operator.push(val);
    } else {
      var operator = this.state.operator;
      if (operator.indexOf(val) !== -1) {
        operator.splice(operator.indexOf(val), 1);
      } else {
        operator.push(val);
      }
    }
    this.state.operator = operator;
    this.setState({ operator: operator });

    if (this.state.operator.length !== 0) {
      var operator = this.state.operator;
      var mapArray = this.props.busSearchList.return_journeys.result;
      mapArray.filter(function (e) {
        if (operator.indexOf(e.bus_operator_name) !== -1) {
          filterArray.push(e);
          return filterArray;
        }
      });
      this.state.operatorData = filterArray;
      this.setState({ operatorData: filterArray });
    }
    this.Filter();
  };

  busTypeFilterReturn = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.type.length === 0) {
      var type = [];
      type.push(val);
    } else {
      // var type = new Array();
      type = this.state.type;
      if (type.indexOf(val) !== -1) {
        type.splice(type.indexOf(val), 1);
      } else {
        type.push(val);
      }
    }
    this.state.type = type;
    this.setState({ type: type });
    if (this.state.type.length !== 0) {
      var type = this.state.type;
      var mapArray = this.props.busSearchList.return_journeys.result;
      mapArray.filter(function (e) {
        if (type.indexOf(e.bus_type) !== -1) {
          filterArray.push(e);
          return filterArray;
        }
      });
      this.state.typeData = filterArray;
      this.setState({ typeData: filterArray });
    }

    this.Filter();
  };

  bus_boarding_pointReturn = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.bus_boarding_point.length === 0) {
      var bus_boarding_point = [];
      bus_boarding_point.push(val);
    } else {
      // var type = new Array();
      bus_boarding_point = this.state.bus_boarding_point;
      if (bus_boarding_point.indexOf(val) !== -1) {
        bus_boarding_point.splice(bus_boarding_point.indexOf(val), 1);
      } else {
        bus_boarding_point.push(val);
      }
    }
    this.state.bus_boarding_point = bus_boarding_point;
    this.setState({ bus_boarding_point: bus_boarding_point });

    if (this.state.bus_boarding_point.length !== 0) {
      var bus_boarding_point = this.state.bus_boarding_point;
      var mapArray = this.props.busSearchList.return_journeys.result;
      mapArray.filter(function (e) {
        e.boarding_points.map((bor) => {
          if (bus_boarding_point.indexOf(bor) !== -1) {
            filterArray.push(e);
            return filterArray;
          }
        });
      });
      this.state.bus_boarding_pointData = filterArray;
      this.setState({ bus_boarding_pointData: filterArray });
    }

    this.Filter();
  };

  bus_dropping_pointReturn = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.bus_dropping_point.length === 0) {
      var bus_dropping_point = [];
      bus_dropping_point.push(val);
    } else {
      // var type = new Array();
      bus_dropping_point = this.state.bus_dropping_point;
      if (bus_dropping_point.indexOf(val) !== -1) {
        bus_dropping_point.splice(bus_dropping_point.indexOf(val), 1);
      } else {
        bus_dropping_point.push(val);
      }
    }
    this.state.bus_dropping_point = bus_dropping_point;
    this.setState({ bus_dropping_point: bus_dropping_point });

    if (this.state.bus_dropping_point.length !== 0) {
      var bus_dropping_point = this.state.bus_dropping_point;
      var mapArray = this.props.busSearchList.return_journeys.result;
      mapArray.filter(function (e) {
        e.droping_points.map((bor) => {
          if (bus_dropping_point.indexOf(bor) !== -1) {
            filterArray.push(e);
            return filterArray;
          }
        });
      });
      this.state.bus_dropping_pointData = filterArray;
      this.setState({ bus_dropping_pointData: filterArray });
    }

    this.Filter();
  };

  bus_facilityReturn = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.bus_facility.length === 0) {
      var bus_facility = [];
      bus_facility.push(val);
    } else {
      // var type = new Array();
      bus_facility = this.state.bus_facility;
      if (bus_facility.indexOf(val) !== -1) {
        bus_facility.splice(bus_facility.indexOf(val), 1);
      } else {
        bus_facility.push(val);
      }
    }
    this.state.bus_facility = bus_facility;
    this.setState({ bus_facility: bus_facility });
    if (this.state.bus_facility.length !== 0) {
      var bus_facility = this.state.bus_facility;
      var mapArray = this.props.busSearchList.return_journeys.result;
      mapArray.filter(function (e) {
        e.bus_facilities.map((bor) => {
          if (bus_facility.indexOf(bor) !== -1) {
            filterArray.push(e);
            return filterArray;
          }
        });
      });
      this.state.bus_facilityData = filterArray;
      this.setState({ bus_facilityData: filterArray });
    }
    this.Filter();
  };

  departure = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.departure.length === 0) {
      var departure = [];
      departure.push(val);
    } else {
      // var type = new Array();
      departure = this.state.departure;
      if (departure.indexOf(val) !== -1) {
        departure.splice(departure.indexOf(val), 1);
      } else {
        departure.push(val);
      }
    }
    this.state.departure = departure;
    this.setState({ departure: departure });

    if (this.state.departure.length !== 0) {
      var departure = this.state.departure;
      var mapArray = this.props.busSearchList.journeys.result;
      mapArray.filter(function (e) {
        var time = e.departure_time_d.split(":");
        departure.map((bor) => {
          // if(bor === 'Before 6:00 AM' && (time[0] <= 12 || time[0] <=6) && dep[1] === 'AM'){
          //   filterArray.push(e);
          //   return filterArray;
          // }else if(bor === '6:00 AM - 12:00 PM' && (time[0] <= 12 || time[0] >= 6) && dep[1] === 'AM'){
          //   filterArray.push(e);
          //   return filterArray;
          // }else if(bor === '12:00 PM - 6:00 PM' && (time[0] <= 12 || time[0] <= 6) && dep[1] === 'PM'){
          //   filterArray.push(e);
          //   return filterArray;
          // }else if(bor === 'After 6:00 PM' && (time[0] <= 12 || time[0] >= 6) && dep[1] === 'PM'){
          //   filterArray.push(e);
          //   return filterArray;
          // }

          if (bor === "12:00 AM - 6:00 AM" && time[0] >= 0 && time[0] < 6) {
            filterArray.push(e);
            return filterArray;
          } else if (
            bor === "6:00 AM - 12:00 PM" &&
            time[0] < 12 &&
            time[0] >= 6
          ) {
            filterArray.push(e);
            return filterArray;
          } else if (
            bor === "12:00 PM - 6:00 PM" &&
            time[0] >= 12 &&
            time[0] < 18
          ) {
            filterArray.push(e);
            return filterArray;
          } else if (
            bor === "6:00 PM - 12:00 AM" &&
            time[0] >= 18 &&
            time[0] <= 23
          ) {
            filterArray.push(e);
            return filterArray;
          }
        });
      });
    }
    this.state.departureData = filterArray;
    this.setState({ departureData: filterArray });
    // console.log(filterArray);
    // console.log(this.state.departureData);
    this.Filter();
  };

  arrival = (e, val) => {
    e.preventDefault();
    var filterArray = [];
    if (this.state.arrival.length === 0) {
      var arrival = [];
      arrival.push(val);
    } else {
      // var type = new Array();
      arrival = this.state.arrival;
      if (arrival.indexOf(val) !== -1) {
        arrival.splice(arrival.indexOf(val), 1);
      } else {
        arrival.push(val);
      }
    }
    // console.log(arrival);
    this.state.arrival = arrival;
    this.setState({ arrival: arrival });

    if (this.state.arrival.length !== 0) {
      var arrival = this.state.arrival;
      var mapArray = this.props.busSearchList.journeys.result;
      mapArray.filter(function (e) {
        var time = e.arrival_time_d.split(":");
        arrival.map((bor) => {
          if (bor === "12:00 AM - 6:00 AM" && time[0] >= 0 && time[0] < 6) {
            filterArray.push(e);
            return filterArray;
          } else if (
            bor === "6:00 AM - 12:00 PM" &&
            time[0] < 12 &&
            time[0] >= 6
          ) {
            filterArray.push(e);
            return filterArray;
          } else if (
            bor === "12:00 PM - 6:00 PM" &&
            time[0] >= 12 &&
            time[0] < 18
          ) {
            filterArray.push(e);
            return filterArray;
          } else if (
            bor === "6:00 PM - 12:00 AM" &&
            time[0] >= 18 &&
            time[0] <= 23
          ) {
            filterArray.push(e);
            return filterArray;
          }
        });
      });
    }
    this.state.arrivalData = filterArray;
    this.setState({ arrivalData: filterArray });
    this.Filter();
  };

  // returnReturnj= ()=>{
  //   console.log('sd');
  //   // var check = localStorage.getItem('jStatus');
  //   // console.log(check);
  //   // if(check === "1"){
  //   //   this.setState({returnStatus:true,})
  //   //   localStorage.removeItem('jStatus')
  //   // }
  // }

  componentWillReceiveProps(nextProps) {
    this.setState({ fireRedirect: false });
  }

  componentDidMount(props) {

    var localSData = JSON.parse(localStorage.getItem("bookingData"));
    // console.log(localSData);
    var busBookingList = this.props.busBookingList;
    // console.log(busBookingList);
    if(localSData){
      var total_cost = localSData.Singleprice*localSData.seat_idArray.length;
      var postArray = {
        referance_no:localSData.referance_no+'web',
        user_id:localStorage.getItem('user_id'),
        seat_nameArray:localSData.seat_nameArray,
        bus_seat_id:localSData.seat_idArray,
        boarding_point_id:localSData.boarding_point_id,
        bus_journey_id:localSData.bus_journey_id,
        booking_cost:total_cost,
        place_of_boarding_point:localSData.place_of_boarding_point,
        request_from:'ReactApp',
      }
      localStorage.setItem('ref_no',localSData.referance_no+'web')
      this.props.BusBooking(postArray);
      this.props.searchRequest(localSData.search_parameter);
      localStorage.setItem('journey',JSON.stringify(postArray));

      var retData = localSData.search_parameter;
      localStorage.setItem('journeyDetails',JSON.stringify(retData));

      // if(!this.props.busSearchList.return_journeys || this.props.busSearchList.return_journeys === null && localStorage.getItem('journey')){
      //   this.props.history.push("/order");
      //   console.log('sdk');
      // }
      
      
      // this.returnReturnj();
      

      
      // else{
      //   this.setState({ open: true, openJourney: localSData.bus_journey_id });
      // }
      // if(busBookingList === 'Please proceed'){
      //   setInterval(() => {
      //     this.setState({returnStatus:true,})
      //     console.log('tyu',busBookingList);
      //   }, 2000);
        
      // }else{
      //   setInterval(() => {
      //     console.log('dfg',busBookingList);
      //   }, 2000);
        
      //   // this.setState({ open: true, openJourney: localSData.bus_journey_id });
      //   // localStorage.removeItem("bookingData")
      // }
      
    }else{
      this.props.searchRequest(this.props.location.state.searchArray);
    }

    if(localStorage.getItem('journey')){

      this.setState({
        returnStatus:true
      })
      this.state.returnStatus = true;
      var rett = this.props.busSearchList.return_journeys;
      // if(rett === null || rett  === undefined){
      //   this.props.history.push("/order");
      // }
    }

    this.props.UserInformation(this.props.history);
  }

  componentWillUpdate (){
    var busBookingList = this.props.busBookingList;
    var check = localStorage.getItem('jStatus');
    // console.log(check,busBookingList);
    if(check === "1"){
      this.setState({returnStatus:true,})
      localStorage.removeItem('jStatus')
    }
    if(this.props.busSearchList.return_journeys === null && localStorage.getItem('journey')){
      this.props.history.push("/order");
      // alert('kjbk ffdg');
    }
  }

  

  render() {
    const { fireRedirect } = this.state;
    // console.log('fireRedirect',fireRedirect);
    var userData = this.props.userData;
    
    var localData =JSON.parse(localStorage.getItem("bookingData"));
    const date_time = [
      "12:00 AM - 6:00 AM",
      "6:00 AM - 12:00 PM",
      "12:00 PM - 6:00 PM",
      "6:00 PM - 12:00 AM",
    ];

    // var returnCheck = this.props.busSearchList.return_journeys.length;

    let operator =
      this.props.busSearchList.journeys === undefined || this.props.busSearchList.journeys === null
        ? ""
        : this.props.busSearchList.journeys.advanched_search_parameters.bus_operator.map(
            (val, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={
                        this.state.operator.indexOf(val.name) !== -1
                          ? true
                          : false
                      }
                      onClick={(e) => this.operatorCheck(e, val.name)}
                      name={val.name}
                    />
                  }
                  label={val.name}
                />
              );
            }
          );
    
          
    let operatorReturn =
      this.props.busSearchList.return_journeys === undefined || this.props.busSearchList.return_journeys === null
        ? ""
        : this.props.busSearchList.return_journeys.advanched_search_parameters.bus_operator.map(
            (val, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={
                        this.state.operator.indexOf(val.name) !== -1
                          ? true
                          : false
                      }
                      onClick={(e) => this.operatorCheck(e, val.name)}
                      name={val.name}
                    />
                  }
                  label={val.name}
                />
              );
            }
          );
    operator = this.state.returnStatus ? operatorReturn : operator;

    let bus_type =
      this.props.busSearchList.journeys === undefined
        ? ""
        : this.props.busSearchList.journeys.advanched_search_parameters.bus_type.map(
            (val, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      key={index}
                      checked={
                        this.state.type.indexOf(val.name) !== -1 ? true : false
                      }
                      onChange={(e) => this.busTypeFilter(e, val.name)}
                    />
                  }
                  label={val.name}
                />
              );
            }
          );

    let bus_typeReturn =
      this.props.busSearchList.return_journeys === undefined || this.props.busSearchList.return_journeys === null
        ? ""
        : this.props.busSearchList.return_journeys.advanched_search_parameters.bus_type.map(
            (val, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      key={index}
                      checked={
                        this.state.type.indexOf(val.name) !== -1 ? true : false
                      }
                      onChange={(e) => this.busTypeFilter(e, val.name)}
                    />
                  }
                  label={val.name}
                />
              );
            }
          );

    bus_type = this.state.returnStatus ? bus_typeReturn : bus_type;

    let bus_boarding_point =
      this.props.busSearchList.journeys === undefined
        ? ""
        : this.props.busSearchList.journeys.advanched_search_parameters.bus_boarding_point.map(
            (val, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      key={index}
                      checked={
                        this.state.bus_boarding_point.indexOf(val.name) !== -1
                          ? true
                          : false
                      }
                      onChange={(e) => this.bus_boarding_point(e, val.name)}
                    />
                  }
                  label={val.name}
                />
              );
            }
          );

    let bus_boarding_pointReturn =
      this.props.busSearchList.return_journeys === undefined || this.props.busSearchList.return_journeys === null
        ? ""
        : this.props.busSearchList.return_journeys.advanched_search_parameters.bus_boarding_point.map(
            (val, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      key={index}
                      checked={
                        this.state.bus_boarding_point.indexOf(val.name) !== -1
                          ? true
                          : false
                      }
                      onChange={(e) => this.bus_boarding_point(e, val.name)}
                    />
                  }
                  label={val.name}
                />
              );
            }
          );

    bus_boarding_point = this.state.returnStatus
      ? bus_boarding_pointReturn
      : bus_boarding_point;

    let bus_dropping_point =
      this.props.busSearchList.journeys === undefined
        ? ""
        : this.props.busSearchList.journeys.advanched_search_parameters.bus_dropping_point.map(
            (val, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      key={index}
                      checked={
                        this.state.bus_dropping_point.indexOf(val.name) !== -1
                          ? true
                          : false
                      }
                      onChange={(e) => this.bus_dropping_point(e, val.name)}
                    />
                  }
                  label={val.name}
                />
              );
            }
          );

    let bus_dropping_pointReturn =
      this.props.busSearchList.return_journeys === undefined || this.props.busSearchList.return_journeys === null
        ? ""
        : this.props.busSearchList.return_journeys.advanched_search_parameters.bus_dropping_point.map(
            (val, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      key={index}
                      checked={
                        this.state.bus_dropping_point.indexOf(val.name) !== -1
                          ? true
                          : false
                      }
                      onChange={(e) => this.bus_dropping_point(e, val.name)}
                    />
                  }
                  label={val.name}
                />
              );
            }
          );
    bus_dropping_point = this.state.returnStatus
      ? bus_dropping_pointReturn
      : bus_dropping_point;

    let bus_facility =
      this.props.busSearchList.journeys === undefined
        ? ""
        : this.props.busSearchList.journeys.advanched_search_parameters.bus_facility.map(
            (val, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      key={index}
                      checked={
                        this.state.bus_facility.indexOf(val.name) !== -1
                          ? true
                          : false
                      }
                      onChange={(e) => this.bus_facility(e, val.name)}
                    />
                  }
                  label={val.name}
                />
              );
            }
          );

    let bus_facilityReturn =
      this.props.busSearchList.return_journeys === undefined || this.props.busSearchList.return_journeys === null
        ? ""
        : this.props.busSearchList.return_journeys.advanched_search_parameters.bus_facility.map(
            (val, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      key={index}
                      checked={
                        this.state.bus_facility.indexOf(val.name) !== -1
                          ? true
                          : false
                      }
                      onChange={(e) => this.bus_facility(e, val.name)}
                    />
                  }
                  label={val.name}
                />
              );
            }
          );
    bus_facility = this.state.returnStatus ? bus_facilityReturn : bus_facility;

    let departure = date_time.map((val, index) => {
      return (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              key={index}
              checked={this.state.departure.indexOf(val) !== -1 ? true : false}
              onChange={(e) => this.departure(e, val)}
            />
          }
          label={val}
        />
      );
    });

    let arrival = date_time.map((val, index) => {
      return (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              key={index}
              checked={this.state.arrival.indexOf(val) !== -1 ? true : false}
              onChange={(e) => this.arrival(e, val)}
            />
          }
          label={val}
        />
      );
    });

    let srData =
      this.props.busSearchList.journeys === undefined
        ? []
        : this.props.busSearchList.journeys.result;

    let srDataReturn =
      this.props.busSearchList.return_journeys === undefined  ||  this.props.busSearchList.return_journeys === null
        ? []
        : this.props.busSearchList.return_journeys.result;

    srData = this.state.returnStatus ? srDataReturn : srData;

    // let doneBtn = !this.state.returnStatus ? (<div className="done">
    // <button  onClick={(e) => this.returnData(e)}>Done</button><br />
    // <a className="note">Note : Due to traffic condition the trip may get conncted</a>
    // </div>):'';

    let fil =
      this.state.operator.length > 0 ||
      this.state.type.length ||
      this.state.bus_boarding_point.length > 0 ||
      this.state.bus_dropping_point.length > 0 ||
      this.state.bus_facility.length > 0 ||
      this.state.departure.length > 0 ||
      this.state.arrival.length > 0
        ? this.state.filArray
        : srData;
    // console.log('filter',fil);
    let resultData = fil.map((value, index) => {
      
      return (
        <Fragment key={index}>
          <Grid item xs={12} spacing={3} container className="bg-white mar-top-20 bus-card mobile-bus-list mob-pad-10-8"  >
          <Grid item xs={12} container className="pad0"   onClick={(e) => this.togglePanel(e, index,value.bus_operator_name,value.bus_name,value.departure_time)}>
            <Grid item xs={5} className="mob-pad-0">
              <div className="operator">Operator(Bus Type)</div>
              <div className="operator-name">{value.bus_operator_name}</div>
              <div className="details">
                <p>{value.bus_name}</p>
                <p>Route: {value.route_name}</p>
                <p>Starting Point: {value.start_city} </p>
                <p>Ending Point: {value.end_city}</p>
              </div>
            </Grid>
            <Grid item xs={4} container className="mob-pad-0">
            <Grid item xs={6}>
              <div className="operator">Dept. time</div>
              <div className="details">
                <p>{value.departure_time}</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="operator ">Arr. time</div>
              <div className="details">
                <p>{value.arrival_time}</p>
              </div>
            </Grid>
            </Grid>
            <Grid item xs={1} className="mob-pad-0">
              <div className="operator">Seats</div>
              <div className="details">
                <p>{value.available_seats}</p>
              </div>
            </Grid>
            <Grid item xs={2} className="pad-left-10 mob-pad-0">
              <div className="operator mob-text-right">Fare {this.state.open ? (
              <i className="fa fa-angle-up toggle_icon mar-70"></i>
            ) : (
              <i className="fa fa-angle-down toggle_icon mar-70"></i>
            )}</div>
              <div className="details fare">
                <p>{value.seat_price} Tk.</p>
              </div>
            </Grid>
          </Grid>
            {/* <Grid item xs={2}>
            <button
            className="btn-view-seats buy-ticket modify-btn w-100"
            onClick={(e) => this.togglePanel(e, index,value.bus_operator_name,value.bus_name,value.departure_time)}
          >
            Buy Tickets{" "}
            {this.state.open ? (
              <i className="fa fa-angle-up"></i>
            ) : (
              <i className="fa fa-angle-down"></i>
            )}
          </button>
            </Grid> */}
            <Grid item xs={12}>
              {this.state.open && (this.state.tIndex === index || this.state.openJourney === value.journey_id) ? (
                <BusSeat retJ={this.props.busSearchList.return_journeys}  searchPara={JSON.parse(localStorage.getItem("bookingData"))?JSON.parse(localStorage.getItem("bookingData")).search_parameter:this.props.location.state.searchArray} journey_id={value.journey_id} returnStatus={this.state.returnStatus} returnDataShow={this.returnDataShow} />
                 
              ) : (
                " "
              )}
              {/* {doneBtn} */}
            </Grid>
          </Grid>
        </Fragment>
      );
    });

    // resultData = resultData.length >0 ? resultData : (<img src="/assets/images/no_data.png"  alt="no data found" />);

    return (
      <div className="search-banner">
        <div className="src-banner-img">
          <img
            src="/assets/images/bus_search_banner.jpg"
            className="logo web-bus-banner"
            alt="img"
          />
          <img
            src="/assets/images/bus_banner_mobile.jpg"
            className="logo mobile-bus-banner"
            alt="img"
          />
        </div>
        <Container maxWidth="md">
          <div className="search-box">
            <Tabs>
              <TabList>
                <Tab>
                  {" "}
                  <i className="fa fa-bus" aria-hidden="true"></i> Bus
                </Tab>
                <Tab>
                  {" "}
                  <Link to={{ pathname:'/',state:{from:1} }} ><i className="fa fa-ship" aria-hidden="true"></i> Launch </Link>
                </Tab>
                <Tab>
                  {" "}
                  <Link to={{ pathname:'/',state:{from:2} }} ><i className="fa fa-plane" aria-hidden="true"></i> Airlines </Link>
                </Tab>
                <Tab>
                  {" "}
                  <Link to={{ pathname:'/',state:{from:3} }} > <i className="fa fa-building" aria-hidden="true"></i>  Hotels </Link>
                </Tab>
              </TabList>

              <TabPanel>
                <form noValidate autoComplete="off">
                  <ModifySearch
                    type="bus"
                    modArray={localData?localData.search_parameter:this.props.location.state.searchArray}
                    returnStatus={this.state.returnStatus}
                    toggleCheck={this.toggleCheck}
                  />
                </form>
                <button className="btn-view-seats tracking mob-modify" onClick={this.handleDrawerOpen}>Modify Search</button>
              </TabPanel>
              <TabPanel></TabPanel>
              <TabPanel></TabPanel>
              <TabPanel></TabPanel>
            </Tabs>
          </div>
        </Container>
        <Container maxWidth="md" className="pad-top-25">
          <Grid container>
            <Grid item xs={3} className="mobile-gird">
              <h3 className="refine-search">Refine Search</h3>
              <Collapsible trigger="Operator" open={true}>
                {operator}
              </Collapsible>
              <Collapsible trigger="Bus Type" open={true}>
                {bus_type}
              </Collapsible>
              <Collapsible trigger="Boarding Point" open={true}>
                {bus_boarding_point}
              </Collapsible>
              <Collapsible trigger="Dropping Point" open={true}>
                {bus_dropping_point}
              </Collapsible>
              <Collapsible trigger="Facillities" open={true}>
                {bus_facility}
              </Collapsible>
              <Collapsible trigger="Departure Time" open={true}>
                {departure}
              </Collapsible>
              <Collapsible trigger="Arrival Time" open={true}>
                {arrival}
              </Collapsible>
            </Grid>
            <Grid item xs={9} className="mobile-search-data">
              {/* {resultData} */}
              {/* { this.props.busSearchList === undefined ? (<i className="fa fa-spinner fa-spin load-fa"></i>):''} */}
              { resultData }
              
            </Grid>
          </Grid>
        </Container>
        {/* {fireRedirect && (
      <Redirect
        to={{
          pathname: '/order',
          // state: { searchArray: this.state },
        }}
      />
    )} */}

<Drawer
        variant="persistent"
        anchor="left"
        open={this.state.drawerOpen}
      >
         <Container maxWidth="md" className="pad-top-25">
          <Grid container>
            <Grid item xs={3} className="drawer-gird">
              <h3 className="refine-search">Refine Search</h3>
              <Collapsible trigger="Operator" open={true}>
                {operator}
              </Collapsible>
              <Collapsible trigger="Bus Type" open={true}>
                {bus_type}
              </Collapsible>
              <Collapsible trigger="Boarding Point" open={true}>
                {bus_boarding_point}
              </Collapsible>
              <Collapsible trigger="Dropping Point" open={true}>
                {bus_dropping_point}
              </Collapsible>
              <Collapsible trigger="Facillities" open={true}>
                {bus_facility}
              </Collapsible>
              <Collapsible trigger="Departure Time" open={true}>
                {departure}
              </Collapsible>
              <Collapsible trigger="Arrival Time" open={true}>
                {arrival}
              </Collapsible>
            </Grid>
            
          </Grid>
          <div className="drawer-btn">
              <button className="btn-view-seats  tracking" onClick={this.handleDrawerClose}>Apply</button>
          </div>
        </Container>
      </Drawer>
      {userData.other_pop === '' || userData.other_pop === undefined ? '' : <Pop img={userData.other_pop}/> }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('sdkak',state.BusBookingReducer.bookingList);
  
  return {
    loadingg : state.SearchReducer.busLoading,
    busSearchList: state.SearchReducer.busSearchList,
    busBookingList: state.BusBookingReducer.bookingList,
    userData: state.AuthReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchRequest: (creds) => dispatch(searchRequest(creds)),
    BusBooking: (creds) => dispatch(BusBooking(creds)),
    UserInformation: (history) => dispatch(UserInformation(history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusSearch);
