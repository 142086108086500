import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import { Fragment } from "react";
import HeaderBanner from "./HeaderBanner";
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import HttpService from '../Store/Services/HttpService';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import "./Dialog.css";
import Button from "@material-ui/core/Button";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default class LaunchTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      isOpenTrackingModal: false,
      setOpenTrackingModal: false,
      openTrackingModal: false,
      lat:'',
      lng:'',
      name:''
    };

  }


  handleClickOpenTracking = (data) => {
    this.setState({ setOpenTrackingModal: true, openTrackingModal: true,lat:data.lat,lng:data.lng,name:data.name});
  };
  handleCloseTracking = () => {
    this.setState({ setOpenTrackingModal: false, openTrackingModal: false});
  };

  componentDidMount = () => {
    const http = new HttpService();
    let url = "launch-location";
    http.getData(url).then(data=>{
      console.log(data);
      this.setState({data:data})
    }).catch((error)=> {
        console.log(error)
        return error; 
    });
  };
  render() {
    return (
      <Fragment>
        <HeaderBanner title="Launch Tacking" />
        <Container maxWidth="md">

          <Grid container  spacing={3} className="pad-bottom mob-about mar31">
          <TableContainer >
            <Table aria-label="customized table ">
              <TableBody>
                {this.state.data.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell align="left">
                      {/* i className="fa fa-ship custom-ship" aria-hidden="true"></i> */}
                      <p style={{display:'initial'}}><embed height="30px"  src="images/Vessel_Track.svg" /> <span className="span-name">{row.name}</span></p>
                      </StyledTableCell>
                    <StyledTableCell align="right">
                    <button onClick={()=>this.handleClickOpenTracking(row)} className="btn-view-seats  tracking-page">
                       <i className="fa fa-map-marker"></i> Tracking Launch
                    </button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Grid>
          <Dialog
          fullScreen 
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.openTrackingModal}
          onClose={this.handleCloseTracking}
          maxWidth={100}
        >
          <div style={{display:'inline-flex'}}>
          <p style={{paddingLeft:'24px',width:'100%'}}>{this.state.name}</p>
          <DialogActions>
          
            <Button className="cl-btn" onClick={this.handleCloseTracking} >
              <i className="fa fa-close"></i>
            </Button>
          </DialogActions>
          </div>
          <DialogContent>
          <div>
            <iframe width="100%" height="400px"
             src={`https://www.google.com/maps/embed/v1/place?q=${this.state.lat},${ this.state.lng}&key=AIzaSyDZeKmppB1BbqEao2horwtegrIJN8CoRVw`}> 
             </iframe>
            </div>
          </DialogContent>
          
        </Dialog>
        </Container>
        
      </Fragment>
    );
  }
}
