import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import {reqForgetPassword} from '../Store/Actions/AuthAction'
import { withRouter } from "react-router-dom";
import {signInWithGoogle} from "./firebase";
import {signInWithFacebook} from "./firebase";
import {auth} from "./firebase";


class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
          email: "",
          loader:false,
        }
      };
    
      handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.email ===''){
            toast.warning(<i className="fa fa-exclamation-triangle"> The Email field is required</i>);
        }else{
            this.props.reqForgetPassword(this.state,this.props.history);
            this.setState({loader:true,});
        }
      };
      handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      };
      

  render() {
    var loading = this.props.loading;  

    return (
      <div>
        <Container maxWidth="md">
          <Container maxWidth="md">
            <div className="powerbit-login">
              <h3>Welcome To Powerbit !</h3>
              <p>Please login with your email .</p>
              <div className="form-controll">
                <div className="login-header">Forget Password</div>
                <form className="login-form" onSubmit={this.handleSubmit}>
                
                <TextField
                  id="standard-textarea"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  name="email"
                  onChange = {this.handleChange}
                />
                
                <button className="sign-up-btn mar-top-10">{loading && this.state.loader ? <i className="fa fa-spinner fa-spin"></i> :''}Submit</button>
                <div className="forget-text">
                <FormGroup row>
                </FormGroup>
                </div>
                </form>
              </div>
            </div>
          </Container>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state,history) => {
  
    return {
      loading:state.AuthReducer.loading,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        reqForgetPassword:(creds,history) => dispatch(reqForgetPassword(creds,history)),
    };
  };

  
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgetPassword));
