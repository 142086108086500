import HttpService from './HttpService';


export const SignUpService = (credentials) =>{
    const http = new HttpService();
    let signUpUrl = "register";
    return http.postData(credentials,signUpUrl).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })
  }

  export const LoginUser = (credentials) =>{
    const http = new HttpService();
    let loginUrl = "login";
    return http.postData(credentials, loginUrl).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })

}
  export const SocialLoginUser = (credentials) =>{
    const http = new HttpService();
    let loginUrl = "social-login";
    return http.postData(credentials, loginUrl).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })

}


export const AuthUserInformation = () =>{
    const http = new HttpService();
    let url = "profile";
    return http.getAuthenticateData(url).then(data=>{
        return data;
    }).catch((error)=> {console.log(error)
        return error; 
    });
  }

  export const userTrips = (id) =>{
    const http = new HttpService();
    let url = "my-trips/"+id;
    return http.getAuthenticateData(url).then(data=>{
        return data;
    }).catch((error)=> {console.log(error)
        return error; 
    });
  }


  export const UserLogoutD = () =>{
    const http = new HttpService();
    let url = "logout";
    return http.logout(url).then(data=>{
        return data;
    }).catch((error)=> {console.log(error)
        return error; 
    });
  }

  export const UserEdit = (credentials) =>{
    const http = new HttpService();
    var id = credentials.id
    let editPatientUrl = "profile/update/"+id;
    return http.postData(credentials,editPatientUrl).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })
  }
  export const editUserImage = (credentials) =>{
    const http = new HttpService();
    var id = credentials.id
    let editPatientUrl = "profile/image-update/"+id;
    return http.postData(credentials,editPatientUrl).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })
  }


  export const ChangePassword = (credentials) =>{
    const http = new HttpService();
    let url = "change-password";
    return http.postData(credentials,url).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })
  }
  export const sendMailForgetPassword = (credentials) =>{
    const http = new HttpService();
    let url = "password/email";
    return http.postData(credentials,url).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })
  }
  export const reqForResetPassword = (credentials) =>{
    const http = new HttpService();
    let url = "password/reset";
    return http.postData(credentials,url).then(data=>{
        return data;
    }).catch((error)=>{
        console.log(error);
        return error;
    })
  }

