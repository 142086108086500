import React, { Component, Fragment } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Tooltip from "@material-ui/core/Tooltip";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import "./Dialog.css";
import SimpleImageSlider from "react-simple-image-slider";
import { connect } from "react-redux";
import { hotelRequestForDetails,getHotelReview } from "../Store/Actions/SearchAction";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


class HotelDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      setOpen: false,
      open: false,
      openReview: false,
      city:'',
      adult:'',
      children:'',
      number_of_room:'',
      id:'',
      dataProps:'',
      check_in:'',
      check_out:'',
      categoryDetails:'',
      selectedRoom:[],
      bookData:[],
      images2:[],
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseReview = this.handleCloseReview.bind(this);
    this.selectedRoom = this.selectedRoom.bind(this);
  }

  selectedRoom = (data,type,hotel_id,av_room,price) => {
    var bookData = this.state.bookData;
    if(av_room === 0){
      toast.warning(<i className="fa fa-exclamation-triangle"> Sorry No room are available !</i>);
      return true;
    }

    if(type === 'plus'){
     
      if(bookData.length === 0){
        var dataArray ={
          hotel_id :hotel_id,
          id :data,
          room :1,
          price:price,
        }
        bookData.push(dataArray);
      }else{
        var dataCheck = true;
        bookData.filter((val,index)=>{
          if(val.id === data){
            dataCheck = false;
            var room = parseInt(val.room) + parseInt(1); 
            if(av_room < room){
              toast.warning(<i className="fa fa-exclamation-triangle"> You can booked maximum {av_room} room!</i>);
              return true;
            }
            var total_price  = (parseInt(price))*(parseInt(room));
            var dataArray ={
              hotel_id :hotel_id,
              id :data,
              room :room,
              price:total_price,
            }
            bookData.splice(index,1);
            bookData.push(dataArray);
          }
        })
        if(dataCheck){
          var dataArray ={
            hotel_id :hotel_id,
            id :data,
            room :parseInt(1),
            price:price
          }
          bookData.push(dataArray);
        }

      }
    }else if(type === 'minus'){
      if(bookData.length === 0){
        return 0;
      }else{
        bookData.filter((val,index)=>{
          if(val.id === data && val.room > 1){
            var room = parseInt(val.room) - parseInt(1); 
            var total_price  = (parseInt(price))*(parseInt(room));
            var dataArray ={
              hotel_id :hotel_id,
              id :data,
              room :room,
              price:total_price,
            }
            bookData.splice(index,1);
            bookData.push(dataArray);
          }else if(val.room === 1){
            bookData.splice(index,1);
          }
        })
      }
    }
    

    this.setState({bookData:bookData});
    this.state.bookData = bookData;

  }

  handleClickOpen = (data) => {
    
    var dArr = [];
    var st = data.images.map((val,ind)=>{
      console.log(val);
        var imgArray = {
          url:val
        };
        dArr.push(imgArray);
    });
    console.log(dArr);
    this.setState({ setOpen: true, open: true,categoryDetails:data,images2:dArr });
  };
  openReview = () => {
    this.props.getHotelReview(this.props.hotelDetails.id)
    this.setState({ setOpen: true, openReview: true });
  };

  handleClose = () => {
    this.setState({ setOpen: false, open: false,categoryDetails:' ',images2:'' });
  };
  handleCloseReview = () => {
    this.setState({ setOpen: false, openReview: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };
  componentDidMount(){
    
    var data = this.props.location.state.dataArray;
    var dataProps = {
      city:data.city,
      adult:data.adult,
      children:data.children,
      number_of_room:data.number_of_room,
      id:data.id,
      check_in:data.check_in,
      check_out:data.check_out,
    };
    this.setState({
      city:data.city,
      adult:data.adult,
      children:data.children,
      number_of_room:data.number_of_room,
      id:data.id,
      dataProps:dataProps,
      check_in:data.check_in,
      check_out:data.check_out,
    })
    this.props.hotelRequestForDetails(dataProps);

  }
  checkValidation=()=>{
    toast.warning(<i className="fa fa-exclamation-triangle"> Please Chose Number of room ! </i>);
  }

  

  render() {
   
    const { photoIndex, isOpen } = this.state;
    var hotelData = this.props.hotelDetails;
    const images = hotelData.length>0 || hotelData === undefined? []:hotelData.images;
    var facilities = hotelData.length>0 || hotelData === undefined? []: hotelData.most_popular_facilities.map((val,index)=>{
        return(
          <Fragment>
            <div className="detail-facilities">
                  <img
                    onClick={() => this.setState({ isOpen: true })}
                    src={val.icon}
                    className="img rule-icon"
                    alt="img"
                  />
                  <p className="mar-top-2">{val.name}</p>
                </div>
          </Fragment>
        )
    });
    var rules = hotelData.length>0 ||  hotelData === undefined? []: hotelData.house_rules.map((val,index)=>{
      return(
        <Fragment>
          {
           val.name === 'Check-In' || val.name === 'Check-Out' ?<div className="rules">
           <div className="rules-icon">
             <img src={val.icon}  alt={val.name} className="rule-icon" /> {" "}
             {val.name}&nbsp;&nbsp;&nbsp;
           </div>
           <div className="progrss-bar ">
             <div className="no-color"></div>
             <Tooltip
               title={val.description}
               open={true}
               placement="right"
               arrow
             >
               <div className="color"></div>
             </Tooltip>
           </div>
         </div>:<div className="rules">
                <div className="rules-icon">
                <img src={val.icon}  alt={val.name} className="rule-icon" /> {" "}
                  {val.name}
                </div>
                <p className="rules-text">
                  {val.description}
                </p>
              </div>
          }
              
        </Fragment>
      )
    });
    var faq = hotelData.length>0 || hotelData === undefined? []: hotelData.faqs.map((val,index)=>{
      return(
        <Fragment>
          <AccordionItem className="item-acor">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      {val.question}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="acor-body">
                    {val.answer}
                  </AccordionItemPanel>
                </AccordionItem>
                
        </Fragment>
      )
    })

    var mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let jDate =  this.state.check_in?this.state.check_in.split("-"):'';
    let monthName = mL[jDate[1]-1];
    let day = jDate[2];
    let year = jDate[0];
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date(this.state.check_in);
    var dayName = days[d.getDay()];
  

    let rDate = this.state.check_out?this.state.check_out.split("-"):'';
    let rmonthName = mL[jDate[1]-1];
    let rday = rDate[2];
    let ryear = rDate[0];
    var rdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var rd = new Date(this.state.check_out);
    var rdayName = rdays[rd.getDay()];

    var check_in = {
      date :jDate,
      year :year,
      dayName :dayName,
    };

    var check_out = {
      date :rDate,
      year :ryear,
      dayName :rdayName,
    };

    var bookPage  = this.state.bookData.length === 0 ? <a onClick={this.checkValidation} className="book-btn pointer">Book</a> : <Link to={{ pathname: '/hotel-booking', state: { bookData: this.state.bookData,hotelData:hotelData,check_in:check_in,check_out:check_out} }} className="book-btn pointer">Book</Link>;
    var loginPage = this.state.bookData.length === 0 ? <a onClick={this.checkValidation} className="book-btn pointer">Book</a> :  <Link to={{ pathname: '/sign-in', state: { bookData: this.state.bookData,hotelData:hotelData,check_in:check_in,check_out:check_out,type:'hotel'} }} className="book-btn pointer">Book</Link>;
    var reBtn = localStorage.getItem('user') ? bookPage : loginPage;
    var roomCheck = true;

    var roomsMob = hotelData.length > 0 &&  hotelData.room_categories === undefined ? []: hotelData.room_categories.map((val,index)=>{
      var check = true;
      var room = this.state.bookData.map((data,index)=>{
        if(data.id === val.id){
          check = false;
          return data.room;
        }
      });
      if(check){
        var room = 0;
      }
        return(
          <Fragment>
            <div className="mob-cate-details">
            <h3 onClick={()=>this.handleClickOpen(val)}>{val.name}</h3>
            <p>{val.facility_description}</p>
            <button class="book-btn">{val.vat_applicable === 0 ? <i class="fa fa-times" aria-hidden="true"></i> : <i className="fa fa-check"></i>} Vat</button>
            <button class="book-btn">{val.smoking === 0 ? <i class="fa fa-times" aria-hidden="true"></i> : <i className="fa fa-check"></i>} Smoking</button>
            <button class="book-btn">{val.free_parking === 0 ? <i class="fa fa-times" aria-hidden="true"></i> : <i className="fa fa-check"></i>} Free Parking</button>
            {
                        val.facilities.map((fc,row)=>{
                          return(
                            <button class="book-btn"><img
                            onClick={() => this.setState({ isOpen: true })}
                            src={fc.icon}
                            className="img mob-rule-icon"
                            alt="img"
                          /> {fc.name}</button>
                            
                          )
                        })
                      }
            
            <div className="room-select">
                      <div className="plus-h" onClick={()=>this.selectedRoom(val.id,'minus',val.hotel_id,val.number_of_available_room,val.price)}>
                        <i className="fa fa-minus "></i>
                      </div>
                      <div className="number-h">{room}</div>
                      <div className="minus-h" onClick={()=>this.selectedRoom(val.id,'plus',val.hotel_id,val.number_of_available_room,val.price)}>
                        <i className="fa fa-plus"></i>
                      </div>
                    </div>
                    <p>{room} seats are selected</p>
                      <h3>BDT. {val.price}</h3>
                      <p className="line-height-15">
                        +BDT {(val.price*100)/val.vat_percentage} taxes and charges
                      </p>
                      { index === 0 ? reBtn : " "}
                      <p className="line-height-15 ">
                        <i className="fa fa-bullhorn bullhorn"></i> Confirmation
                        is immediate
                      </p>
                      <p className="line-height-15 ">
                        <i className="fa fa-bullhorn bullhorn"></i> seats are
                        selected
                      </p>
                      <p className="line-height-15 ">
                        <i className="fa fa-bullhorn bullhorn"></i> No
                        registration required
                      </p>


            </div>
         
          </Fragment>
        )
    });
    var rooms = hotelData.length > 0 &&  hotelData.room_categories === undefined ? []: hotelData.room_categories.map((val,index)=>{
      var check = true;
      var room = this.state.bookData.map((data,index)=>{
        if(data.id === val.id){
          check = false;
          return data.room;
        }
      });
      if(check){
        var room = 0;
      }
        return(
          <Fragment>
        <Grid item xs={3} className="pad-left-5">
                    <div className="room-details pad-left-5">
                      <h3 onClick={()=>this.handleClickOpen(val)}>
                        {val.name}
                      </h3>
                      <p>Vat : {val.vat_applicable === 0 ? 'No' : 'Yes'}</p>
                      <p>Smoking : {val.vat_applicable === 0 ? 'No' : 'Yes'}</p>
                      <p>Parking : {val.vat_applicable === 0 ? 'No' : 'Yes'}</p>
                      <p>View : {val.view}</p>
                      <p>Bathroom : {val.bathroom}</p>
                      {
                        val.facilities.map((fc,row)=>{
                          return(
                            <Fragment>
                              <div className="detail-facilities">
                        <img
                          onClick={() => this.setState({ isOpen: true })}
                          src={fc.icon}
                          className="img rule-icon"
                          alt="img"
                        />
                        <p>{fc.name}</p>
                      </div>
                            </Fragment>
                          )
                        })
                      }
                      </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="sleep">
                      <i className="fa fa-user-o"></i>
                      <i className="fa fa-user-o"></i>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="today-price">
                      <h3>BDT. {val.price}</h3>
                      <p>+BDT {(val.price*100)/val.vat_percentage} taxes and charges</p>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="today-price">
                      <p>{val.facility_description}</p>
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    <div className="room-select">
                      <div className="plus-h" onClick={()=>this.selectedRoom(val.id,'minus',val.hotel_id,val.number_of_available_room,val.price)}>
                        <i className="fa fa-minus "></i>
                      </div>

                      <div className="number-h">
                         {/* {this.getRommForThisCategory(val.id)} */}
                        {room}
                      
                      </div>

                      <div className="minus-h" onClick={()=>this.selectedRoom(val.id,'plus',val.hotel_id,val.number_of_available_room,val.price)}>
                        <i className="fa fa-plus"></i>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="selected-room">
                      <p>{room} seats are selected</p>
                      <h3>BDT. {val.price}</h3>
                      <p className="line-height-15">
                        +BDT {(val.price*100)/val.vat_percentage} taxes and charges
                      </p>
                      {/* <button  className="book-btn">Book</button> */}
                      { index === 0 ? reBtn : " "}
                      <p className="line-height-15 ">
                        <i className="fa fa-bullhorn bullhorn"></i> Confirmation
                        is immediate
                      </p>
                      <p className="line-height-15 ">
                        <i className="fa fa-bullhorn bullhorn"></i> seats are
                        selected
                      </p>
                      <p className="line-height-15 ">
                        <i className="fa fa-bullhorn bullhorn"></i> No
                        registration required
                      </p>
                    </div>
                  </Grid>
                  
      </Fragment>
        )
    });

    

      var ratting = '';
          if(hotelData.star_ratting === 1){
              ratting = <Fragment >
                  <i className="fa fa-star"> </i> 
                  <i className="fa fa-star-o"> </i>  
                  <i className="fa fa-star-o"> </i>  
                  <i className="fa fa-star-o"> </i>  
                  <i className="fa fa-star-o"> </i>  
              </Fragment>     
          }else if(hotelData.star_ratting === 2){
            ratting = <Fragment>
                  <i className="fa fa-star"> </i> 
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star-o"> </i>  
                  <i className="fa fa-star-o"> </i>  
                  <i className="fa fa-star-o"> </i>  
              </Fragment> 
          }else if(hotelData.star_ratting === 3){
            ratting = <Fragment>
                  <i className="fa fa-star"> </i> 
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star-o"> </i>  
                  <i className="fa fa-star-o"> </i>  
              </Fragment> 
          }
          else if(hotelData.star_ratting === 4){
            ratting = <Fragment>
                  <i className="fa fa-star"> </i> 
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star-o"> </i>  
              </Fragment> 
          }
          else if(hotelData.star_ratting === 5){
            ratting = <Fragment>
                  <i className="fa fa-star"> </i> 
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star"> </i>  
                  <i className="fa fa-star"> </i>  
              </Fragment> 
          }
    
    
    return (
      <div className="search-banner">
        <div className="src-banner-img">
          <img
            src="/assets/images/hotel/banner.jpg"
            className="logo"
            alt="img"
          />
        </div>
        <Container maxWidth="md" className="pad-top-25">
          <Grid container spacing={3}>
            {hotelData.images === undefined ? <div className="center"><i className="fa fa-spinner fa-spin"></i></div> : <Fragment>
              <Grid item xs={6}>
              <img
                onClick={() => this.setState({ isOpen: true })}
                src={hotelData.images[0]}
                className="hotel-details-img"
                alt="img"
              />
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <img
                  onClick={() => this.setState({ isOpen: true })}
                  src={hotelData.images[1]}
                  className="hotel-details-img_2"
                  alt="img"
                />
              </Grid>
              <Grid item xs={12}>
                <img
                  onClick={() => this.setState({ isOpen: true })}
                  src={hotelData.images[2]}
                  className="hotel-details-img_2 pad-top-7 over-img"
                  alt="img"
                />
                <div class="total-text">
                  <h2>{hotelData.images.length-3} +</h2>
                </div>
              </Grid>
            </Grid>
          
            </Fragment>
             }
            <Grid item xs={12}>
              <div className="hotel-name-details pad-left-0 font-weight-600">
                {hotelData.name}
              </div>
              <div className="hotel-details hotel-location pad-left-0">
                <i className="fa fa-map-marker"> </i>{" "}
                {hotelData.address}
              </div>
              <div className="hotel-details hotel-location pad-left-0">
                {ratting} {hotelData.total_review} review{" "}
              </div>
              <div className="hotel-detail">
                <p>
                  {hotelData.description}
                </p>

                <div className="hotel-name-details pad-left-0 font-weight-600">
                  Most popular facilities
                </div>
                {facilities}
                <a className="note detail-note">
                  No credit card needed to book. we'll send on email confirming
                  your reservation
                </a>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="hotel-name-details pad-left-0 font-weight-600 pad-top-25">
                Availability
              </div>
            </Grid>
            <Grid item xs={3}>
              <p className="availablity-title">Check In Date</p>
              <div className="availability-box">
                <div className="av-day">{dayName}</div>
                <div className="av-date">{day} {monthName} {year}</div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <p className="availablity-title">Check Out Date</p>
              <div className="availability-box">
                <div className="av-day">{rdayName}</div>
                <div className="av-date">{rday} {rmonthName} {ryear}</div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <p className="availablity-title">Guests</p>
              <div className="availability-box mar30">
                <div className="av-day pad-top-15">{this.state.adult} Adult</div>
              </div>
            </Grid>
            <Grid item xs={3} className="btn-change-search">
              <Link to={{ pathname:'/',state:{from:3} }} className="change-search text-decoration-none web-src-hotel">Change Search</Link>
              <Link to={{ pathname:'/',state:{from:3} }} className="change-search text-decoration-none mob-src-hotel">Search</Link>
              {/* <Button className="change-search" onClick={this.handleClickOpen}>
                Change Search
              </Button> */}
            </Grid>
          </Grid>
         
         
          <div className="pad-top-15 web-cate-details">
            <div className="red-border bg-white">
              <Grid container spacing={3} className="pad-10 ">
                <Grid item xs={3} className="pad-left-15 ">
                  {" "}
                  Room Type
                </Grid>
                <Grid item xs={2} >
                  Sleeps
                </Grid>
                <Grid item xs={2} >
                  Today's Price
                </Grid>
                <Grid item xs={2} >
                  Your Choice
                </Grid>
                <Grid item xs={1} >
                  {" "}
                  Rooms
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              <div className="details-body">
                <Grid container spacing={3}>
                  {rooms}
                </Grid>
              </div>
            </div>
          </div>
        

          <div className="pad-top-15 mob-cate-details">
            <div className="red-border bg-white">
              <div className="details-body">
                
                <Grid container spacing={3}>
                  {roomsMob}
                </Grid>
              </div>
            </div>
          </div>
        
        
        
        
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="hotel-name-details pad-left-0 font-weight-600 pad-top-25">
                Guest Reviews
              </div>
              <div className="hotel-review">
                <p>{this.props.hotelDetails.total_review}</p>
                <span className="color-red mar-top-20 pad-left-5">
                  Passable
                </span>
                <span className="font-13 mar-top-20 pad-left-5">
              reviews
                </span>
                <a onClick={this.openReview} className="color-red mar-top-20 pad-left-5 pointer">Read more</a>
              </div>
            </Grid>
            {/* <Grid item xs={12}>
              <div className="btn-group">
                <button className="book-btn" onClick={this.openReview}>
                  Room <i className="fa fa-plus"> </i>
                </button>
                <button className="book-btn">
                  Bathroom <i className="fa fa-plus"> </i>
                </button>
                <button className="book-btn">
                  Towel <i className="fa fa-plus"> </i>
                </button>
                <button className="book-btn">
                  Breakfast <i className="fa fa-plus"> </i>
                </button>
                <button className="book-btn">
                  wifi <i className="fa fa-plus"> </i>
                </button>
              </div>
            </Grid> */}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="hotel-name-details pad-left-0 font-weight-600 pad-top-25">
                House Rules
              </div>
              <div className="hotel-review">
                <span className="font-13">
                  Seal welcome resort takes special request - add in the next
                  step
                </span>{" "}
              </div>
              {rules}
              </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="hotel-name-details pad-left-0 font-weight-600 pad-bottom">
                FAQ About {hotelData.name}{" "}
              </div>
              <Accordion className="no-border">
                {faq}
              </Accordion>
            </Grid>
          </Grid>
        </Container>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          maxWidth='md'
        >
          <DialogActions>
            <Button onClick={this.handleClose}>
              <i class="fa fa-close"></i>
            </Button>
          </DialogActions>
          {this.state.open ? <Fragment>
            <DialogContent>
            <div className="image-slider web-img-slider">
              <SimpleImageSlider
                width={908}
                bgColor={"#fff"}
                height={350}
                showNavs="true"
                showBullets={true}
                style={{
                  margin: "auto 0px",
                  textAlign: "center",
                  position: "relative",
                }}
                images={this.state.images2}
              />
              <div className="details-body">
                <Grid container spacing={3}>
                  <Grid item xs={12} className="pad-left-5">
                    <div className="room-details pad-left-5">
                      <h3 className="font-24">{this.state.categoryDetails.name}</h3>
                      <p>Vat : {this.state.categoryDetails.vat_applicable === 0 ? 'No' : 'Yes'}</p>
                      
                      {
                        this.state.categoryDetails.facilities.map((fc,row)=>{
                          return(
                            <Fragment>
                              <div className="detail-facilities">
                        <img
                          onClick={() => this.setState({ isOpen: true })}
                          src={fc.icon}
                          className="img rule-icon"
                          alt="img"
                        />
                        <p>{fc.name}</p>
                      </div>
                            </Fragment>
                          )
                        })
                      }
                      <p>{this.state.categoryDetails.facility_description}</p>
                      </div>
                  </Grid>
                  <Grid item xs={12}>
                  <div className="hotel-name-details pad-left-0 font-weight-600 pad-top-25">
                      In your private Bathroom:
                    </div>
                    <p className="line-height-5">
                    {this.state.categoryDetails.bathroom}
                    </p>

                    <div className="hotel-name-details pad-left-0 font-weight-600 pad-top-25">
                      View:
                    </div>
                    <p className="line-height-5">
                    {this.state.categoryDetails.view}
                    </p>
                    
                    <div className="hotel-name-details pad-left-0 font-weight-600 pad-top-25">
                      Smoking:
                    </div>
                    <p className="line-height-5">
                       {this.state.categoryDetails.smoking === 0 ? 'No' : 'Yes'}
                    </p>
                    <div className="hotel-name-details pad-left-0 font-weight-600 pad-top-25">
                      Parking:
                    </div>
                    <p className="line-height-5">
                    {this.state.categoryDetails.free_parking === 0 ? 'No' : 'Yes'}
                    </p>
                  </Grid>
                </Grid>
              </div>
            </div>
         
            <div className="image-slider mob-img-slider">
              <SimpleImageSlider
                width={255}
                bgColor={"#fff"}
                height={240}
                showNavs="true"
                showBullets={true}
                style={{
                  margin: "auto 0px",
                  textAlign: "center",
                  position: "relative",
                }}
                images={this.state.images2}
              />
              <div className="details-body">
                <Grid container spacing={3}>
                  <Grid item xs={12} className="pad-left-5">
                    <div className="room-details pad-left-5">
                      <h3 className="font-24">{this.state.categoryDetails.name}</h3>
                      <p>Vat : {this.state.categoryDetails.vat_applicable === 0 ? 'No' : 'Yes'}</p>
                      
                      {
                        this.state.categoryDetails.facilities.map((fc,row)=>{
                          return(
                            <Fragment>
                              <div className="detail-facilities">
                        <img
                          onClick={() => this.setState({ isOpen: true })}
                          src={fc.icon}
                          className="img rule-icon"
                          alt="img"
                        />
                        <p>{fc.name}</p>
                      </div>
                            </Fragment>
                          )
                        })
                      }
                      <p>{this.state.categoryDetails.facility_description}</p>
                      </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="hotel-name-details pad-left-0 font-weight-600 pad-top-25">
                      In your private Bathroom:
                    </div>
                    <p className="line-height-5">
                    {this.state.categoryDetails.bathroom}
                    </p>

                    <div className="hotel-name-details pad-left-0 font-weight-600 pad-top-25">
                      View:
                    </div>
                    <p className="line-height-5">
                    {this.state.categoryDetails.view}
                    </p>
                    
                    <div className="hotel-name-details pad-left-0 font-weight-600 pad-top-25">
                      Smoking:
                    </div>
                    <p className="line-height-5">
                       {this.state.categoryDetails.smoking === 0 ? 'No' : 'Yes'}
                    </p>
                    <div className="hotel-name-details pad-left-0 font-weight-600 pad-top-25">
                      Parking:
                    </div>
                    <p className="line-height-5">
                    {this.state.categoryDetails.free_parking === 0 ? 'No' : 'Yes'}
                    </p>
                  </Grid>
                </Grid>
              </div>
            </div>
         
         
         
          </DialogContent>
          
          </Fragment>:''}
          <DialogActions>
            {/* <Button onClick={this.handleClose} className="footer-btn">
              Cancel
            </Button> */}
            <Button onClick={this.handleClose} className="footer-btn">
              Book
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openReview}
          onClose={this.handleCloseReview}
          maxWidth='md'
        >
          <DialogActions>
            <Button onClick={this.handleCloseReview}>
              <i class="fa fa-close"></i>
            </Button>
          </DialogActions>
          <DialogContent>
            <Grid container spacing={3} className="review">
              {/* <Grid item xs={8}>
                <div className="hotel-review">
                  <p style={{ paddingLeft: "7px" }}>2.3</p>
                  <span className="color-red mar-top-20 pad-left-5">
                    Passable
                  </span>
                  <span className="font-13 mar-top-20 pad-left-5">
                    38 reviews
                  </span>
                  <a className="color-red mar-top-20 pad-left-5">Read more</a>
                  <span
                    className="font-13 mar-top-20 pad-left-150"
                    style={{ color: "#91C686" }}
                  >
                    100% real guest experience
                  </span>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="mar-top-20">
                  <Button className="review-btn"> write a review </Button>
                </div>
              </Grid> */}
              {/* <Grid item xs={12} className="pad-bottom-0 pad-top-0">
                <div className="hotel-name-details pad-left-0 font-weight-600 pad-top-25">
                  {" "}
                  Categories{" "}
                </div>
              </Grid>
              <Grid item xs={4} className="pad-top-0 pad-bottom-0">
                <p>Facialities</p>
                <div style={{ display: "flex" }}>
                  <div className="ratting-bar">
                    <div className="red-bar"></div>
                  </div>
                  <span className="rating-count">7.8</span>
                </div>
              </Grid>
              <Grid item xs={4} className="pad-top-0 pad-bottom-0">
                <p>Cleanliness</p>
                <div style={{ display: "flex" }}>
                  <div className="ratting-bar">
                    <div className="red-bar"></div>
                  </div>
                  <span className="rating-count">7.8</span>
                </div>
              </Grid>
              <Grid item xs={4} className="pad-top-0 pad-bottom-0">
                <p>Staff</p>
                <div style={{ display: "flex" }}>
                  <div className="ratting-bar">
                    <div className="red-bar"></div>
                  </div>
                  <span className="rating-count">7.8</span>
                </div>
              </Grid>
              <Grid item xs={4} className="pad-top-0 pad-bottom-0">
                <p>Confort</p>
                <div style={{ display: "flex" }}>
                  <div className="ratting-bar">
                    <div className="red-bar"></div>
                  </div>
                  <span className="rating-count">7.8</span>
                </div>
              </Grid>
              <Grid item xs={4} className="pad-top-0 pad-bottom-0">
                <p>Value of money</p>
                <div style={{ display: "flex" }}>
                  <div className="ratting-bar">
                    <div className="red-bar"></div>
                  </div>
                  <span className="rating-count">7.8</span>
                </div>
              </Grid>
              <Grid item xs={4} className="pad-top-0 pad-bottom-0">
                <p>Location</p>
                <div style={{ display: "flex" }}>
                  <div className="ratting-bar">
                    <div className="red-bar"></div>
                  </div>
                  <span className="rating-count">7.8</span>
                </div>
              </Grid>
              <Grid item xs={4} className="pad-top-0 pad-bottom-0">
                <p>wifi</p>
                <div style={{ display: "flex" }}>
                  <div className="ratting-bar">
                    <div className="red-bar"></div>
                  </div>
                  <span className="rating-count">7.8</span>
                </div>
              </Grid>
              <Grid item xs={12}>
                <p className="font-weight-600">Select topic for read reviews</p>
                <div className="btn-group">
                  <button className="book-btn" onClick={this.openReview}>
                    Room <i className="fa fa-plus"> </i>
                  </button>
                  <button className="book-btn">
                    Bathroom <i className="fa fa-plus"> </i>
                  </button>
                  <button className="book-btn">
                    Towel <i className="fa fa-plus"> </i>
                  </button>
                  <button className="book-btn">
                    Breakfast <i className="fa fa-plus"> </i>
                  </button>
                  <button className="book-btn">
                    wifi <i className="fa fa-plus"> </i>
                  </button>
                  <a className="color-red mar-top-20 pad-left-5">Show more</a>
                </div>
              </Grid>*/}
              <Grid item xs={7}> 
                <p className="font-weight-600">Select topic for read reviews</p>
              </Grid>
              <Grid item xs={2}>
                <p className="font-weight-600">Sort reviews by</p>
              </Grid>
              <Grid item xs={3}>
                <div className="mar-top-20">
                  <button className="book-btn">
                    recomended <i className="fa fa-angle-down"> </i>
                  </button>
                </div>
              </Grid>
              
                {this.props.review.length === 0 ? '' : this.props.review.hotelReviews.map((val,index)=>{
                  // console.log(val.reviewer_name);
                  return(
                    <Fragment>
                    <Grid item xs={12} className="web-review">
                      <div className="hotel-review ">
                        <img
                          src={val.reviewer_image}
                          className="reviewer-img"
                          alt="reviewer-img"
                        />
                        <span className="color-red mar-top-20 pad-left-5">
                          {val.reviewer_name} 
                        </span>
                        <p className="mar-left-20" style={{ paddingLeft: "0px",textAlign:'center' }}>
                         {val.rating}
                        </p>
                        <span className="color-red mar-top-20 pad-left-25">
                          Reviewers choice
                        </span>
                        <span className="font-13 mar-top-20 pad-left-50">
                          Reviewed {val.review_date}
                        </span>
                      </div>
                      <div class="traveler-info">
                        <p>
                          {" "}
                          <i className="fa fa-circle-o"> </i>Check In : {val.check_in}
                        </p>
                        <p>
                          {" "}
                          <i className="fa fa-circle-o"> </i>Check out : {val.check_out}
                        </p>
                      </div>
                    </Grid>


                    <Grid item xs={12} className="mob-review">
                      <div className="hotel-review">
                        <img
                          src={val.reviewer_image}
                          className="reviewer-img"
                          alt="reviewer-img"
                        />
                        <span className="color-red mar-top-20 pad-left-5">
                          {val.reviewer_name} 
                        </span>
                        
                        <span className="font-13 mar-top-20 pad-left-50">
                          Reviewed {val.review_date}
                        </span>
                      </div>
                      <div class="traveler-info">
                      
                        <div className="color-red flex" >
                          <p className="mob-rat"  style={{ paddingLeft: "0px",textAlign:'center' }}>
                         {val.rating}
                        </p> <span className="rev-text">Reviewers choice</span>
                        </div>
                        <p>
                          {" "}
                          <i className="fa fa-circle-o"> </i>Check In : {val.check_in}
                        </p>
                        <p>
                          {" "}
                          <i className="fa fa-circle-o"> </i>Check out : {val.check_out}
                        </p>
                      </div>
                    </Grid>






                    <Grid item xs={12} className="pad-bottom-0 pad-top-0">
                      <div className="hotel-name-details pad-left-0 font-weight-600 pad-top-25">
                        {" "}
                        {val.satisfaction}{" "}
                      </div>
                      <p class="traveler-text">
                        {val.review}
                      </p>
                    </Grid>
                  </Fragment>
                  )
                })}
                
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={this.handleCloseReview} className="footer-btn">
              Cancel
            </Button> */}
            {/* <Button onClick={this.handleCloseReview} className="footer-btn">
              Book
            </Button> */}
          </DialogActions>
        </Dialog>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hotelDetails: state.SearchReducer.hotelDetails,
    review: state.SearchReducer.hotelReview,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hotelRequestForDetails: (creds) => dispatch(hotelRequestForDetails(creds)),
    getHotelReview: (hotel_id) => dispatch(getHotelReview(hotel_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelDetails);
