import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import { Fragment } from "react";
import HeaderBanner from "./HeaderBanner";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

export default class About extends Component {
  render() {
    return (
      <Fragment>
        <HeaderBanner title="" />
        <Container maxWidth="md">
          <h3 className="pad-top-25">About Powerbit Travel</h3>
          <p className="text-center">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <h3 className="pad-top-25">Why Powerbit Travel</h3>
          <Grid container  spacing={3} className="pad-bottom mob-about">
            <Grid item  xs={3} >
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Great Peers
                  </Typography>
                  <Typography variant="body2" component="p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item  xs={3} >
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Most Trusted Brand
                  </Typography>
                  <Typography variant="body2" component="p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item  xs={3} >
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Fun @ Work
                  </Typography>
                  <Typography variant="body2" component="p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item  xs={3} >
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Open Culture
                  </Typography>
                  <Typography variant="body2" component="p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}
