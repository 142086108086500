import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import { Fragment } from "react";
import HeaderBanner from "./HeaderBanner";

export default class Faq extends Component {
  render() {
    return (
      <Fragment>
        <HeaderBanner title="FAQ" />
        <Container maxWidth="md" className="pad-bottom pad-top-25 ">
          <h3 className="text-left">What is Lorem Ipsum?</h3>
          <p className="text-left">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
          <h3 className="text-left">What is Lorem Ipsum?</h3>
          <p className="text-left">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
          <h3 className="text-left">What is Lorem Ipsum?</h3>
          <p className="text-left">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
        </Container>
      </Fragment>
    );
  }
}
