import React, { Component,Fragment } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Loader from "./Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

export default class slide2 extends Component {
  constructor(props) {
    super();
    this.state = {
      dataArray: [],
      open2: false,
      dArray2:'',
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  handleClickOpen2 = (data) => {
    this.setState({ setOpen: true, open2: true,dArray2:data });
  };

  handleClose2 = () => {
    this.setState({ setOpen: false, open2: false });
  };


  componentDidMount() {
    this.setState({ dataArray: this.props.offer });
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            slidesToScroll: 1,
          },
        },
      ],
    };
    let dataArray = this.state.dataArray;
    var data =dataArray === undefined ? (
        <Loader />
      ) : (
        dataArray.map((value,index) => {
            return(
           
                  <div className="offer-banner-image " key={index}>
                    <img
                      src={value.image}
                      className="logo"
                      alt="img"
                      onClick={()=>this.handleClickOpen2(value)}
                    />
                  </div>
            
            )
      
        })
      );
    return (
      <Fragment>
        <div style={{ textAlign: "left",paddingBottom:"31px" }}>
          <button className="button sl-btn" onClick={this.previous}>
            <i className="fa fa-angle-left"></i>
          </button>
          <button className="button sl-btn" onClick={this.next}>
            <i className="fa fa-angle-right"></i>
          </button>
        </div>
        <Slider ref={(c) => (this.slider = c)} {...settings} >
           {data}
        </Slider>
        {this.state.dArray2 !== ''?
        <Dialog
                open={this.state.open2}
                onClose={this.handleClose2}
                maxWidth={100}
              >
                <DialogActions>
                  <Button onClick={this.handleClose2} >
                    <i className="fa fa-close"></i>
                  </Button>
                </DialogActions>
                <DialogContent>
                  <img src={this.state.dArray2.image} className="offer-view-img" alt="img" />
                  <h3>{this.state.dArray2.name}</h3>
                  <p>{this.state.dArray2.description}</p>
                  <p><b>Operator Name : </b>{this.state.dArray2.bus_operator}</p>
                  <p><b>Coupon Code : </b>{this.state.dArray2.coupon_code}</p>
                  <p><b>Discount : </b>{this.state.dArray2.discount_parcent} %</p>
                  <p><b>Maximum Discount : </b>{this.state.dArray2.maximum_discount}</p>
                  <p><b>Minimum Price : </b>{this.state.dArray2.minimum_price}</p>
                </DialogContent>
                {/* <DialogActions>
                  <Button onClick={this.handleClose2} className="footer-btn">
                    Cancel
                  </Button>
                </DialogActions> */}
              </Dialog> :''}
      </Fragment>
    );
  }
}
