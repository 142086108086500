import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Container } from "@material-ui/core";
import { connect } from "react-redux";
import { testimonialData } from "../Store/Actions/OfferAction";
import Loader from "./Loader";

class Testimonial extends Component {
  componentDidMount = () => {
    this.props.testimonialData();
  };

  render() {
    let testimonial = this.props.testimonial;
    var result =
    testimonial === undefined ? (
        <Loader />
      ) : (
        testimonial.map((value, index) => {
          return (
            <div key={index}>
              <div className="myCarousel">
                <p>{value.description}</p>
                <h3>{value.name}</h3>
                <h4>{value.title}</h4>
              </div>
              <img src={value.image} alt={value.name} />
            </div>
          );
        })
      );

    return (
      <div className="testimonial">
        <Container maxWidth="md">
          <h3 className="text-center pad-top-25">Testimonial</h3>
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={3000}
          >
            {result}
          </Carousel>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    testimonial: state.OfferReducer.testimonial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    testimonialData: () => dispatch(testimonialData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Testimonial);
