import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import {Link } from "react-router-dom";
import { connect } from "react-redux";
// import { UserInformation } from "../Store/Actions/AuthAction";
import { BusBookingStatusAction } from "../Store/Actions/BusBookingAction";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import  HeaderBanner from "./HeaderBanner";

class PaymentStatus extends Component {
    paymentPage =()=>{
        var url = localStorage.getItem('paymentUrl');
        window.open(url, '_blank');
    }

    componentDidMount(props) {
      // this.props.UserInformation();
      this.props.BusBookingStatusAction();
    }

  render() {
    var ticket = this.props.bookingStatus;
    if(ticket.message === 'Success'){
      window.close();
    }else if(!localStorage.getItem('paymentUrl')){
      return <Redirect to="/" />
    }
      
    return (
      <div>
        <HeaderBanner title="Payment" />
        <Container maxWidth="md" className="pad-25 ">
          <Grid container spacing={3}>
            <Grid item xs={8}>
                <div className="payment-status">
                    <h2>Please Complete your payment and confirm your ticket.</h2>
                    <p> If you don't get your payment page  then click bellow..</p>
                    <button onClick={this.paymentPage} className="status-btn">Get Payment Page</button>
                </div>
            </Grid>
            <Grid item xs={4}>
              <div className="order-add">
                <img src="assets/images/add1.jpg" alt="" />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, history) => {
  return {
    userData: state.AuthReducer.userData,
    bookingStatus: state.BusBookingReducer.bookingStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // UserInformation: () => dispatch(UserInformation()),
    BusBookingStatusAction: () => dispatch(BusBookingStatusAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentStatus));
