import React, { Component, Fragment } from "react";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import { offerBanner } from "../Store/Actions/OfferAction";
import Loader from "./Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

class OfferBanner extends Component {
  constructor(props) {
    super();
    this.state = {
      setOpen: false,
      open: false,
      dArray:'',
    };
  }

  handleClickOpen = (data) => {
    this.setState({ setOpen: true, open: true,dArray:data });
    console.log(this.state.dArray,this.state.dArray.length);
  };

  handleClose = () => {
    this.setState({ setOpen: false, open: false });
  };
  componentDidMount = () => {
    this.props.offerBanner();
  };
  render() {
    let dataArray = this.props.banner;
    var result =
    dataArray === undefined ? (
        <Loader />
      ) : (
            <Container maxWidth="md" spacing={3}>
              <img src={dataArray.image} className="logo" alt={dataArray.name} onClick={()=>this.handleClickOpen(dataArray)} />
            </Container>
      );

    return (
      <Fragment>
        <div className="offer-banner">{result}</div>
           {this.state.dArray !== ''?
        <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                maxWidth={100}
              >
                <DialogActions>
                  <Button onClick={this.handleClose} >
                    <i className="fa fa-close"></i>
                  </Button>
                </DialogActions>
                <DialogContent>
                  <img src={this.state.dArray.image} className="admin-offer-view-img" alt="img" />
                  <h3>{this.state.dArray.name}</h3>
                  <p>{this.state.dArray.description}</p>
                  <p><b>Coupon Code : </b>{this.state.dArray.coupon_code}</p>
                  <p><b>Discount : </b>{this.state.dArray.discount_parcent} %</p>
                  <p><b>Maximum Discount : </b>{this.state.dArray.maximum_discount}</p>
                  <p><b>Minimum Price : </b>{this.state.dArray.minimum_price}</p>
                </DialogContent>
                {/* <DialogActions>
                  <Button onClick={this.handleClose} className="footer-btn">
                    Cancel
                  </Button>
                </DialogActions> */}
              </Dialog> :''}
      
      </Fragment>
    
    );
  }
}

const mapStateToProps = (state) => {
  return {
    banner: state.OfferReducer.banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    offerBanner: () => dispatch(offerBanner()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferBanner);
