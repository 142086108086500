import {combineReducers} from 'redux';
import  OfferReducer from './OfferReducer';
import  SearchReducer from './SearchReducer';
import  AuthReducer from './AuthReducer';
import  BusBookingReducer from './BusBookingReducer';



const RootReducer = combineReducers({
    OfferReducer,SearchReducer,AuthReducer,BusBookingReducer
});


export default RootReducer;