import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from "react-router-dom";
import FormGroup from '@material-ui/core/FormGroup';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import {UserLogin,SocialLogin} from '../Store/Actions/AuthAction'
import { withRouter } from "react-router-dom";
import {signInWithGoogle} from "./firebase";
import {signInWithFacebook} from "./firebase";
import {auth} from "./firebase";



class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
          email: "",
          password: "",
          social:false,
          loader:false,
        };
      }
    
      handleSubmit = (e) => {
        e.preventDefault();
       if(this.state.email ===''){
        toast.warning(<i className="fa fa-exclamation-triangle"> The Email field is required</i>);
      }else if(this.state.password ===''){
        toast.warning(<i className="fa fa-exclamation-triangle"> The Password field is required</i>);
      }else if(this.state.password.length < 8 ){
        toast.warning(<i className="fa fa-exclamation-triangle"> The password must be at least 8 characters </i>);
      }else{
        this.props.UserLogin(this.state,this.props.history);
        this.setState({
          loader:true,
        });
      }
        
      };
      handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      };
      socialLog = () => {
        
        this.setState({
          social:true,
        })
        signInWithGoogle()
        this.state.social = true;
        
      }

     facebookLogin = () =>{
      console.log('facebokk');
      this.setState({
        social:true,
      })
      signInWithFacebook();
      this.state.social = true;
     }

      unsubscribeFromAuth = null;
      componentDidMount(props) {
        if(this.props.history.location.state !== undefined){
          if(this.props.history.location.state.type === 'hotel'){
            localStorage.setItem('bookingData',JSON.stringify(this.props.history.location.state));
          }else{
            var bookItem = this.props.history.location.state.bookJourney;
            localStorage.setItem('bookingData',JSON.stringify(bookItem));
          }
          
        }
          this.unsubscribeFromAuth = auth.onAuthStateChanged(user => {
            if(user){
              var postArray ={
                full_name:user.displayName,
                email:user.email,
                phone:user.phoneNumber,
                uid:user.uid,
                social_provider:'google'
              }
              this.props.SocialLogin(postArray,this.props.history);
              this.unsubscribeFromAuth();
              auth.signOut();
              
            }
          });

          
  
          
        // }
      
      
        
      }
      

  render() {
    var loadingLogin = this.props.loadingLogin;  

    return (
      <div>
        <Container maxWidth="md">
          <Container maxWidth="md">
            <div className="powerbit-login">
              <h3>Welcome To Powerbit !</h3>
              <p>Please login with your email .</p>
              <div className="form-controll">
                <div className="login-header">Sign In</div>
                <form className="login-form" onSubmit={this.handleSubmit}>
                
                <TextField
                  id="standard-textarea"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  name="email"
                  onChange = {this.handleChange}
                />
                <TextField
                  id="standard-textarea"
                  label="Password"
                  placeholder="Password"
                  variant="outlined"
                  type="password"
                  name="password"
                  onChange = {this.handleChange}
                />
                
                <button className="sign-up-btn mar-top-10">{loadingLogin && this.state.loader ? <i className="fa fa-spinner fa-spin"></i> :''}Sign In</button>
                <div className="forget-text">
                <FormGroup row>
                <FormControlLabel
                control={<Checkbox   name="checkedA" />}
                label="Remember me"
                />
                 <Link to="/forget/passwrod" className="forget-btn  "> Forget Password?</Link>
                 </FormGroup>
                </div>
                </form>
                <p>Or sign up with  social login</p>
                <div className="social-login-btn">
                    <button onClick={this.facebookLogin} className="sign-up-fb-btn"><i className="fa fa-facebook"></i> facebook</button>
                    <span> </span>
                    <button  onClick={this.socialLog} className="sign-up-google-btn"><i className="fa fa-google"></i> google</button>
                </div>
                <p>Click here to register as a <Link to="/sign-up"> New User ?</Link></p>
              </div>
            </div>
          </Container>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state,history) => {
  
    return {
      authResponse:state.AuthReducer.authResponse,
      loadingLogin:state.AuthReducer.loadingLogin,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        UserLogin:(creds,history) => dispatch(UserLogin(creds,history)),
        SocialLogin:(creds,history) => dispatch(SocialLogin(creds,history)),
    };
  };

  
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
