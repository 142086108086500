import React, { Component } from 'react';
import Container from "@material-ui/core/Container";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { connect } from "react-redux";
import { BusBookingTable, couponValidation } from "../Store/Actions/BusBookingAction";
import { withRouter } from "react-router-dom";
// import history from "../Store/Actions/history";
import { Redirect } from "react-router-dom";
import  HeaderBanner from "./HeaderBanner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { UserInformation } from "../Store/Actions/AuthAction";
import HttpService from '../Store/Services/HttpService';


class OrderDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            passenger_name: '',
            passenger_email: '',
            passenger_phone: '',
            passenger_card: '',
            nid: '',
            submit:false,
            loader:false,
            coupon_code:'',
            timer:'',
        }
    }
    
    handleCoupon = (e) =>{
      e.preventDefault();
      this.setState({
        coupon_code: e.target.value,
      });
    };

    getDisCount = (e)=>{
      e.preventDefault();
      if(this.state.coupon_code === ''){
        toast.warning(<i className="fa fa-exclamation-triangle">{" "}The coupon code is required .</i> )
      }else{
        var journey = JSON.parse(localStorage.getItem('journey'));
        var disArray = {
          coupon_code : this.state.coupon_code,
          booking_type : 'BusBooking',
          referance_no : journey.referance_no,
        }
        this.props.couponValidation(disArray,'BusBooking')
      }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        
        this.setState({submit:true});
        this.state.submit = true;
        var journey = JSON.parse(localStorage.getItem('journey'));
        var returnJourney = JSON.parse(localStorage.getItem('returnJourney'));
        // var bus_journey_id[0][bus_journey_id] = journey.bus_journey_id;
        //     bus_journey_id:returnJourney.bus_journey_id
        // };
        // var booking_cost = [journey.booking_cost,returnJourney?returnJourney.booking_cost:0];
        // var coupon_code = ['',''];
        // var coupon_applied = [0,0];
        // var discount_amount = [0,0];
        var bus_journey_id='';
        var journey_details= [
             bus_journey_id= {
                bus_journey_id:journey.bus_journey_id,
                coupon_code:'',
                coupon_applied:0,
                discount_amount:0,
                booking_cost:journey.booking_cost,
                },
                bus_journey_id= {
                bus_journey_id:returnJourney?returnJourney.bus_journey_id:'',
                coupon_code:'',
                coupon_applied:0,
                discount_amount:0,
                booking_cost:returnJourney?returnJourney.booking_cost:0,
                    },
        ];
        var jd = [
          {
            bus_journey_id:journey.bus_journey_id,
            coupon_code:'',
            coupon_applied:0,
            discount_amount:0,
            booking_cost:journey.booking_cost,
            }
        ];
        // var journey_details= [bus_journey_id,booking_cost,coupon_code,coupon_applied,discount_amount];
        
        var tt = returnJourney ? returnJourney.booking_cost : 0;
        var total1 = parseInt(journey.booking_cost)+parseInt(tt);
        var discountTotal = this.props.discountData === undefined || this.props.discountData.length === 0 ? 0 : parseInt(this.props.discountData.price_details.discount);
        var total = parseInt(total1) - parseInt(discountTotal)
        
        var fromDataArray = {
            referance_no:journey.referance_no,
            user_id:journey.user_id,
            booking_cost:total,
            discount_amount:discountTotal,
            system_commission:100,
            payable_amount:total+100,
            passenger_name:this.state.passenger_name,
            passenger_email:this.state.passenger_email,
            passenger_phone:this.state.passenger_phone,
            passenger_card:this.state.passenger_card,
            nid:this.state.nid,
            journey_details:returnJourney?journey_details:jd,
            request_from:'ReactApp',

        }

        var patternPhone = new RegExp(/^[0-9\b]+$/);
        // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        // if (!pattern.test(this.state.passenger_email)) {
        //   toast.warning(<i className="fa fa-exclamation-triangle">{" "}The Passenger Email field is invalid .</i> )
        // }

        // else if(this.state.passenger_email ===''){
        //   toast.warning(<i className="fa fa-exclamation-triangle"> The Passenger Email field is required</i>);
        // }

        if (!patternPhone.test(this.state.passenger_phone)) {
          toast.warning(<i className="fa fa-exclamation-triangle">{" "}The Passenger Phone number is invalid .</i> )
        }else if(this.state.passenger_phone.length != 11){
          toast.warning(<i className="fa fa-exclamation-triangle">{" "}The Passenger Phone number is invalid .</i> )
        }else if(this.state.passenger_name ===''){
          toast.warning(<i className="fa fa-exclamation-triangle"> The Passenger Name field is required</i>);
        }else if(this.state.passenger_phone ===''){
          toast.warning(<i className="fa fa-exclamation-triangle"> The Passenger Phone field is required</i>);
        }else if(this.state.passenger_card ===''){
          toast.warning(<i className="fa fa-exclamation-triangle"> The Card Type field is required</i>);
        }else if(this.state.nid ===''){
          toast.warning(<i className="fa fa-exclamation-triangle"> The Passenger Nid field is required</i>);
        }else{
          localStorage.setItem('search_type','bus-booking')
          this.props.BusBookingTable(fromDataArray,this.props.history);
          this.setState({loader:true})
        }
        
        // localStorage.removeItem('jStatus');
        // localStorage.removeItem('journey');
        // localStorage.removeItem('returnJourney');
        // localStorage.removeItem('journeyDetails');
        // localStorage.removeItem('busName');
        // localStorage.removeItem('departurTime');
        // localStorage.removeItem('operatorName');
        // localStorage.removeItem('retuenBusName');
        // localStorage.removeItem('retuenDeparturTime');
        // localStorage.removeItem('retuenOperatorName');



      };
      
      handleChange = (e) => {
        e.preventDefault();
        this.setState({
          [e.target.name]: e.target.value,
        });
      };
     
    componentDidMount(){
        // window.location.replace('https://sandbox.sslcommerz.com/EasyCheckOut/testcdedbe87fad507234349603ac680f88cbdd')
        this.setState({submit:true});
        this.state.submit = true;
        this.props.UserInformation(this.props.history);
        
    }

    setTimer = () => {
      var setTimer = JSON.parse(localStorage.getItem('setTimer'));
      
        var countDownDate = new Date(setTimer.booked_time).getTime();
        const _this = this;
        var x = setInterval(function() {
          var now = new Date().getTime();
          var distance = countDownDate - now;
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);
          var time = '00:'+minutes.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }) +":" + seconds.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          });
          _this.setState({
              timer:time
          })
          
          if (distance < 0 || minutes === 0) {
            clearInterval(x);
            _this.setState({
              timer:'00:00:00'
          })
          }
        }, 1000);
      
      
    }

    checkTimer =()=>{
      var setTimerData = JSON.parse(localStorage.getItem('setTimer'));
      const _this_ = this;
      if(setTimerData != null){
        _this_.setTimer();
      }else {
        var interval = setInterval(()=>{
          _this_.checkTimer();
          if(setTimerData != null){ clearInterval(interval); _this_.setTimer()}
        },1000)
      }
    }



    componentWillUpdate (){
        localStorage.removeItem('bookingData')
        this.checkTimer()
    }
    render() {
        var userData = this.props.userData;
        const http = new HttpService();
        var journey = JSON.parse(localStorage.getItem('journey'));
        if(!journey){
          return <Redirect to="/" />
        }
        var returnJourney = JSON.parse(localStorage.getItem('returnJourney'));
        var journeyDetails = JSON.parse(localStorage.getItem('journeyDetails'));
        var operatorName = localStorage.getItem('operatorName');
        // var busName = localStorage.getItem('busName');
        var departurTime = localStorage.getItem('departurTime');
        
        var retuenOperatorName = localStorage.getItem('retuenOperatorName');
        // var retuenBusName = localStorage.getItem('retuenBusName');
        var retuenDeparturTime = localStorage.getItem('retuenDeparturTime');
        var retTotal_cost = returnJourney?returnJourney.booking_cost:0;
        var total1 = parseInt(journey.booking_cost)+parseInt(retTotal_cost);
        var discountTotal = this.props.discountData === undefined || this.props.discountData.length === 0 ? 0 : parseInt(this.props.discountData.price_details.discount);
        var total = parseInt(total1) - parseInt(discountTotal)
       

        if(this.props.busBooking_s.status === "success" && this.state.submit){
          // window.open(this.props.busBooking.data, '');
          window.open(this.props.busBooking_s.data,'_self');
          // window.open(this.props.busBooking.data, '_blank');
          localStorage.setItem('paymentUrl',this.props.busBooking_s.data);
          localStorage.setItem('jType','bus-booking');
          // window.location.href(this.props.busBooking.data, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
          // history.push('/payment');
          if(this.state.submit){
            return <Redirect to="/payment" />
          }
          // return <Redirect to="/payment" />
        }
        
        return (
            <div>
              <HeaderBanner title="Order" />
                <Container maxWidth="md" className="pad-25 ">
                <Grid container spacing={3} className="mob-order">
                    <Grid item xs={8}>
                        <div className="journey-details">
                            <h2 >Journey Details</h2>
                            <div className="towards">
                            <h3>{journeyDetails.from_city} - {journeyDetails.to_city}</h3>
                            <p>{operatorName}</p>
                            <p>{departurTime} {journeyDetails.journey_date}</p>
                            <p>Seat NO : {journey.seat_nameArray.map((val,index)=>{return val+','})}</p>
                            <p>Boarding Point : {journey.place_of_boarding_point}</p>
                            </div>
                            {"\n"}

                            {returnJourney ? (
                              <div className="return pad-top-15">
                              <h3> {returnJourney?journeyDetails.to_city:''} - {returnJourney?journeyDetails.from_city:''}</h3>
                              <p>{retuenOperatorName}</p>
                              <p>{retuenDeparturTime} {journeyDetails.return_journey_date}</p>
                              <p>Seat NO : {returnJourney?returnJourney.seat_nameArray.map((val,index)=>{return val+','}):''}</p>
                              <p>Boarding Point : {returnJourney?returnJourney.place_of_boarding_point:''}</p>
                              </div>
                            ):''}
                            
                        </div>
                        <form className="order-form" onSubmit={this.handleSubmit}>
                        <div className="passenger-details">
                            <h2>Passenger Details</h2>
                              <TextField  id="standard-required" value={this.state.passenger_name} name="passenger_name" onChange={this.handleChange} label="Passenger Name"  />
                              <TextField  id="standard-required1" value={this.state.passenger_phone} name="passenger_phone" onChange={this.handleChange} label="Passenger Phone"  />
                              <TextField  id="standard-required2" value={this.state.passenger_email} name="passenger_email" onChange={this.handleChange} label="Passenger Email"  />
                              <FormControl>
                              <InputLabel id="demo-simple-select-label">Select ID Type</InputLabel>
                              <Select
                                id="standard-required2"
                                value={this.state.passenger_card}
                                onChange={this.handleChange}
                                name="passenger_card"
                                className="sel0"
                              >
                                <MenuItem value="NID">NID</MenuItem>
                                <MenuItem value="Passport">Passport</MenuItem>
                                <MenuItem value="Driving Licence">Driving Licence</MenuItem>
                                <MenuItem value="Employee ID">Employee ID</MenuItem>
                                <MenuItem value="Birth Certificate">Birth Certificate</MenuItem>
                                <MenuItem value="Student ID">Student ID(Valid)</MenuItem>
                                
                              </Select>
                              </FormControl>
                              <TextField  id="standard-required3" value={this.state.nid} name="nid" onChange={this.handleChange} label="Card No"  />
                              
                              
                              <h2>Fare Details</h2>
                              <div className="fare">
                              <div className="p-row"><div className="row-left">Ticket Price</div> <div className="row-right">{total1}</div></div>
                              <div className="p-row"><div className="row-left">Processing Fee's</div> <div className="row-right">100</div></div>
                              <div className="p-row">
                                <div className="row-left">
                                  <TextField onChange={this.handleCoupon} value={this.state.coupon_code} id="standard-required" label="Coupon Code" variant="outlined" />
                                </div> 
                                  <div className="row-right">
                                      <button onClick={(e)=>this.getDisCount(e)} className="coupon-apply mob-order-btn">Apply</button>
                                  </div>
                                </div>
                              <div className="p-row"><div className="row-left">Discount</div> <div className="row-right">{discountTotal}</div></div>
                              <div className="p-row"><div className="row-left">Insurance Amount</div> <div className="row-right">0</div></div>
                              <div className="p-row border-none"><div className="row-left">Total</div> <div className="row-right">
                                  <span className="b_total">{parseInt(total)+100}</span>
                                  </div></div>
                              </div>
                     <button className="pay-btn mob-order-btn">{this.state.loader ? <i className="fa fa-spinner fa-spinn"> Loading </i>:'Pay Now'} </button>
                     {/* <iframe src="https://sandbox.sslcommerz.com/EasyCheckOut/testcdedcc3d7e257da21f5b6ae47a29ee89a36" /> */}
                     </div>
                        </form>
                    </Grid>
                    <Grid item xs={4}>
                      
                      <div className="expire-section">
                        <p>Book now before tickets run out!</p>
                        <h2><i className="fa fa-clock-o"> </i> {this.state.timer} </h2>
                        
                      </div>
                      { userData.add_img === '' || userData.add_img === undefined ? '' : 
                      <div className="order-add">
                        <img src={http.url+userData.add_img} alt="" /> 
                      </div>
                      }
                    </Grid>
                </Grid>
                </Container>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
      busBooking_s: state.BusBookingReducer.busBooking_s,
      discountData: state.BusBookingReducer.discountData,
      userData: state.AuthReducer.userData,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        BusBookingTable: (creds,history) => dispatch(BusBookingTable(creds,history)),
        couponValidation: (creds,type) => dispatch(couponValidation(creds,type)),
        UserInformation: (history) => dispatch(UserInformation(history)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderDetails));