import React, { Component, Fragment } from 'react';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

export default class Footer extends Component {
    render() {
        return (
            <Fragment>
            <div className="footer">
              <Container maxWidth="md">
                <Grid container spacing={3}  className="pad-top-25">
                  <Grid item xs={5}>
                    <div className="footer-about">
                       <img src="assets/images/logo.png" alt="" />
                       <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                    </div>
                  </Grid>
                  <Grid item xs={2} >
                   <h3 className="footer-title">Services</h3>
                   <div className="service-list">
                       <Link to="">Bus Tickets</Link>
                       <Link to="">Airlines Tickets</Link>
                       <Link to="">Launch Tickets</Link>
                       <Link to="">Hotels room booking</Link>
                   </div>

                  </Grid>
                  <Grid item xs={2}>
                  <h3 className="footer-title">Quick Link</h3>
                   <div className="service-list">
                       <Link to="about-us">About Us</Link>
                       <Link to="faq">Faq</Link>
                       <Link to="contact">Contact Us</Link>
                       <Link to="terms-use-condition">Terms Of Use</Link>
                       <Link to="privacy-policy">Privacy Policy</Link>
                   </div>
                  </Grid>
                  <Grid item xs={3}>
                  <h3 className="footer-title pad-left-15">Follow Us</h3>
                  <div className="social-icon align-left">
                  <Link to="/"><i className="fa fa-facebook"></i></Link>
                  <Link to="/"><i className="fa fa-twitter"></i></Link>
                  <Link to="/"><i className="fa fa-linkedin"></i></Link>
                  <Link to="/"><i className="fa fa-google-plus"></i></Link>
              </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
            <div className="small-footer">
                <i className="fa fa-copyright"> 2020-2021 Vromon . All Rights Reserved .</i>
            </div>
            </Fragment>
          );
    }
}
