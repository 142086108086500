import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

export default class Partners extends Component {
  render() {
    return (
      <div className="partners">
        <Container maxWidth="md">
            <h3 className="text-center pad-top-60">Our Trusteed Partners</h3>
          <Grid container spacing={3} >
            <Grid item xs={3}>
              <div className="box-icon shadow">
                <div className="partner-img">
                  <img src="/assets/images/partners_1.png" className="logo" alt="img" />
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="box-icon shadow">
                <div className="partner-img">
                  <img src="/assets/images/partners_2.png" className="logo" alt="img" />
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="box-icon shadow">
                <div className="partner-img">
                   <img src="/assets/images/partners_3.png" className="logo" alt="img" />
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="box-icon shadow">
                <div className="partner-img">
                   <img src="/assets/images/partners_4.png" className="logo" alt="img" />
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
