import {
  BusBookingService,
  BusBookingTableService,
  BookingStatusService,
  LaunchBookingService,
  LaunchBookingTableService,
  HotelBookingTableService,
  reqForDiscount,
} from "../Services/BusBookingService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "./history";

export const BusBooking = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: "LOADING" });
    BusBookingService(credentials, history).then(
      (res) => {
        if (res.message === "Please proceed") {
          localStorage.setItem("jStatus", "1");
        }
        if (res.message === "These seats are booked recently") {
          toast.error(
            <i className="fa fa-exclamation-triangle">{res.message}</i>
          );
          history.push("/");
        }
        // toast.success(<i className="fa fa-check-circle-o"> {res.message} </i>);
        var bookedJourneyDetails = res.bookedJourneyDetails[0];
        localStorage.setItem("setTimer",JSON.stringify(bookedJourneyDetails));
        res = res.message;
        dispatch({ type: "SUCCESS_BUS_BOOKING", res });
        
      },
      (error) => {
        toast.success(
          <i className="fa fa-exclamation-triangle">Something went wrong !</i>
        );
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};

export const LaunchBooking = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: "LOADING" });
    LaunchBookingService(credentials, history).then(
      (res) => {
        if (res.message === "Please proceed") {
          localStorage.setItem("jStatus", "1");
        }
        // console.log(res.message,'jll');
        if (res.message === "These seats/cabins are booked recently") {
          toast.error(
            <i className="fa fa-exclamation-triangle">{res.message}</i>
          );
          history.push("/");
        }
        // toast.success(<i className="fa fa-check-circle-o"> {res.message} </i>);
        var bookedJourneyDetails = res.bookedJourneyDetails[0];
        localStorage.setItem("setTimer",JSON.stringify(bookedJourneyDetails));
        res = res.message;
        dispatch({ type: "SUCCESS_LAUNCH_BOOKING", res });
      },
      (error) => {
        toast.success(
          <i className="fa fa-exclamation-triangle">Something went wrong !</i>
        );
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};

export const BusBookingTable = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: "LOADING" });
    BusBookingTableService(credentials, history).then(
      (res) => {
        if (res.message === "Seat hold time expired") {
          toast.error(
            <i className="fa fa-exclamation-triangle"> {res.message} </i>
          );
          history.push("/");
        }
        dispatch({ type: "BUS_BOOKING", res });
      },
      (error) => {
        toast.success(
          <i className="fa fa-exclamation-triangle">Something went wrong !</i>
        );
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};

export const HotelBookingTable = (credentials) => {
  return (dispatch) => {
    dispatch({ type: "HOTEL_BOOKING_LOADING" });
    HotelBookingTableService(credentials).then(
      (res) => {
        dispatch({ type: "HOTEL_BOOKING", res });
      },
      (error) => {
        toast.success(
          <i className="fa fa-exclamation-triangle">Something went wrong !</i>
        );
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};

export const LaunchBookingTable = (credentials) => {
  return (dispatch) => {
    dispatch({ type: "LOADING" });
    LaunchBookingTableService(credentials).then(
      (res) => {
        if (res.message === "Seat hold time expired") {
          toast.error(
            <i className="fa fa-exclamation-triangle">{res.message}</i>
          );
          history.push("/");
        }
        dispatch({ type: "LAUNCH_BOOKING", res });
      },
      (error) => {
        toast.success(
          <i className="fa fa-exclamation-triangle">Something went wrong !</i>
        );
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};

export const BusBookingStatusAction = () => {
  return (dispatch) => {
    dispatch({ type: "LOADING" });
    BookingStatusService().then(
      (res) => {
        console.log(res);
        dispatch({ type: "BOOKING_STATUS", res });
      },
      (error) => {
        toast.success(
          <i className="fa fa-exclamation-triangle">Something went wrong !</i>
        );
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};
export const couponValidation = (credentials, type) => {
  return (dispatch) => {
    dispatch({ type: "LOADING" });
    reqForDiscount(credentials, type).then(
      (res) => {
        if(res.message === 'Invalid'){
          res = [];
          toast.warning(<i className="fa fa-exclamation-triangle">{" "}This Coupon is Invalid or this coupon code is not valid for this booking .</i> )
        }
        dispatch({ type: "DISCOUNT", res });
      },
      (error) => {
        toast.success(
          <i className="fa fa-exclamation-triangle">Something went wrong !</i>
        );
        dispatch({ type: "CODE_ERROR", error });
      }
    );
  };
};
